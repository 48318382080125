import React, { useState, useEffect, useRef } from 'react';
import {
    ChatContainer,
    MessageList,
    MessageInput,
    ConversationHeader,
} from '@chatscope/chat-ui-kit-react';
import {
    Client,
    Conversation,
    Message as TwilioMessage,
    Paginator,
} from '@twilio/conversations';
import { useGetIdentity, useNotify } from 'react-admin';
import { ConversationData, useTwilio } from '../../contexts/TwilioProvider';
import DisplayMessage from './DisplayMessage';
import * as Sentry from '@sentry/browser';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import { v4 as uuidv4 } from 'uuid';
import { ZOOM_GROUP, ZOOM_ONE_TO_ONE } from '../../constants';

export const ChatBox = () => {
    const { client, selectedConversation, conversationData } = useTwilio() as {
        client: Client;
        selectedConversation: Conversation;
        conversationData: { [key: string]: ConversationData };
    };
    const [messages, setMessages] = useState<TwilioMessage[]>([]);
    const [messagePaginator, setMessagePaginator] =
        useState<Paginator<TwilioMessage> | null>(null);

    const [loadingMore, setLoadingMore] = useState(false);

    const [title, setTitle] = useState('No Conversation Selected');
    const [value, setValue] = useState('');

    const [participantsModal, setModal] = useState<boolean>(false);

    const msgListRef = useRef<any>(null);
    const notify = useNotify();

    useEffect(() => {
        const loadMessagesAndParticipants = async () => {
            if (selectedConversation) {
                try {
                    let header: string;
                    if (selectedConversation.friendlyName)
                        header = selectedConversation.friendlyName;
                    else if (
                        conversationData[selectedConversation.sid]?.userNames
                    ) {
                        header =
                            conversationData[
                                selectedConversation.sid
                            ].userNames.join(', ');
                    } else {
                        header =
                            selectedConversation.uniqueName ||
                            'New Conversation';
                    }

                    setTitle(header);

                    const paginator = await selectedConversation.getMessages();
                    setMessagePaginator(paginator);
                    setMessages(paginator.items);
                } catch (error) {
                    Sentry.addBreadcrumb({
                        category: 'twilio',
                        message: 'Could not load messages',
                        level: 'log',
                    });
                }
            }
        };

        loadMessagesAndParticipants();

        const messageAddedListener = async (message: TwilioMessage) => {
            setMessages((prevMessages) => [...prevMessages, message]);
        };
        selectedConversation?.on('messageAdded', messageAddedListener);
        return () => {
            selectedConversation?.off('messageAdded', messageAddedListener);
        };
    }, [selectedConversation]);

    useEffect(() => {
        if (
            selectedConversation &&
            conversationData[selectedConversation.sid] &&
            !selectedConversation.friendlyName
        ) {
            let header: string;
            if (conversationData[selectedConversation.sid].userNames) {
                header =
                    conversationData[selectedConversation.sid].userNames.join(
                        ', ',
                    );
            } else header = selectedConversation.uniqueName || 'Conversation';

            setTitle(header);
        }
    }, [conversationData]);

    const handleSend = async () => {
        // The text input is a contenteditable div, so we need to get the text content directly, otherwise
        // we would get the HTML content
        const textbox = document.getElementsByClassName(
            'cs-message-input__content-editor',
        );
        const text = textbox[0].textContent;
        setValue('');
        if (text?.trim()) {
            try {
                // await selectedConversation.sendMessage(text, {
                //     authorFriendlyName: `${client.user.friendlyName}`,
                // });
                await selectedConversation.sendMessage(text);
            } catch (error) {
                notify(`Could not send message`, { type: 'error' });
                Sentry.captureException(error);
            }
        }
    };

    const createZoomMeeting = () => {
        let callType = ZOOM_GROUP;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (selectedConversation.attributes?.type === 'user') {
            callType = ZOOM_ONE_TO_ONE;
        }
        const uuid = uuidv4().substring(9, 23).toUpperCase();
        selectedConversation
            .sendMessage('You were invited to a Video Call.', {
                type: 'video_invite',
                sessionName: uuid,
                callType,
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    const handleScroll = async () => {
        if (loadingMore === true) return;

        if (messagePaginator && messagePaginator.hasPrevPage) {
            setLoadingMore(true);

            const prevPage = await messagePaginator.prevPage();
            setMessagePaginator(prevPage);

            setLoadingMore(false);
            setMessages((prevMessages) => [...prevPage.items, ...prevMessages]);
        }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];

            if (file.type.startsWith('image/')) {
                try {
                    const formData = new FormData();
                    formData.append('file', file, file.name);

                    const contentType = file.type;

                    await selectedConversation.sendMessage(formData, {
                        contentType,
                        filename: file.name,
                    });
                } catch (error) {
                    notify(`Could not send message`, { type: 'error' });
                    Sentry.captureException(error);
                }
            } else alert('Please select an image file.');

            if (event.target) event.target.value = '';
        }
    };

    const characterLimit = 320;
    const handleInputChange = (text: string) => {
        if (text.length <= characterLimit) setValue(text);
        else notify('Character limit of 320 reached', { type: 'warning' });
    };

    return (
        <>
            <ChatContainer>
                <ConversationHeader>
                    <ConversationHeader.Content userName={title} />
                    <ConversationHeader.Actions>
                        <IconButton onClick={() => setModal(true)}>
                            <InfoIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                createZoomMeeting();
                            }}
                        >
                            <VideoCallOutlinedIcon fontSize="large" />
                        </IconButton>
                    </ConversationHeader.Actions>
                </ConversationHeader>
                <MessageList
                    ref={msgListRef}
                    onYReachStart={handleScroll}
                    disableOnYReachWhenNoScroll={true}
                    loadingMore={loadingMore}
                    style={{ marginBottom: '10px' }}
                >
                    {messages.map((msg, index) => {
                        return (
                            <DisplayMessage
                                key={index}
                                message={msg}
                                client={client}
                                msgListRef={msgListRef}
                            />
                        );
                    })}
                </MessageList>

                <MessageInput
                    onAttachClick={() => fileInputRef.current?.click()}
                    placeholder="Type message here..."
                    value={value}
                    onChange={handleInputChange}
                    onPaste={(evt: React.ClipboardEvent<HTMLInputElement>) => {
                        evt.preventDefault();
                        setValue(evt.clipboardData.getData('text')); // get text representation of clipboard
                    }}
                    onSend={handleSend}
                />
            </ChatContainer>
            <ParticipantsModal
                isOpen={participantsModal}
                setIsOpen={setModal}
                participants={
                    selectedConversation &&
                    selectedConversation.sid &&
                    conversationData[selectedConversation.sid]
                        ? conversationData[selectedConversation.sid]
                              .friendly_map
                        : {}
                }
            />
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </>
    );
};

interface ModalProps {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    participants: Record<string, string>;
}

const ParticipantsModal: React.FC<ModalProps> = ({
    isOpen,
    setIsOpen,
    participants,
}) => {
    const { data } = useGetIdentity();
    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <DialogTitle>Participants</DialogTitle>
            <DialogContent dividers>
                <List>
                    <ListItem>
                        {data ? (
                            <ListItemText
                                primary={`${data.display_name}, ${data.email}`}
                            />
                        ) : (
                            <></>
                        )}
                    </ListItem>
                    {Object.entries(participants).map(([key, value], index) => (
                        <ListItem key={index}>
                            <ListItemText primary={`${value}, ${key}`} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsOpen(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
