import React from 'react';
import { useNotify } from 'react-admin';
import { DefaultToolbar } from './DefaultToolbar';

interface DefaultEditToolbar {
    resource:
        | 'operation'
        | 'project'
        | 'site'
        | 'task'
        | 'user'
        | 'template_operation'
        | 'template_task'
        | 'evaluation';
    showCopy?: boolean;
}
/**
 * This component  to standardize the buttons and behaviours for EDIT components
 * including the messages and notifications
 */

export const DefaultEditToolbar: React.FC<DefaultEditToolbar> = ({
    resource,
    showCopy = false,
}) => {
    const notify = useNotify();

    return (
        <DefaultToolbar
            mutationOptions={{
                onSuccess: () =>
                    notify(`resources.${resource}.notification.saved`, {
                        undoable: true,
                    }),
            }}
            resource={resource}
            showCopy={showCopy}
            showDelete
        />
    );
};
