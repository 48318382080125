import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer } from '@chatscope/chat-ui-kit-react';
import ArrowLeftIcon from '@mui/icons-material/West';
import { SideBar } from './SideBar';
import { ChatBox } from './ChatBox';
import { TempChatBox } from './components/TempChatBox';
import { Loading, Title, useTranslate, useAuthenticated } from 'react-admin';
import { Client, Conversation } from '@twilio/conversations';
import { LocalConversation, useTwilio } from '../contexts/TwilioProvider';
import { useParams } from 'react-router-dom';

export const ChatRoom: React.FC = () => {
    useAuthenticated();
    const translate = useTranslate();

    const {
        client,
        conversations,
        connected,
        setConversation,
        localConversation,
    } = useTwilio() as {
        client: Client | null;
        conversations: Conversation[];
        connected: boolean;
        localConversation: LocalConversation | null;
        setConversation: React.Dispatch<Conversation | null>;
    };

    const { conversationId } = useParams();

    useEffect(() => {
        if (conversationId) {
            const selected = conversations.find(
                (convo) => convo.sid === conversationId,
            );
            setConversation(selected || null);
        }

        return () => {
            setConversation(null);
        };
    }, [conversationId, conversations, setConversation]);

    // const handleClick = async () => {
    //     if (client && conversations.length > 0) {
    //         const deletionPromises = conversations.map(async (conversation) => {
    //             try {
    //                 await conversation.delete();
    //                 console.log(`Conversation ${conversation.sid} deleted.`);
    //             } catch (error) {
    //                 console.error(
    //                     `Failed to delete conversation ${conversation.sid}: `,
    //                     error,
    //                 );
    //             }
    //         });
    //         try {
    //             await Promise.all(deletionPromises);
    //             console.log('All conversations have been deleted.');
    //         } catch (error) {
    //             console.error(
    //                 'An error occurred while deleting conversations: ',
    //                 error,
    //             );
    //         }
    //     }
    // };

    return (
        <Box sx={containerStyle}>
            <Title title={translate('resources.chat.name')} />

            <Box sx={chatWrapperStyle}>
                {client !== null && connected ? (
                    <Box height="calc(100vh - 60px)">
                        {/* <button onClick={handleClick} /> */}
                        <MainContainer responsive style={styles}>
                            <SideBar />
                            {conversationId ? (
                                <ChatBox />
                            ) : localConversation ? (
                                <TempChatBox />
                            ) : (
                                <div style={noChatContentStyles}>
                                    <ArrowLeftIcon />
                                    <h2>Select a conversation</h2>
                                </div>
                            )}
                        </MainContainer>
                    </Box>
                ) : (
                    <Loading />
                )}
            </Box>
        </Box>
    );
};

const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
};

const chatWrapperStyle = {
    height: '99%',
    width: '100%',
};

const noChatContentStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    '& h2': {
        marginLeft: '20px',
    },
};
