import React, { useState, useEffect, RefObject } from 'react';
import { format } from 'date-fns';
import { Message } from '@chatscope/chat-ui-kit-react';
import { Client, Message as TwilioMessage, Media } from '@twilio/conversations';
import { Modal, Box } from '@mui/material';

import { getAvatar } from '../components/ChatAvatar';

interface MediaMessageProps {
    message: TwilioMessage;
    client: Client;
    msgListRef: RefObject<any>;
    friendly_map: Record<string, string>;
}

const MediaMessage: React.FC<MediaMessageProps> = ({
    message,
    msgListRef,
    client,
    friendly_map,
}) => {
    const [mediaUrls, setMediaUrls] = useState<(string | null)[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(
        null,
    );

    const handleImageClick = (url: string) => {
        setSelectedImageUrl(url);
        setOpenModal(true);
    };
    useEffect(() => {
        const fetchMediaUrls = async () => {
            if (message.attachedMedia) {
                const urls = await Promise.all(
                    message.attachedMedia.map(async (media: Media) => {
                        const url = await media.getContentTemporaryUrl();
                        return url;
                    }),
                );
                setMediaUrls(urls);
                msgListRef.current?.scrollToBottom('auto');
            }
        };

        if (message.attachedMedia && message.attachedMedia.length > 0)
            fetchMediaUrls();
    }, [message.sid]);

    return (
        <div>
            {mediaUrls.map((url, index) => (
                <div key={index} onClick={() => url && handleImageClick(url)}>
                    <Message
                        model={{
                            sentTime: message.dateCreated
                                ? message.dateCreated.toDateString()
                                : '',
                            sender: message.author
                                ? friendly_map[message.author] || message.author
                                : 'Unkown',
                            direction:
                                message.author === client.user.identity
                                    ? 'outgoing'
                                    : 'incoming',
                            position: 'single',
                        }}
                    >
                        {message.author &&
                            getAvatar(
                                (
                                    message.attributes as {
                                        authorFriendlyName: string;
                                    }
                                )?.authorFriendlyName || message.author
                                    ? friendly_map[message.author] ||
                                          message.author
                                    : 'Unkown',
                            )}
                        <Message.Header>
                            {message.dateCreated
                                ? format(message.dateCreated, 'PPpp')
                                : ''}
                        </Message.Header>
                        <Message.CustomContent>
                            <img
                                src={url || ''}
                                alt="image"
                                height={250}
                                crossOrigin="anonymous"
                            />
                        </Message.CustomContent>
                    </Message>
                </div>
            ))}
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="image-modal"
                aria-describedby="image-popup"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <img
                        src={selectedImageUrl || ''}
                        alt="Reload the page to load the images again"
                        style={{ maxWidth: '100%', maxHeight: '80vh' }}
                        crossOrigin="anonymous"
                    />
                </Box>
            </Modal>
        </div>
    );
};

export default MediaMessage;
