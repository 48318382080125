import { convertToUTCDateTime } from '../dataProvider';

export const siteLifeCycleCallbacks = {
    resource: 'site',
    beforeUpdate: async (params: any, dataProvider: any) => {
        const newParams = { ...params };
        const { data, previousData } = newParams;
        const { links, scheduled_start, scheduled_end, time_region, ...rest } =
            data;

        dataProvider.resetLink('site', {
            resource_id: data?.id,
            data: { links: links },
        });

        if (scheduled_start) {
            const newScheduledStart = convertToUTCDateTime(
                scheduled_start,
                time_region,
                params.previousData.time_region,
            );
            newParams.data.scheduled_start = newScheduledStart;
        }

        if (scheduled_end) {
            const newScheduledEnd = convertToUTCDateTime(
                scheduled_end,
                time_region,
                params.previousData.time_region,
            );
            newParams.data.scheduled_end = newScheduledEnd;
        }

        return newParams;
    },
};
