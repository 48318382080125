import React, { useEffect, useState } from 'react';
import { Stack, Tooltip } from '@mui/material';
import Chat from '@mui/icons-material/Chat';
import CircleIcon from '@mui/icons-material/Circle';
import PhotoIcon from '@mui/icons-material/Photo';
import {
    ArrayField,
    ChipField,
    DatagridConfigurable,
    DateField,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    RaRecord,
    SelectInput,
    SelectColumnsButton,
    SingleFieldList,
    TextField,
    TextInput,
    TopToolbar,
    useDataProvider,
    useGetIdentity,
    useRecordContext,
    useRedirect,
} from 'react-admin';
import { DefaultPerPage, defaultLocale, dateOptions } from '../constants';
import {
    EnhancedEmpty,
    EnhancedPagination,
    LinkWrapper,
    ListCreateButton,
    TimeZoneDateField,
} from '../components';
import { Project, Site } from '../types';
import useCreateTempGroupChat from '../hooks/useCreateTempGroupChat';
import { Button } from 'react-admin';

const ProjectListActions = () => (
    <TopToolbar>
        <FilterButton />
        <SelectColumnsButton />
        <ListCreateButton resource={'project'} />
        <ExportButton />
    </TopToolbar>
);

const ProjectListExpand: React.FC<{ current_user_id: string }> = ({
    current_user_id,
}) => {
    const record = useRecordContext<Project>();
    const redirect = useRedirect();
    const createTempGroupChat = useCreateTempGroupChat();

    const handlePhotosClick = () => {
        redirect(`/project/${record.id}/show`);
    };

    return (
        <Stack spacing={2} direction="row">
            <Button
                color="primary"
                onClick={handlePhotosClick}
                label="actions.photos"
            >
                <PhotoIcon />
            </Button>
            {current_user_id == record?.project_manager?.id && (
                <Button
                    color="primary"
                    onClick={() => {
                        createTempGroupChat('project');
                    }}
                    label="actions.project_chat"
                >
                    <Chat />
                </Button>
            )}
        </Stack>
    );
};

export const ProjectList: React.FC = () => {
    const redirect = useRedirect();
    const { data } = useGetIdentity();
    const { getAll } = useDataProvider();
    const [options, setOptions] = useState([]);

    const getManagers = async () => {
        try {
            await getAll(`user`, {
                filter: { role__in: ['Admin', 'Manager'] },
                perPage: 'all',
            }).then((res: any) => setOptions(res.data));
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        getManagers();
    }, [data]);

    if (data === undefined || data.id === undefined) return null;

    const projectFilters = [
        // <QuickFilter
        //     label="resources.project.filters.pm_fk"
        //     source="pm_fk"
        //     defaultValue={data?.id}
        //     key={'project-filters-00'}
        // />,
        <SelectInput
            label="resources.project.filters.pm_fk"
            key={'project-filters-02'}
            source="pm_fk"
            defaultValue={data?.id}
            choices={options}
            optionText={(choice) => `${choice.first_name} ${choice.last_name}`}
        />,
        <TextInput key={'project-filters-02'} source="title" />,
        <TextInput key={'project-filters-03'} source="project_code" />,
    ];

    return (
        <List
            actions={<ProjectListActions />}
            filters={projectFilters}
            filterDefaultValues={{ pm_fk: data?.id }} // sets the filter to value on initial load
            sort={{ field: 'id', order: 'DESC' }}
            pagination={<EnhancedPagination />}
            perPage={DefaultPerPage}
            empty={<EnhancedEmpty resource="project" />}
            title="resources.project.name"
        >
            <DatagridConfigurable
                expand={
                    <ProjectListExpand current_user_id={data.id.toString()} />
                }
                rowClick="edit"
                bulkActionButtons={false}
            >
                {/* <FunctionField label={'Status'} render={() => <StatusIcon />} /> TODO: replace with new approach */}
                <LinkWrapper source="title">
                    <TextField
                        source="title"
                        sx={{
                            color: 'black',
                            textDecoration: 'underline',
                        }}
                    />
                </LinkWrapper>
                <TextField source="project_code" />
                <TextField source="status" />
                <TimeZoneDateField source="current_start" />
                <TimeZoneDateField source="current_end" />
                <TimeZoneDateField source="scheduled_start" />
                <TimeZoneDateField source="scheduled_end" />
                <DateField
                    source="updated_on"
                    locales={defaultLocale}
                    options={dateOptions}
                />
                <FunctionField
                    label="Project Manager"
                    render={(record: RaRecord) => {
                        if (!record?.project_manager) return;
                        return (
                            <Tooltip
                                title={`${
                                    record?.project_manager?.first_name
                                        ? record.project_manager.first_name
                                              .charAt(0)
                                              .toUpperCase() +
                                          record.project_manager.first_name.slice(
                                              1,
                                          )
                                        : ''
                                } ${
                                    record?.project_manager?.last_name
                                        ? record.project_manager.last_name
                                              .charAt(0)
                                              .toUpperCase() +
                                          record.project_manager.last_name.slice(
                                              1,
                                          )
                                        : ''
                                }`}
                            >
                                <span onClick={(e) => e.stopPropagation()}>
                                    <ChipField
                                        onClick={() =>
                                            redirect(
                                                `/user/${record.project_manager.id}/show`,
                                            )
                                        }
                                        record={{
                                            name: `${
                                                record?.project_manager?.first_name
                                                    ?.charAt(0)
                                                    .toUpperCase() ?? ''
                                            }${
                                                record?.project_manager?.last_name
                                                    ?.charAt(0)
                                                    .toUpperCase() ?? ''
                                            }`,
                                        }}
                                        source="name"
                                    />
                                </span>
                            </Tooltip>
                        );
                    }}
                />
                <ArrayField
                    label="Technicians"
                    source="all_users"
                    sortable={false}
                >
                    <SingleFieldList linkType={false}>
                        <FunctionField
                            render={(record: RaRecord) => {
                                if (!record) return;
                                return (
                                    <Tooltip
                                        title={`${
                                            record.first_name
                                                ? record.first_name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                  record.first_name.slice(1)
                                                : ''
                                        } ${
                                            record.last_name
                                                ? record.last_name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                  record.last_name.slice(1)
                                                : ''
                                        }`}
                                    >
                                        <span
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <ChipField
                                                onClick={() =>
                                                    redirect(
                                                        `/user/${record.id}/show`,
                                                    )
                                                }
                                                record={{
                                                    name: `${
                                                        record?.first_name
                                                            ?.charAt(0)
                                                            .toUpperCase() ?? ''
                                                    }${
                                                        record?.last_name
                                                            ?.charAt(0)
                                                            .toUpperCase() ?? ''
                                                    }`,
                                                }}
                                                source="name"
                                                variant={
                                                    !record?.site_fk
                                                        ? 'filled'
                                                        : 'outlined'
                                                }
                                            />
                                        </span>
                                    </Tooltip>
                                );
                            }}
                        />
                    </SingleFieldList>
                </ArrayField>
            </DatagridConfigurable>
        </List>
    );
};

type StatusType = 'initial' | 'completed' | 'delayed' | 'in progress';

const isStatusType = (status: unknown): status is StatusType => {
    return (
        status === 'initial' ||
        status === 'completed' ||
        status === 'delayed' ||
        status === 'in progress'
    );
};

const StatusIcon: React.FC = () => {
    const [color, setColor] = useState<string>('grey');
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    const statusColor: {
        [key in StatusType]: { [key in 'true' | 'false']: string };
    } = {
        initial: { true: 'grey', false: 'grey' },
        completed: { true: 'green', false: 'grey' },
        delayed: { true: 'green', false: 'red' },
        'in progress': { true: 'green', false: 'red' },
    };
    useEffect(() => {
        const fetchActiveTechs = async () => {
            const project: Project = (
                await dataProvider.customGetAll(`/project/${record.id}`)
            ).data;
            const fetchPromises = project.sites.map(async (site: Site) => {
                const data = await dataProvider.customGetAll(
                    `/site/${site.id}`,
                );
                return data.data.checking_ids;
            });
            const allSiteIDs = await Promise.all(fetchPromises);
            const allIDs = allSiteIDs.flat();

            if (isStatusType(record.status)) {
                const hasIds = allIDs.length > 0 ? 'true' : 'false';
                setColor(statusColor[record.status][hasIds]);
            }
        };
        fetchActiveTechs();
    }, []);

    return (
        <Tooltip title={<h2>{record.status}</h2>}>
            <CircleIcon sx={{ color: color }} />
        </Tooltip>
    );
};
