import SiteIcon from '@mui/icons-material/MapsHomeWork';
import { SiteEdit } from './SiteEdit';
import { NotFound } from 'react-admin';

export default {
    create: NotFound,
    icon: SiteIcon,
    list: NotFound,
    edit: SiteEdit,
};
