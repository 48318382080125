import { timeZone } from '../dataProvider';
export const DefaultPerPageOptions = [10, 25, 50, 100];
export const DefaultPerPage = 25;
export const registerRoles = [
    { id: 'Manager', name: 'Manager' },
    { id: 'Client', name: 'Client', disabled: true },
];
export const loginRoles = [{ id: 'Admin', name: 'Admin' }, ...registerRoles];
export const roles = [{ id: 'Technician', name: 'Technician' }, ...loginRoles];
export const statuses = [
    { id: 'initial', name: 'initial' },
    { id: 'in progress', name: 'in progress' },
    { id: 'delayed', name: 'delayed' },
    { id: 'completed', name: 'completed' },
];
export const dateOptions: Intl.DateTimeFormatOptions = {
    timeZone: timeZone,
};
export const dateTimeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    ...dateOptions,
};
export const defaultLocale = 'en-CA';

export const ZOOM_ONE_TO_ONE = '0';
export const ZOOM_GROUP = '1';

const daysToHours = (days = 0): number => days * 24;
const hoursToMinutes = (hours = 0): number => hours * 60;
const minutesToSeconds = (minutes = 0): number => minutes * 60;
const secondsToMilliseconds = (seconds = 0): number => seconds * 1000; // milliseconds
type TimeDuration = {
    hours?: number;
    minutes?: number;
    seconds?: number;
};
export const durationTimeToMilliseconds = ({
    hours = 0,
    minutes = 0,
    seconds = 0,
}: TimeDuration): number =>
    secondsToMilliseconds(
        minutesToSeconds(hoursToMinutes(hours) + minutes) + seconds,
    );
type DayDuration = {
    days?: number;
} & TimeDuration;
export const durationDaysToMilliseconds = ({
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
}: DayDuration): number =>
    durationTimeToMilliseconds({
        hours: daysToHours(days) + hours,
        minutes,
        seconds,
    });
