import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useCallback, MouseEventHandler } from 'react';
import PropTypes, { ReactComponentLike } from 'prop-types';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import AlertError from '@mui/icons-material/ErrorOutline';
import { useTranslate } from 'ra-core';
import {
    CreateBase,
    RaRecord,
    SaveButton,
    SimpleForm,
    Toolbar,
    useEditContext,
    useNotify,
    useRecordContext,
    useRedirect,
} from 'react-admin';

type Parent = {
    parent: string;
    key: string;
};

type ParentLookup = {
    [P: string]: Parent;
};

const parent_lookup: ParentLookup = {
    site: {
        parent: 'project',
        key: 'project_fk',
    },
};
/**
 * Form dialog
 *
 * @example
 * <FormDialog
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     CancelIcon=AlertError
 *     cancel="Cancel"
 *     onClose={() => { // do something }}
 * />
 */
export const FormDialog = (props: FormDialogProps) => {
    const {
        className,
        isOpen = false,
        title,
        cancel = 'ra.action.cancel',
        CancelIcon = AlertError,
        onClose,
        children,
        ...rest
    } = props;
    const record = useRecordContext();
    const recordId = record.id;
    const {
        resource, // Resource name deduced from the location. e.g. 'posts'
    } = useEditContext();
    const translate = useTranslate();

    const handleClick = useCallback((e) => {
        e.stopPropagation();
    }, []);

    const FormDialogToolbar = () => {
        return (
            <Toolbar>
                <Button
                    onClick={() => onClose(false)}
                    startIcon={<CancelIcon />}
                >
                    {translate(cancel, { _: cancel })}
                </Button>
                <SaveButton alwaysEnable />
            </Toolbar>
        );
    };
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data: RaRecord) => {
        onClose(false);
        const options = {
            smart_count: Array.isArray(data.id) ? data.id.length : 1,
            resource,
        };
        notify(translate('actions.copy.notification', { ...options }));

        redirect(
            'edit',
            parent_lookup[resource].parent,
            record[parent_lookup[resource].key],
        );
    };

    const onError = (error: any) => {
        notify(
            translate('actions.copy.error', {
                resource,
                error,
            }),
        );
    };

    return (
        <StyledDialog
            className={className}
            open={isOpen}
            onClick={handleClick}
            aria-labelledby="alert-dialog-title"
            {...rest}
        >
            <DialogTitle id="alert-dialog-title">
                {translate(title, { _: title })}
            </DialogTitle>
            <CreateBase
                mutationOptions={{
                    meta: { id: recordId },
                    onError,
                    onSuccess,
                }}
            >
                <SimpleForm toolbar={<FormDialogToolbar />}>
                    {children}
                </SimpleForm>
            </CreateBase>
        </StyledDialog>
    );
};

export interface FormDialogProps extends Omit<DialogProps, 'open' | 'onClose'> {
    cancel?: string;
    className?: string;
    CancelIcon?: ReactComponentLike;
    isOpen?: boolean;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    onSave?: MouseEventHandler;
    title: string;
}

FormDialog.propTypes = {
    cancel: PropTypes.string,
    className: PropTypes.string,
    CancelIcon: PropTypes.elementType,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    title: PropTypes.string.isRequired,
    sx: PropTypes.any,
};

const PREFIX = 'RaFormDialog';

export const ConfirmClasses = {
    confirmPrimary: `${PREFIX}-confirmPrimary`,
    confirmWarning: `${PREFIX}-confirmWarning`,
};

const StyledDialog = styled(Dialog, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${ConfirmClasses.confirmPrimary}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${ConfirmClasses.confirmWarning}`]: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
}));
