/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Project, User, Site } from '../../types';
import CircleIcon from '@mui/icons-material/Circle';
import {
    useRecordContext,
    Identifier,
    useTranslate,
} from 'react-admin';
import { Tooltip } from '@material-ui/core';

interface StatusIconProps {
    projectId?: Identifier;
    resource: string;
    page?: string;
}

export const StatusIcon: React.FC<StatusIconProps> = ({
    projectId,
    resource,
    page,
}) => {
    const [color, setColor] = useState('grey');
    const translate = useTranslate();

    let record: any;
    if (resource === 'project') {
        record = useRecordContext<Project>();
    } else if (resource === 'technician') {
        record = useRecordContext<User>();
    } else if (resource === 'site') {
        record = useRecordContext<Site>();
    }

    const checkProjectStatus = () => {
        const curTechs = record.users ?? [];
        let flag = 'grey';
        if (record.status !== 'initial' && record.status !== 'completed') {
            //before this project starts or after its completed, color is grey
            //if the project has started and not finished yet, check if its users checked in
            flag = 'red';
            if (curTechs) {
                for (let index = 0; index < curTechs.length; index++) {
                    const ct = curTechs[index];
                    if (!ct.last_check) continue;
                    else if (ct.last_check?.site.project_fk === record.id) {
                        if (ct.last_check.status === null) {
                            flag = 'green';
                            break;
                        }
                    }
                }
            }
        } else if (record.status === 'completed') {
            if (curTechs) {
                for (let index = 0; index < curTechs.length; index++) {
                    const ct = curTechs[index];
                    if (!ct.last_check) continue;
                    else if (ct.last_check?.site.project_fk === record.id) {
                        if (ct.last_check.status === null) {
                            flag = 'green';
                            break;
                        }
                    }
                }
            }
        }
        setColor(flag);
    };

    const checkTechStatus = () => {
        let flag = 'grey';
        if (!page) {
            if (
                record.last_check &&
                record.last_check?.status === null &&
                record.last_check?.site?.project_fk === projectId
            ) {
                flag = 'green';
            } else {
                flag = 'red';
            }
        } else if (page === 'technician') {
            if (record.last_check && record.last_check?.status === null) {
                flag = 'green';
            } else {
                flag = 'red';
            }
        }
        setColor(flag);
    };

    const checkSiteStatus = () => {
        const curTechs = record.checking_users ?? [];
        const curTechsIds = record.checking_ids ?? [];
        let flag = 'grey';
        if (record.status !== 'initial' && record.status !== 'completed') {
            //before this project starts or after its completed, color is grey
            //if the project has started and not finished yet, check if its users checked in
            flag = 'red';
            if (curTechs.length > 0 && curTechsIds.length > 0) {
                flag = 'green';
            }
        } else if (record.status === 'completed') {
            if (curTechs.length > 0 && curTechsIds.length > 0) {
                flag = 'green';
            }
        }
        setColor(flag);
    };

    useEffect(() => {
        if (resource === 'project') {
            checkProjectStatus();
        } else if (resource === 'technician') {
            checkTechStatus();
        } else if (resource === 'site') {
            checkSiteStatus();
        }
    }, []);

    // return <Icon key={projectRecord.id} path={mdiTicket} size={1} color={color} />;
    return (
        <>
            <Tooltip
                title={<h2>{translate(`status.${resource}.${color}`)}</h2>}
            >
                <CircleIcon sx={{ color: color }} />
            </Tooltip>
        </>
    );
};
