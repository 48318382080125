import { Identifier, useListContext } from 'ra-core';
import React, { createContext, useState, useEffect } from 'react';
import { Stack, CircularProgress, Typography, Tooltip } from '@mui/material';
import {
    RaRecord,
    ReferenceManyField,
    useTranslate,
    ReferenceManyFieldProps,
    useNotify,
} from 'react-admin';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {
    addDays,
    compareAsc,
    format,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subDays,
    subWeeks,
    subYears,
} from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import {
    writeDFRMParams,
    dateSpan,
    timeSpans,
    readDFRMParams,
} from './DFRMRouter';
import { enUS } from 'date-fns/locale';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SearchIcon from '@mui/icons-material/Search';

type DateFiltersProps = {
    timeSpan: string;
    setTimeSpan: React.Dispatch<React.SetStateAction<string>>;
    setFilterValues: React.Dispatch<
        React.SetStateAction<{ start: string; end: string } | undefined>
    >;
};

const DateFilters: React.FunctionComponent<DateFiltersProps> = ({
    timeSpan,
    setTimeSpan,
    setFilterValues,
}) => {
    const translate = useTranslate();
    const [, setSearchParams] = useSearchParams();

    const currentDate = startOfDay(new Date());

    const weekStart = startOfWeek(currentDate, { weekStartsOn: 0 });
    const monthStart = startOfMonth(currentDate);
    const lastWeekStart = subWeeks(currentDate, 1);
    const lastTwoWeekStart = subWeeks(currentDate, 2);
    const lastMonthStart = subDays(currentDate, 30);
    const lastYearStart = addDays(subYears(currentDate, 1), 1);

    const [dateRange, setDateRange] = useState<dateSpan>({
        start: weekStart,
        end: currentDate,
    });
    const notify = useNotify();

    const getStartDate: () => Date = () => {
        if (timeSpan === timeSpans.lastWeek) return lastWeekStart;
        else if (timeSpan === timeSpans.lastTwoWeeks) return lastTwoWeekStart;
        else if (timeSpan === timeSpans.lastMonth) return lastMonthStart;
        else if (timeSpan === timeSpans.lastYear) return lastYearStart;
        else if (timeSpan === timeSpans.currentMonth) return monthStart;
        else if (timeSpan === timeSpans.custom && dateRange.start !== null)
            return dateRange.start;
        else return weekStart;
    };

    const getEndDate: () => Date = () => {
        if (timeSpan === timeSpans.custom && dateRange.end !== null)
            return dateRange.end;
        else return currentDate;
    };

    const changeParamsAndFilters = () => {
        const newfilterValue = {
            start: format(getStartDate(), 'yyy-MM-dd'),
            end: format(getEndDate(), 'yyy-MM-dd'),
        };
        setFilterValues(newfilterValue);
        writeDFRMParams(newfilterValue, setSearchParams);
    };

    const validDateRange: (start: Date | null, end: Date | null) => boolean = (
        start: Date | null,
        end: Date | null,
    ) => {
        if (start === null || end === null) {
            notify('Please select a start and end date!', { type: 'error' });
            return false;
        } else if (compareAsc(start, end) === 1) {
            notify('End date cannot come before start date!', {
                type: 'error',
            });
            return false;
        }
        return true;
    };

    useEffect(() => {
        const urlParams = readDFRMParams();
        if (
            urlParams.dateFilter.start !== null &&
            urlParams.dateFilter.end !== null &&
            validDateRange(urlParams.dateFilter.start, urlParams.dateFilter.end)
        ) {
            setDateRange(urlParams.dateFilter);
            const newfilterValue = {
                start: format(urlParams.dateFilter.start, 'yyy-MM-dd'),
                end: format(urlParams.dateFilter.end, 'yyy-MM-dd'),
            };
            setFilterValues(newfilterValue);
        }
    }, [window.location.href]);

    useEffect(() => {
        const startDate = getStartDate();
        setDateRange({ start: startDate, end: currentDate });
        changeParamsAndFilters();
    }, [timeSpan]);

    const handleTimeSpanChange = (newTimeSpan: string) => {
        setTimeSpan(newTimeSpan);
    };

    const handleCustomDateRange = () => {
        if (!validDateRange(dateRange.start, dateRange.end)) return;
        if (dateRange.start !== null && dateRange.end !== null) {
            const startDate = new Date(dateRange.start);
            const endDate = new Date(dateRange.end);
            const setStartDate = startDate.setHours(0, 0, 0, 0);
            const setEndDate = endDate.setHours(0, 0, 0, 0);
            const setCurrentEnd = currentDate.setHours(0, 0, 0, 0);

            const dateRangeToTimeSpan = new Map([
                [weekStart.setHours(0, 0, 0, 0), timeSpans.currentWeek],
                [monthStart.setHours(0, 0, 0, 0), timeSpans.currentMonth],
                [lastWeekStart.setHours(0, 0, 0, 0), timeSpans.lastWeek],
                [lastTwoWeekStart.setHours(0, 0, 0, 0), timeSpans.lastTwoWeeks],
                [lastMonthStart.setHours(0, 0, 0, 0), timeSpans.lastMonth],
            ]);

            const selectedTimeSpan = dateRangeToTimeSpan.get(setStartDate);

            if (setCurrentEnd === setEndDate) {
                if (selectedTimeSpan) {
                    setTimeSpan(selectedTimeSpan);
                } else {
                    setTimeSpan(timeSpans.custom);
                }
            } else {
                setTimeSpan(timeSpans.custom);
                changeParamsAndFilters();
            }
        }
    };

    const showContained = (componentTimeSpan: string, stateTimeSpan: string) =>
        componentTimeSpan === stateTimeSpan ? 'contained' : 'outlined';

    const timeSpanButtons = [
        {
            timeSpan: timeSpans.currentWeek,
            label: `reporting.time_span.${timeSpans.currentWeek}`,
            title: `${format(weekStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
        {
            timeSpan: timeSpans.currentMonth,
            label: `reporting.time_span.${timeSpans.currentMonth}`,
            title: `${format(monthStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
        {
            timeSpan: timeSpans.lastWeek,
            label: `reporting.time_span.${timeSpans.lastWeek}`,
            title: `${format(lastWeekStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
        {
            timeSpan: timeSpans.lastTwoWeeks,
            label: `reporting.time_span.${timeSpans.lastTwoWeeks}`,
            title: `${format(lastTwoWeekStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
        {
            timeSpan: timeSpans.lastMonth,
            label: `reporting.time_span.${timeSpans.lastMonth}`,
            title: `${format(lastMonthStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
        {
            timeSpan: timeSpans.lastYear,
            label: `reporting.time_span.${timeSpans.lastYear}`,
            title: `${format(lastYearStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
    ];

    const buttonGroups = [
        { timeSpans: timeSpanButtons.slice(0, 2) },
        { timeSpans: timeSpanButtons.slice(2) },
    ];

    return (
        <>
            <Stack direction="row" sx={{ m: 2 }} spacing={4}>
                {buttonGroups.map((group, index) => (
                    <ButtonGroup color={'primary'} key={index}>
                        {group.timeSpans.map((btn, btnIndex) => (
                            <Tooltip
                                title={btn.title}
                                placement="top"
                                key={`date-filters-${btnIndex}`}
                            >
                                <Button
                                    variant={showContained(
                                        btn.timeSpan,
                                        timeSpan,
                                    )}
                                    onClick={() =>
                                        handleTimeSpanChange(btn.timeSpan)
                                    }
                                >
                                    {translate(btn.label)}
                                </Button>
                            </Tooltip>
                        ))}
                    </ButtonGroup>
                ))}
                <Stack direction="row" sx={{ m: 2 }} spacing={2}>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={enUS}
                    >
                        <DatePicker
                            sx={{ maxWidth: '225px' }}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    label: 'Start Date',
                                    InputLabelProps: { shrink: true },
                                },
                            }}
                            defaultValue={dateRange.start}
                            value={dateRange.start}
                            onChange={(startDate) => {
                                setDateRange({
                                    start: startDate,
                                    end: dateRange.end,
                                });
                            }}
                        />
                        <DatePicker
                            sx={{ maxWidth: '225px' }}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    label: 'End Date',
                                    InputLabelProps: { shrink: true },
                                },
                            }}
                            defaultValue={dateRange.end}
                            value={dateRange.end}
                            onChange={(endDate) => {
                                setDateRange({
                                    start: dateRange.start,
                                    end: endDate,
                                });
                            }}
                        />
                        <Button
                            variant="outlined"
                            sx={{ maxWidth: '15px' }}
                            onClick={handleCustomDateRange}
                        >
                            <SearchIcon />
                        </Button>
                    </LocalizationProvider>
                </Stack>
            </Stack>
        </>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DateFilteredReferenceManyDisplay: React.FunctionComponent<any> = ({
    children,
}) => {
    const translate = useTranslate();
    const { data, isLoading, isFetching } = useListContext();

    if (isLoading || isFetching) {
        return <CircularProgress size={25} thickness={2} />;
    }

    if (!isLoading && !isFetching) {
        if (!data)
            return (
                <Typography>
                    {translate('reporting.total.fetchFail')}
                </Typography>
            );
        if (!data.length)
            return (
                <Typography>{translate('reporting.total.noData')}</Typography>
            );
        return children;
    }
};

const currentDate = startOfDay(new Date());
const weekFilter = {
    start: format(startOfWeek(currentDate), 'yyy-MM-dd'),
    end: format(currentDate, 'yyy-MM-dd'),
};

export const DateFilteredReferenceManyContext = createContext({
    timeSpan: timeSpans.currentWeek,
    filterValues: weekFilter,
});

interface DateFilteredReferenceManyProps
    extends ReferenceManyFieldProps<RaRecord<Identifier>> {
    timeSpan: string;
    setTimeSpan: React.Dispatch<React.SetStateAction<string>>;
    filterValues: { start: string; end: string } | undefined;
    setFilterValues: React.Dispatch<
        React.SetStateAction<{ start: string; end: string } | undefined>
    >;
}

export const DateFilteredReferenceMany: React.FunctionComponent<
    DateFilteredReferenceManyProps
> = ({
    children,
    label,
    reference,
    target,
    timeSpan,
    setTimeSpan,
    filterValues,
    setFilterValues,
    ...rest
}) => {
    return (
        <ReferenceManyField
            label={label}
            reference={reference}
            target={target}
            filter={filterValues ?? weekFilter}
            {...rest}
        >
            <DateFilters
                timeSpan={timeSpan}
                setTimeSpan={setTimeSpan}
                setFilterValues={setFilterValues}
            />
            <DateFilteredReferenceManyContext.Provider
                value={{
                    timeSpan: timeSpan,
                    filterValues: filterValues ?? weekFilter,
                }}
            >
                <DateFilteredReferenceManyDisplay>
                    {children}
                </DateFilteredReferenceManyDisplay>
            </DateFilteredReferenceManyContext.Provider>
        </ReferenceManyField>
    );
};
