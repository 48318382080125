import React from 'react';
import {
    BooleanInput,
    required,
    SimpleForm,
    TextInput,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { TemplateTask } from '../types';
import { InfoIcon, DefaultEditToolbar, SublistEdit } from '../components';
import { BooleanNumberToggle } from '../components/BooleanNumberToggle';

const TemplateTaskTitle = () => {
    const record = useRecordContext<TemplateTask>();
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.template_task.name')} - {record.title}
            <InfoIcon resource={'task_template'} record={record} />
        </span>
    ) : null;
};

export const TemplateTaskEdit: React.FC = () => {
    return (
        <>
            {
                <SublistEdit
                    title={<TemplateTaskTitle />}
                    resource="template_task"
                >
                    <div>
                        <SimpleForm
                            toolbar={
                                <DefaultEditToolbar resource="template_task" />
                            }
                            shouldUnregister
                        >
                            <TextInput
                                autoFocus
                                source="title"
                                validate={required()}
                            />
                            <TextInput source="description" fullWidth />
                            <BooleanNumberToggle
                                boolSrc="photo_required"
                                boolLabel="resources.template_task.fields.photo_required"
                                numSrc="photo_num"
                                numLabel="resources.template_task.fields.photo_num"
                                min={1}
                            />
                            <BooleanInput
                                source="note_required"
                                label="Note Required?"
                            />
                        </SimpleForm>
                    </div>
                </SublistEdit>
            }
        </>
    );
};
