import {
    Autocomplete,
    AutocompleteProps,
    Checkbox,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    TextField,
} from '@mui/material';
import {
    Cancel,
    CheckBox,
    CheckBoxOutlineBlank,
    Refresh,
} from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import {
    Identifier,
    RaRecord,
    useNotify,
    useRecordContext,
    useTranslate,
} from 'react-admin';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

/* eslint-disable @typescript-eslint/no-explicit-any */
export type EnhancedMultiAutoCompleteProps<T extends RaRecord> = {
    action: (
        arrayIds: Identifier[],
        recordId: Identifier,
    ) => Promise<void | any[]>;
    assignedOptions?: T[];
    getDefaultValues?: (record: RaRecord) => T[] | undefined;
    labelResource: (record: T) => string;
    loadResource: (recordId: Identifier) => Promise<any>;
    onDelete?: (event: any) => void | undefined;
    resource: 'supports' | 'user';
    submitOnClear?: boolean;
} & Omit<
    AutocompleteProps<T, true, true, false>,
    'options' | 'renderInput' | 'multiple'
>;
/* eslint-enable @typescript-eslint/no-explicit-any */

export const EnhancedMultiAutoComplete = <T extends RaRecord>({
    action,
    assignedOptions = [],
    getDefaultValues = () => undefined,
    labelResource,
    loadResource,
    onDelete = undefined,
    resource,
    submitOnClear = false,
    ...rest
}: EnhancedMultiAutoCompleteProps<T>) => {
    const record = useRecordContext();
    const [options, setOptions] = useState<T[]>(assignedOptions);
    const [values, setValues] = useState<T[]>([]);
    const [listLoading, setListLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);

    const notify = useNotify();
    const translate = useTranslate();

    const refreshList = async () => {
        setListLoading(true);
        try {
            if (assignedOptions.length === 0) {
                const res = await loadResource(record.id);
                setOptions(res.data);
            }
        } catch (error) {
            notify(
                `resources.${resource}.autocomplete.notification.getFailed`,
                {
                    type: 'info',
                },
            );
        }
        setListLoading(false);
    };

    useEffect(() => {
        refreshList();
        if (record) setValues(getDefaultValues(record) ?? []);
    }, []);

    const submit = (input: T[]) => {
        setActionLoading(true);
        const selectedIds: Identifier[] = [];
        input.forEach((value) => {
            selectedIds.push(value.id);
        });
        action(selectedIds, record.id)
            .then(() => {
                notify(`resources.${resource}.notification.saved`, {
                    type: 'info',
                });
                // if there is no initial/default values, reset values to [].
                if (getDefaultValues(record) === undefined) setValues([]);
                // TODO: only refresh list iff we start exclude certain values
                // refreshList();
            })
            .catch(() => {
                notify(
                    `resources.${resource}.autocomplete.notification.actionFailed`,
                    {
                        type: 'error',
                    },
                );
                if (record) setValues(getDefaultValues(record) ?? []);
            })
            .finally(() => {
                setActionLoading(false);
            });
    };

    const handleDelete = (
        event: React.ChangeEvent<EventTarget>,
        index: number,
    ) => {
        if (!onDelete) return;
        onDelete(event);
        setValues((prevValues) => {
            prevValues.splice(index, 1);
            submit([...prevValues]);
            return [...prevValues];
        });
    };

    return (
        <Grid container spacing={2} alignItems="center" direction="row">
            <Grid item sx={{ width: '80%' }}>
                <Autocomplete<T, true, true, false>
                    {...rest}
                    disableCloseOnSelect
                    multiple
                    options={options}
                    value={values}
                    disabled={actionLoading}
                    getOptionLabel={(option) => labelResource(option)}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {labelResource(option)}
                        </li>
                    )}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                                <Chip
                                    key={key}
                                    label={labelResource(option)}
                                    {...tagProps}
                                    onDelete={onDelete}
                                    deleteIcon={
                                        <Cancel
                                            onMouseDown={(event) => {
                                                event.stopPropagation();
                                                handleDelete(event, index);
                                            }}
                                        />
                                    }
                                />
                            );
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={translate(
                                `resources.${resource}.autocomplete.label`,
                            )}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {(actionLoading || listLoading) && (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        )}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                    onClose={() => {
                        if (!values || !values.length) return;
                        submit(values);
                    }}
                    onChange={(_event, values, reason) => {
                        setValues(values);
                        if (reason === 'clear' && submitOnClear) submit(values);
                    }}
                />
            </Grid>
            <Grid item>
                <IconButton
                    disabled={actionLoading || listLoading}
                    onClick={refreshList}
                >
                    <Refresh />
                </IconButton>
            </Grid>
        </Grid>
    );
};
