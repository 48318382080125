import React from 'react';
import { Datagrid, useRecordContext, InfiniteList } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { TaskPhoto } from './TaskPhoto';
import { TooltipChipField } from '../../components/TooltipChipField';
import { InfiniteListLoadMoreBtn } from '../../components/InfiniteListLoadMoreBtn';
import { TaskTitle } from './components/TaskTitle';
import { TaskNote } from './components/TaskNote';

export const OperationExpand = () => {
    const record = useRecordContext();

    return (
        <>
            <InfiniteList
                resource="task"
                filter={{ operation_fk: record.id }}
                sort={{ field: 'priority_number', order: 'ASC' }}
                actions={false}
                pagination={<InfiniteListLoadMoreBtn buttonType="Tasks" />}
                title={'default.no_title'}
            >
                <Datagrid
                    bulkActionButtons={false}
                    sx={{
                        '& .RaDatagrid-row': {
                            backgroundColor: 'white',
                            flexDirection: 'column',
                        },
                        '& .RaDatagrid-headerCell': {
                            display: 'none',
                        },
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}
                    >
                        <TaskTitle />
                        <TaskNote />
                        <TaskPhoto operationTitle={record.title} />
                    </Box>
                </Datagrid>
            </InfiniteList>
        </>
    );
};
