import React from 'react';
import { WithRecord } from 'react-admin';
import { InfoIcon } from '../../../components';
import { Box, Typography } from '@mui/material';

export const TaskTitle = () => (
    <>
        <WithRecord
            render={(record) => (
                <Box sx={{ display: 'flex', flexDirection: 'row', padding: 1 }}>
                    <Typography sx={{ fontWeight: '600' }}>Task: </Typography>
                    <Typography sx={{ fontWeight: '500', marginLeft: 1 }}>
                        {record.title}
                    </Typography>
                </Box>
            )}
        />
    </>
);
