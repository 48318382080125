import React from 'react';
import { ButtonProps, useRedirect, useTranslate } from 'react-admin';
import { Button } from '@mui/material';
import { HowToReg } from '@mui/icons-material';

export const RegisterButton: React.FC<ButtonProps> = () => {
    const redirect = useRedirect();

    const handleClick = async () => {
        redirect('/register');
    };

    const translate = useTranslate();

    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={handleClick}
            fullWidth={true}
            size="medium"
            startIcon={<HowToReg />}
        >
            {translate('pos.register.register')}
        </Button>
    );
};
