import { DataProviderResult, useDataProvider } from 'react-admin';
import { useQuery, UseQueryResult } from 'react-query';

export const useGetUserEvent = (
    total: boolean,
    params: GetUserEventParams,
): UseGetUserEventHookValue => {
    const dataProvider = useDataProvider();
    return useQuery(['getUserEvent', params], () =>
        dataProvider
            .getUserEvent(total, params)
            .then(({ data }: DataProviderResult) => data),
    );
};

export type UseGetUserEventHookValue = UseQueryResult<{
    total: number;
}>;

export interface GetUserEventParams {
    filter:
        | string
        | {
              start?: any;
              end?: any;
              user_fk?: any;
              pm_fk?: any;
              site_fk?: any;
              project_fk?: any;
          };
}
