import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import { ProjectList } from './ProjectList';
import { ProjectEdit } from './ProjectEdit';
import { ProjectShow } from './ProjectShow/index';
import { NotFound } from 'react-admin';

export default {
    create: NotFound,
    edit: ProjectEdit,
    icon: BusinessCenterIcon,
    list: ProjectList,
    planningIcon: NextPlanIcon,
    show: ProjectShow,
};
