import React, { useState } from 'react';
import {
    ChatContainer,
    MessageInput,
    ConversationHeader,
} from '@chatscope/chat-ui-kit-react';
import { Client, Conversation } from '@twilio/conversations';
import { useNavigate } from 'react-router-dom';
import { Loading, useNotify } from 'react-admin';

import { LocalConversation, useTwilio } from '../../contexts/TwilioProvider';
import { useDataProvider } from 'react-admin';
import * as Sentry from '@sentry/browser';

export const TempChatBox: React.FC = () => {
    const { client, setLocalConversation, localConversation } = useTwilio() as {
        client: Client;
        setLocalConversation: React.Dispatch<LocalConversation | null>;
        localConversation: LocalConversation;
    };
    const { createChatRoom } = useDataProvider();
    const navigate = useNavigate();
    const notify = useNotify();
    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState('');

    const characterLimit = 320;
    const handleInputChange = (text: string) => {
        if (text.length <= characterLimit) setValue(text);
        else notify('Character limit of 320 reached', { type: 'warning' });
    };

    const handleSend = async () => {
        // The text input is a contenteditable div, so we need to get the text content directly, otherwise
        // we would get the HTML content
        const textbox = document.getElementsByClassName(
            'cs-message-input__content-editor',
        );
        const text = textbox[0].textContent;
        setValue('');
        if (!text?.trim()) return;
        setLoading(true);

        try {
            let conversation = {} as Conversation;
            if (localConversation.type === 'user') {
                conversation = await client.createConversation({
                    uniqueName: localConversation.uniqueName,
                    friendlyName: localConversation.friendlyName,
                    attributes: {
                        type: localConversation.type,
                    },
                });
                if (localConversation.selectedPeople) {
                    for (const email of localConversation.selectedPeople) {
                        await conversation.add(email);
                    }
                }
            } else {
                const conversationResponse = await createChatRoom({
                    type: localConversation.type,
                    typeId: localConversation.typeId,
                });
                conversation = await client.getConversationBySid(
                    conversationResponse.data,
                );
            }

            setTimeout(async () => {
                await conversation.sendMessage(text);
            }, 150);
            setLocalConversation(null);
            navigate(`/chat/${conversation.sid}`);
        } catch (err) {
            notify(`Error creating new conversation`, { type: 'error' });
            Sentry.captureException(err);
        }
    };

    if (loading) return <Loading />;

    return (
        <ChatContainer>
            <ConversationHeader>
                <ConversationHeader.Content
                    userName={
                        localConversation.friendlyName ||
                        localConversation?.otherPeople
                    }
                />
            </ConversationHeader>

            <MessageInput
                placeholder="Send the first message to create the chat"
                onSend={handleSend}
                value={value}
                onChange={handleInputChange}
                onPaste={(evt: React.ClipboardEvent<HTMLInputElement>) => {
                    evt.preventDefault();
                    setValue(evt.clipboardData.getData('text')); // get text representation of clipboard
                }}
            />
        </ChatContainer>
    );
};
