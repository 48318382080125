import { AuthProvider } from 'react-admin';
import jwt_decode from 'jwt-decode';
import { serverURL } from './contexts/SocketProvider';
import axios from 'axios';
import { isBefore, isEqual, fromUnixTime } from 'date-fns';
import { httpClient } from './dataProvider/client';
import * as Sentry from '@sentry/react';
import { deleteTokenFromFirebase } from './chats/fcmPushNotifications';

interface Token {
    exp?: string;
    iat?: string;
    sub?: string;
    permissions?: Array<string>;
}

interface GetVideoTokenParams {
    session_name: string;
    role_type: string;
}

export const checkPermission = (permission: string) => {
    // const { permissions } = usePermissions()
    return true;
    // return permissions ? permissions.find((el: string) => { el == permission}) : false
};

const checkTokenExpiry = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;
    const parsed_token: Token = jwt_decode(token);
    const { exp } = parsed_token;
    if (!exp) return false;
    const exp_time = fromUnixTime(parseInt(exp));
    const now_time = new Date();
    if (isBefore(exp_time, now_time) || isEqual(exp_time, now_time)) {
        return false;
    }
    return true;
};

const removeToken = (expired = false) => {
    if (expired) {
        alert('Your session has expired. Please log in again.');
    }
    localStorage.removeItem('token');
};

/*
 * Based on https://marmelab.com/react-admin/AuthProviderWriting.html
 */
export const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        return httpClient(`${serverURL}/login_web`, {
            url: serverURL + '/login_web',
            method: 'POST',
            data: { username, password },
        })
            .then(({ body }) => body)
            .then(({ token }) => {
                localStorage.setItem('token', token);

                window.dispatchEvent(
                    new CustomEvent('tokenChanged', { detail: token }),
                );

                return Promise.resolve({ redirectTo: '/user' });
            })
            .catch(({ body }) => Promise.reject(body?.message ?? ''));
    },
    logout: () =>
        httpClient(`${serverURL}/logout`, {
            method: 'POST',
        }).then(() => {
            Sentry.setUser(null);
            removeToken();
            deleteTokenFromFirebase();
        }),
    // when the dataProvider returns an error, check if this is an authentication error
    checkError: (error) => {
        if (error?.status === 401 || error?.status === 403) {
            return Promise.reject();
        }
        console.log('error?', error);
        return Promise.resolve();
    },
    // when the user navigates, make sure that their credentials are still valid
    checkAuth: () => {
        const token = localStorage.getItem('token');
        if (!token) {
            return Promise.reject();
        }
        const isAuthorized = checkTokenExpiry();
        if (!isAuthorized) {
            removeToken(true);
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        const token = localStorage.getItem('token');
        if (token === null)
            return Promise.reject({ message: 'No Token', status: 401 });
        const { permissions } = jwt_decode<Token>(token);
        return permissions
            ? Promise.resolve(permissions)
            : Promise.reject({ message: 'No Permissions', status: 401 });
    },
    getTwilioToken: async () => {
        return await httpClient(`${serverURL}/chat/token`, {
            method: 'GET',
        })
            .then(({ body }) => body)
            .catch(({ body }) => Promise.reject(body?.message ?? ''));
    },
    getVideoToken: async (params: GetVideoTokenParams) => {
        return await httpClient(`${serverURL}/chat/video-token`, {
            method: 'GET',
            params: params,
        })
            .then(({ body }) => body)
            .catch(({ body }) => Promise.reject(body?.message ?? ''));
    },
    // For future use of register endpoint:

    // register: (data: any) => {
    //     const request = new Request(api_url + '/register', {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: new Headers({
    //             'Content-Type': 'application/json',
    //         }),
    //     });
    //     return fetch(request)
    //         .then((response) => {
    //             console.log('Started Response', response);
    //             if (response.status < 200 || response.status >= 300) {
    //                 throw new Error(response.statusText);
    //             }
    //             return response.json();
    //         })
    //         .then(({ token }) => {
    //             localStorage.setItem('token', token);
    //         })
    //         .catch((e) => {
    //             throw new Error(e);
    //         });
    // },

    getIdentity: async () => {
        const token = localStorage.getItem('token');
        if (!token) return Promise.reject();
        return await axios({
            url: serverURL + '/simpleprofile',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(({ data }) => {
                // remove user chat as there is too much data
                Sentry.setUser({
                    id: data.id.toString(),
                    username: data.display_name,
                    email: data.email,
                });

                const { chat, ...rest } = data;
                return rest;
            })
            .catch(() => {
                throw new Error('Network error');
            });
    },
};
