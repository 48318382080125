import {
    CreateParams,
    DataProvider,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from 'react-admin';
import { stringify } from 'query-string';
import { apiUrl, httpClient } from './client';
import {
    ContentRange,
    contentRangeHeaderParser,
    convertFileToBase64,
    sanitizeListQueryParams,
} from './helpers';
// const hubEndPoints = ['device', 'flux'];

export const RequiredMethods: DataProvider = {
    getList: (resource: string, params: GetListParams) => {
        return httpClient(`${apiUrl}/${resource}`, {
            ...sanitizeListQueryParams(params),
        }).then(({ headers, json }) => {
            const range: ContentRange | null = contentRangeHeaderParser(
                headers['content-range'],
            );

            let temp_data = json.data ? json.data : json;
            if (typeof temp_data === 'string') {
                temp_data = JSON.parse(temp_data);
            }

            return {
                data: temp_data,
                total: !range ? 0 : range.size,
            };
        });
    },

    getOne: (resource: string, params: GetOneParams) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
            ({ json }) => ({
                data: json,
            }),
        );
    },

    getMany: (resource: string, params: GetManyParams) => {
        return httpClient(`${apiUrl}/${resource}`, {
            params: { filter: JSON.stringify({ id__in: params.ids }) },
        }).then(({ json }) => ({ data: json }));
    },

    getManyReference: async (
        resource: string,
        params: GetManyReferenceParams,
    ) => {
        // TODO: refactor this into the sanitize function
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
            sort: JSON.stringify([field, order]),
            page: JSON.stringify(page),
            perPage: JSON.stringify(perPage),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        };
        return httpClient(`${apiUrl}/${resource}`, { params: query }).then(
            ({ headers, json }) => {
                let total = 0;

                if (headers['content-range']) {
                    total = parseInt(
                        (headers['content-range'] ?? '').split('/').pop(),
                        10,
                    );
                } else {
                    total = 0;
                }

                return {
                    data: json,
                    total: total,
                };
            },
        );
    },

    create: (resource: string, params: CreateParams) => {
        // TODO: create type for params, should only get
        const { data, meta } = params;
        let copyTarget = '';
        let options = {};
        if (meta && 'id' in meta) {
            copyTarget = `/${meta.id}`;
            options = { params: { ...data } };
        } else {
            options = { data };
        }

        return httpClient(`${apiUrl}/${resource}${copyTarget}`, {
            method: 'POST',
            ...options,
        }).then(({ json }) => ({
            data: { ...json },
        }));
    },

    update: (resource: string, params: UpdateParams) => {
        //only if there is a property "files" and files are not empty, the PUT request cannot be sent directly
        if (
            !params.data ||
            Object.prototype.hasOwnProperty.call(params.data, 'files') ||
            !params.data.files
        ) {
            // fallback to the default implementation
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                data: params.data,
            }).then(({ json }) => ({ data: json }));
        }

        let newFileUpload = [];
        // Freshly dropped pictures are File objects and must be converted to base64 strings
        if (typeof params.data.files === 'object') {
            newFileUpload = [params.data.files];
        } else {
            newFileUpload = params.data.files;
        }

        //const formerPictures = params.data.pictures.filter(
        //    (p: any) => !(p.rawFile instanceof File)
        //);

        return Promise.all(newFileUpload.map(convertFileToBase64))
            .then((base64Files) =>
                base64Files.map((file64) => ({
                    src: file64.reader,
                    title: file64.title,
                })),
            )
            .then((transformedNewFiles) => {
                const url = `${apiUrl}/upload`;

                const body = {
                    data: {
                        files: [...transformedNewFiles],
                    },
                };
                return httpClient(url, {
                    method: 'POST',
                    data: body,
                }).then(({ json }) => ({ data: json }));
            });
    },

    updateMany: (resource: string, params: UpdateManyParams) => {
        const sanitizedParams = {};

        if ('ids' in params) {
            Object.assign(sanitizedParams, {
                ids: JSON.stringify(params.ids),
            });
        }

        return httpClient(`${apiUrl}/${resource}`, {
            method: 'PUT',
            data: params?.data ?? {},
            params: { ...sanitizedParams },
        }).then(({ json }) => ({ data: json }));
    },

    delete: (resource: string, params: DeleteParams) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource: string, params: DeleteManyParams) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            data: params.ids,
        }).then(({ json }) => ({ data: json }));
    },
};
