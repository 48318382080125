import React from 'react';
import { WithRecord } from 'react-admin';
import { InfoIcon } from '../../../components';

export const ProjectTitle = () => (
    <>
        <WithRecord
            render={(record) => (
                <span>
                    Project - {record.title}{' '}
                    {record?.project_code ? `(${record.project_code})` : ''}
                    <InfoIcon resource={'project'} record={record} />
                </span>
            )}
        />
    </>
);
