import React, { FunctionComponent, ReactElement } from 'react';
import { Edit, EditProps, useNotify } from 'react-admin';
import { useNavigate } from 'react-router';

interface SublistEditProps extends EditProps {
    children: ReactElement;
}

export const SublistEdit: FunctionComponent<SublistEditProps> = ({
    resource,
    title,
    children,
    ...rest
}) => {
    const notify = useNotify();

    const onSuccess = async () => {
        notify(`resources.${resource}.notification.saved`, {
            type: 'info',
            messageArgs: { resources: { resource } },
            undoable: true,
        });
    };

    return !children ? (
        <></>
    ) : (
        <Edit {...rest} mutationOptions={{ onSuccess }} title={title}>
            {children}
        </Edit>
    );
};
