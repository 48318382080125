import React, { useState, useEffect } from 'react';
import {
    Identifier,
    useDataProvider,
    useDelete,
    useRecordContext,
    useTranslate,
    useUpdateMany,
    useListContext,
} from 'react-admin';
import { Typography, Grid } from '@mui/material';
import { Site, Project, User } from '../../types';
import { EnhancedMultiAutoComplete } from '..';
import { AssignedTechList } from './AssignedTechList';

type AssignTechniciansProps = {
    resource: 'site' | 'project';
};

export const AssignTechnicians: React.FC<AssignTechniciansProps> = ({
    resource,
}) => {
    type ContextType = typeof resource extends 'site' ? Site : Project;

    const record = useRecordContext<ContextType>();

    const { data } = useListContext();

    const [assigned, setAssigned] = useState(
        resource === 'site' ? data : record.users,
    );

    useEffect(() => {
        if (data) setAssigned(data);
    }, [data]);

    const dataProvider = useDataProvider();

    const loadAssignableTechs = async () => {
        return dataProvider.customGetAll(
            `user/assignables/techs/${resource}/${record.id}`,
        );
    };

    const [updateMany] = useUpdateMany();

    const assignTechs = async (
        arrayIds: Identifier[], // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<void | any[]> => {
        return updateMany(
            `${resource}/${record.id}/assign`,
            {
                ids: arrayIds,
            },
            {
                onError: (error) => Promise.reject(error),
                onSettled: (data, error) => {
                    if (error || !data) return Promise.reject(error);
                    setAssigned([...data]);
                    return Promise.resolve(data);
                },
            },
        );
    };

    const [deleteOne] = useDelete();

    const unassignTechs = async (userId: Identifier) => {
        return await deleteOne(
            `${resource}/${record.id}/assign`,
            {
                id: userId,
            },
            {
                onSettled: (data) => {
                    setAssigned([...data]);
                },
            },
        );
    };

    const translate = useTranslate();

    return (
        <Grid
            item
            sx={{
                border: `2px solid lightgrey`,
                borderRadius: `12px`,
                padding: `20.8px 20.8px 2px`,
                marginBottom: '40px',
            }}
        >
            <Typography variant="subtitle2" color="grey">
                {translate(`resources.${resource}.fields.technicians`)}
            </Typography>
            <EnhancedMultiAutoComplete<User>
                sx={{
                    py: '10px',
                }}
                action={assignTechs}
                labelResource={(t) =>
                    `${t.first_name} ${t.last_name}, ${t.email}`
                }
                loadResource={loadAssignableTechs}
                resource="user"
            />
            <AssignedTechList
                resource={resource}
                assigned={assigned}
                handleDelete={unassignTechs}
            />
        </Grid>
    );
};
