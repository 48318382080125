import React, { useState } from 'react';
import {
    Datagrid,
    FunctionField,
    RaRecord,
    RecordContextProvider,
    ReferenceManyField,
    ShowBase,
    TextField,
    Title,
    useGetIdentity,
    useListContext,
    useRecordContext,
    WithRecord,
    useTranslate,
} from 'react-admin';
import {
    Grid,
    Button,
    ButtonGroup,
    Typography,
    Stack,
    Tooltip,
} from '@mui/material';
import {
    format,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subDays,
    subMonths,
    subWeeks,
} from 'date-fns';

import {
    TimeCard,
    EnhancedListView,
    EnhancedPagination,
    StatusIcon,
} from '../components';
import { getHoursAndMinutes } from '../helpers';
import { useGetUserEvent } from '../hooks/useGetUserEvent';
import { Site, User } from '../types';
import { TimeZoneDateField } from '../components/TimeZoneDateField';

const timeSpans = {
    currentWeek: 'week',
    currentMonth: 'month',
    lastWeek: 'lastWeek',
    lastTwoWeeks: 'lastTwoWeeks',
    lastMonth: 'lastMonth',
};

export const Dashboard = () => {
    const { data, isLoading } = useGetIdentity();
    if (isLoading || !data?.id) return null;

    return (
        !isLoading && (
            <ShowBase resource="user" id={data.id}>
                <>
                    <Title title="pos.dashboard.name" />
                    <WithRecord
                        render={(record: User) => {
                            return (
                                <Grid
                                    container
                                    direction="column"
                                    spacing={2}
                                    sx={{ marginY: '1em' }}
                                >
                                    <Grid item>
                                        <DashboardProjectList />
                                    </Grid>
                                </Grid>
                            );
                        }}
                    />
                </>
            </ShowBase>
        )
    );
};

const SiteGrid: React.FunctionComponent = () => {
    const { data, isLoading } = useListContext();
    const translate = useTranslate();
    if (isLoading) return null;
    if (!data)
        return (
            <Typography>{translate('resources.site.list.failed')}</Typography>
        );

    const getDateString = (site: Site) => {
        return `${
            site.scheduled_start
                ? format(new Date(site.scheduled_start), 'yyyy/MM/dd')
                : ''
        } - ${
            site.scheduled_end
                ? format(new Date(site.scheduled_end), 'yyyy/MM/dd')
                : ''
        }`;
    };

    return (
        <>
            <Typography
                variant="subtitle2"
                color="grey"
                sx={{ marginBottom: '5px' }}
            >
                Sites
            </Typography>
            <Grid container spacing={2} sx={{ marginBottom: '1em' }}>
                {data.map((site: Site) => (
                    <Grid item key={`site-${site.id}`}>
                        <RecordContextProvider value={site}>
                            <TimeCard resource="site">
                                <Typography variant="h5">
                                    {site.title}
                                </Typography>
                                <Typography variant="overline">
                                    {getDateString(site)}
                                </Typography>
                            </TimeCard>
                        </RecordContextProvider>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
const DashboardProjectList: React.FC = () => {
    const [timeSpan, setTimespan] = useState(timeSpans.currentWeek);
    const translate = useTranslate();

    const currentDate = startOfDay(new Date());

    const weekStart = startOfWeek(currentDate, { weekStartsOn: 0 });
    const monthStart = startOfMonth(currentDate);
    const lastWeekStart = subWeeks(currentDate, 1);
    const lastTwoWeekStart = subWeeks(currentDate, 2);
    const lastMonthStart = subDays(currentDate, 30);

    const getStartDate = () => {
        if (timeSpan === timeSpans.lastWeek) return lastWeekStart;
        else if (timeSpan === timeSpans.lastTwoWeeks) return lastTwoWeekStart;
        else if (timeSpan === timeSpans.lastMonth) return lastMonthStart;
        else if (timeSpan === timeSpans.currentMonth) return monthStart;
        else return weekStart;
    };

    const getEndDate = () => {
        const currentDate = startOfDay(new Date());
        return currentDate;
    };

    const projectFilters = {
        start_date: format(getStartDate(), 'yyyy-MM-dd'),
        end_date: format(getEndDate(), 'yyyy-MM-dd'),
    };

    const TotalTimeTitle = () => translate(`reporting.total_time.${timeSpan}`);
    const timeSpanButtons = [
        {
            timeSpan: timeSpans.currentWeek,
            label: `reporting.time_span.${timeSpans.currentWeek}`,
            title: `${format(weekStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
        {
            timeSpan: timeSpans.currentMonth,
            label: `reporting.time_span.${timeSpans.currentMonth}`,
            title: `${format(monthStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
        {
            timeSpan: timeSpans.lastWeek,
            label: `reporting.time_span.${timeSpans.lastWeek}`,
            title: `${format(lastWeekStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
        {
            timeSpan: timeSpans.lastTwoWeeks,
            label: `reporting.time_span.${timeSpans.lastTwoWeeks}`,
            title: `${format(lastTwoWeekStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
        {
            timeSpan: timeSpans.lastMonth,
            label: `reporting.time_span.${timeSpans.lastMonth}`,
            title: `${format(lastMonthStart, 'E MMM dd yyyy')} - ${format(
                currentDate,
                'E MMM dd yyyy',
            )}`,
        },
    ];

    const buttonGroups = [
        { timeSpans: timeSpanButtons.slice(0, 2) },
        { timeSpans: timeSpanButtons.slice(2) },
    ];

    const showContained = (componentTimeSpan: string, stateTimeSpan: string) =>
        componentTimeSpan === stateTimeSpan ? 'contained' : 'outlined';

    return (
        <>
            <Stack direction="row" sx={{ m: 2 }} spacing={4}>
                {buttonGroups.map((group, index) => (
                    <ButtonGroup color={'primary'} key={index}>
                        {group.timeSpans.map((btn, btnIndex) => (
                            <Tooltip
                                title={btn.title}
                                placement="top"
                                key={btnIndex}
                            >
                                <Button
                                    variant={showContained(
                                        btn.timeSpan,
                                        timeSpan,
                                    )}
                                    onClick={() => setTimespan(btn.timeSpan)}
                                >
                                    {translate(btn.label)}
                                </Button>
                            </Tooltip>
                        ))}
                    </ButtonGroup>
                ))}
            </Stack>
            <Grid
                container
                direction="column"
                spacing={2}
                sx={{ marginY: '1em' }}
            >
                <Grid item>
                    <TimeCard
                        resource="pm"
                        dateFilters={`"start":"${projectFilters.start_date}","end":"${projectFilters.end_date}"`}
                        sx={{ width: 'fit-content' }}
                    >
                        <Typography variant="h5">{TotalTimeTitle()}</Typography>
                    </TimeCard>
                </Grid>
            </Grid>

            <ReferenceManyField
                label="Projects"
                reference="project"
                target="pm_fk"
                filter={projectFilters}
            >
                <EnhancedListView
                    resource="project"
                    actions={false}
                    pagination={<EnhancedPagination />}
                    title={'default.no_title'}
                >
                    <Datagrid
                        rowClick="show"
                        bulkActionButtons={false}
                        expand={
                            <ReferenceManyField
                                label="Sites"
                                reference="site"
                                target="project_fk"
                            >
                                <SiteGrid />
                            </ReferenceManyField>
                        }
                    >
                        <StatusIcon resource="project" />
                        <TextField source="status" />
                        <TextField source="title" />
                        <TextField source="project_code" />
                        <TimeZoneDateField source="current_start" />
                        <TimeZoneDateField source="current_end" />
                        <TimeZoneDateField source="scheduled_start" />
                        <FunctionField
                            label="Total Technician Time Spent"
                            render={(record: RaRecord) => <TotalProjectTime />}
                        />
                    </Datagrid>
                </EnhancedListView>
            </ReferenceManyField>
        </>
    );
};

const TotalProjectTime: React.FunctionComponent = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    const { data, isLoading } = useGetUserEvent(true, {
        filter: { project_fk: record.id },
    });
    if (isLoading) return <Typography>loading...</Typography>;
    if (data?.total === null || data?.total === undefined)
        return (
            <Typography>{translate('reporting.total.fetchFail')}</Typography>
        );

    return (
        <Typography variant="body2">
            {getHoursAndMinutes(data.total)}
        </Typography>
    );
};
