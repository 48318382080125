import { useRecordContext, useRedirect } from 'react-admin';
import { LocalConversation, useTwilio } from '../contexts/TwilioProvider';
import { Conversation } from '@twilio/conversations';
import { Project, Site } from '../types';

const useCreateTempGroupChat = () => {
    const record = useRecordContext<Project | Site>();

    const redirect = useRedirect();
    const { setLocalConversation, conversations } = useTwilio() as {
        setLocalConversation: React.Dispatch<LocalConversation>;
        conversations: Conversation[];
    };
    return (type: string) => {
        const uniqueName = `${type}_${record.id}`;

        for (const conversation of conversations)
            if (conversation.uniqueName == uniqueName) {
                redirect(`/chat/${conversation.sid}`);
                return;
            }

        setLocalConversation({
            uniqueName: uniqueName,
            friendlyName: record.title,
            type: type,
            typeId: record.id,
        });

        redirect(`/chat`);
    };
};

export default useCreateTempGroupChat;
