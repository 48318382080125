import React, { useEffect, useState } from 'react';
import {
    Button,
    Link,
    SimpleForm,
    TextInput,
    TopToolbar,
    useRecordContext,
} from 'react-admin';
import {
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    ClearButtons,
    ColorButtons,
} from 'ra-input-rich-text';
import { Evaluation, ProjectInfo } from '../types';
import { DefaultEditToolbar, SublistEdit, InfoIcon } from '../components';
import { Grid, Box } from '@mui/material';
import dataProvider from '../dataProvider';
import { BusinessCenter, Summarize } from '@mui/icons-material';

const SiteEvaluationTitle: React.FC = () => {
    const record = useRecordContext<Evaluation>();
    const [projectCode, setProjectCode] = useState<ProjectInfo>(
        {} as ProjectInfo,
    );
    useEffect(() => {
        if (record?.site?.project_fk)
            dataProvider('projectinfo')
                .getOne('projectinfo', { id: record?.site?.project_fk })
                .then((data) => setProjectCode(data.data));
    }, [record?.site?.project_fk, setProjectCode]);

    return record ? (
        <Box component="span">
            <span>Evaluation - {record.site_info}</span>
            <span>
                {' '}
                ({projectCode?.project_code && `${projectCode?.project_code}`})
            </span>
            <InfoIcon resource={'evaluation'} record={record} />
        </Box>
    ) : null;
};

const Actions = () => {
    const record = useRecordContext<Evaluation>();
    if (!record) return null;

    return (
        <TopToolbar>
            <Link to={`/closeout/?id=${record.id}`} target="_blank">
                <Button
                    label={'resources.evaluation.actions.print_report'}
                    startIcon={<Summarize />}
                />
            </Link>
            <Link to={`/project/${record?.site?.project_fk}`} target="_blank">
                <Button
                    label={'resources.evaluation.actions.project_link'}
                    startIcon={<BusinessCenter />}
                />
            </Link>
        </TopToolbar>
    );
};

type CustomRichTextInputProps = {
    source: string;
    label: string;
};

const CustomRichTextInput = (
    { source }: CustomRichTextInputProps,
    { label }: CustomRichTextInputProps,
) => {
    return (
        /* disable typecheck for RichTextInput defaultValue
        eslint-disable-next-line @typescript-eslint/ban-ts-comment
        */
        <RichTextInput
            source={source}
            fullWidth
            label={label}
            toolbar={
                <RichTextInputToolbar>
                    <LevelSelect />
                    <FormatButtons />
                    <ColorButtons />
                    <AlignmentButtons />
                    <ListButtons />
                    <ClearButtons />
                </RichTextInputToolbar>
            }
        />
    );
};

export const SiteEvaluationEdit: React.FC = () => {
    return (
        <>
            {
                <SublistEdit
                    resource="evaluation"
                    title={<SiteEvaluationTitle />}
                    component="div"
                    actions={<Actions />}
                >
                    <div>
                        <Grid container direction="column">
                            <Grid
                                item
                                sx={{
                                    padding: '1.3em',
                                    background: 'white',
                                    border: '2px solid lightgrey',
                                    borderRadius: '16px',
                                }}
                            >
                                <SimpleForm
                                    toolbar={
                                        <DefaultEditToolbar resource="evaluation" />
                                    }
                                    sx={{
                                        paddingX: 0,
                                        '.ProseMirror': {
                                            minHeight: '122px',
                                        },
                                        '[class^="ra-input"]': {
                                            alignItems: 'flex-start',
                                        }, // apply css to all classes of prefix
                                    }}
                                >
                                    <TextInput
                                        label="Site"
                                        source="site_info"
                                        disabled
                                        sx={{ width: '100%' }}
                                    />
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <TextInput
                                                source="purchase_order"
                                                label="resources.evaluation.fields.purchase_order"
                                                sx={{ width: '100%' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <TextInput
                                                source="field_name"
                                                label="resources.evaluation.fields.field_name"
                                                sx={{ width: '100%' }}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextInput
                                                source="field_value"
                                                label="resources.evaluation.fields.field_value"
                                                sx={{ width: '34%' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <CustomRichTextInput
                                        source="outstanding"
                                        label="resources.evaluation.fields.outstanding"
                                    />
                                    <CustomRichTextInput
                                        source="baseline"
                                        label="resources.evaluation.fields.baseline"
                                    />
                                    <CustomRichTextInput
                                        source="end_result"
                                        label="resources.evaluation.fields.end_result"
                                    />
                                    <CustomRichTextInput
                                        source="assesment"
                                        label="resources.evaluation.fields.assesment"
                                    />
                                </SimpleForm>
                            </Grid>
                        </Grid>
                    </div>
                </SublistEdit>
            }
        </>
    );
};
