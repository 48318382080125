import React from 'react';
import {
    BooleanField,
    BooleanInput,
    DeleteButton,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    useNotify,
    useRecordContext,
    useRefresh,
    useTranslate,
} from 'react-admin';
import { OperationTemplate } from '../types';
import {
    EnhancedListView,
    SublistTopToolbar,
    InfoIcon,
    DefaultEditToolbar,
    SublistEdit,
} from '../components';
import { Grid } from '@mui/material';
import { DraggableDatagrid } from '../components/DraggableDatagrid';

function OperationTemplateInfo() {
    return (
        <Grid
            item
            sx={{
                padding: '1.3em',
                background: 'white',
                border: '2px solid lightgrey',
                borderRadius: '12px',
                paddingBottom: '0',
                marginBottom: '40px',
            }}
        >
            <SimpleForm
                toolbar={<DefaultEditToolbar resource="template_operation" />}
            >
                <Grid container spacing={2} sx={{ maxWidth: '80vw' }}>
                    <Grid item sm={11}>
                        <TextInput
                            autoFocus
                            fullWidth
                            source="title"
                            label="resources.template_operation.fields.title"
                            validate={required()}
                        />
                    </Grid>
                    <Grid item sm={11}>
                        <TextInput
                            fullWidth
                            source="description"
                            label="resources.template_operation.fields.description"
                        />
                    </Grid>
                </Grid>
                <BooleanInput source="is_active" />
            </SimpleForm>
        </Grid>
    );
}

export const OperationTemplateEdit: React.FC = () => {
    return (
        <SublistEdit
            resource="template_operations"
            title={<OperationTemplateTitle />}
            component="div"
            actions={false}
        >
            <Grid container direction="column">
                <OperationTemplateInfo />
                <OperationTasks />
            </Grid>
        </SublistEdit>
    );
};

function OperationTemplateTitle() {
    const record = useRecordContext<OperationTemplate>();
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.template_operation.name')} - {record.title}
            <InfoIcon resource={'operation_template'} record={record} />
        </span>
    ) : null;
}

function OperationTasks() {
    const notify = useNotify();
    const refresh = useRefresh();

    return (
        <Grid
            item
            sx={{
                position: 'relative',
                marginY: '1em',
                padding: '1.3em',
                background: 'white',
                border: '2px solid lightgrey',
                borderRadius: '16px',
            }}
        >
            <SimpleShowLayout>
                <ReferenceManyField
                    label="resources.template_task.fields.sublist"
                    reference="template_task"
                    target="tpl_op_fk"
                    sort={{
                        field: 'priority_number',
                        order: 'ASC',
                    }}
                >
                    <EnhancedListView
                        resource="template_task"
                        actions={
                            <SublistTopToolbar
                                resource="template_task"
                                label="Create Task Template +"
                            />
                        }
                    >
                        <DraggableDatagrid>
                            <TextField
                                source="priority_number"
                                sortable={false}
                            />
                            <TextField source="title" sortable={false} />
                            <TextField source="description" sortable={false} />
                            <BooleanField
                                source="note_required"
                                sortable={false}
                            />
                            <BooleanField
                                source="photo_required"
                                sortable={false}
                            />
                            <NumberField source="photo_num" sortable={false} />
                            <DeleteButton
                                mutationOptions={{
                                    onSuccess: () => {
                                        refresh();
                                        notify(
                                            `resources.template_task.notification.deleted`,
                                            {
                                                undoable: true,
                                            },
                                        );
                                    },
                                }}
                            />
                        </DraggableDatagrid>
                    </EnhancedListView>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Grid>
    );
}
