import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import {
    DateTimeInput,
    DateTimeInputProps,
    useTranslate,
    AutocompleteInput,
    useRecordContext,
    useSaveContext,
    required,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { Project, Site } from '../../types';
import { formatTime } from '../../dataProvider';

export type DateTimeGridProps = {
    sources: DateTimeGridSource;
} & Omit<DateTimeInputProps, 'source'>;

type DateTimeGridComponentProps = {
    conversionTZ: string | null;
} & DateTimeGridProps;

interface DateTimeGridSource {
    start: string;
    end: string;
    timezone: string;
}

type TimezoneSelect = {
    id: string;
    group: string;
    name: string;
};

const DateTimeGridComponent: React.FC<DateTimeGridComponentProps> = ({
    sources,
    conversionTZ,
    ...rest
}) => {
    const translate = useTranslate();

    const startDateInput = useWatch({ name: sources.start });
    const endDateInput = useWatch({ name: sources.end });

    const handleFormat = (time: Date | null | string): string =>
        formatTime(time, conversionTZ);

    const defaultRegions: TimezoneSelect[] = [
        {
            id: 'America/St_Johns',
            name: 'Newfoundland Time - Canada/US',
            group: 'Canada/US',
        },
        {
            id: 'America/Halifax',
            name: 'Atlantic Time - Canada/US',
            group: 'Canada/US',
        },
        {
            id: 'America/Montreal',
            name: 'Eastern Time - Canada/US',
            group: 'Canada/US',
        },
        {
            id: 'America/Winnipeg',
            name: 'Central Time - Canada/US',
            group: 'Canada/US',
        },
        {
            id: 'America/Edmonton',
            name: 'Mountain Time - Canada/US',
            group: 'Canada/US',
        },
        {
            id: 'America/Vancouver',
            name: 'Pacific Time - Canada/US',
            group: 'Canada/US',
        },
        {
            id: 'America/Anchorage',
            name: 'Alaska Time - Canada/US',
            group: 'Canada/US',
        },
        {
            id: 'Pacific/Honolulu',
            name: 'Hawaiian Time - Canada/US',
            group: 'Canada/US',
        },
        { id: 'UTC', name: 'Coordinated Universal Time', group: 'Time Zones' },
    ];

    const [showAllTZ, setShowAllTZ] = useState(false);
    const [timeRegions, setTimeRegions] = useState(defaultRegions);

    const toggleAllTZ = (): void => {
        if (showAllTZ) {
            setTimeRegions(defaultRegions);
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const allTimeRegions: TimezoneSelect[] = Intl.supportedValuesOf(
                'timeZone',
            ).reduce(
                (all: TimezoneSelect[], tz: string): TimezoneSelect[] => [
                    ...all,
                    {
                        id: tz,
                        name: tz.replaceAll('_', ' '),
                        group: 'Time Zones',
                    },
                ],
                [...defaultRegions],
            );
            setTimeRegions(allTimeRegions);
        }
        setShowAllTZ((s) => !s);
    };

    const validateStart = () => {
        const startInput = startDateInput
            ? formatTime(startDateInput, conversionTZ)
            : null;
        const endInput = endDateInput
            ? formatTime(endDateInput, conversionTZ)
            : null;

        if (!startInput || !endInput) {
            return undefined;
        }

        if (Date.parse(endInput) - Date.parse(startInput) < 0) {
            const error_message = translate(
                'resources.project.errors.scheduled_start',
            );
            return error_message;
        }
        return undefined;
    };

    return (
        <Grid container>
            <Grid item sx={{ marginRight: 2 }}>
                <DateTimeInput
                    {...rest}
                    source={sources.start}
                    format={handleFormat}
                    validate={validateStart}
                    sx={{ m: 0 }}
                />
            </Grid>
            <Grid item sx={{ marginRight: 2 }}>
                <DateTimeInput
                    {...rest}
                    source={sources.end}
                    format={handleFormat}
                    sx={{ m: 0 }}
                />
            </Grid>
            <Grid item xs={3} sx={{ marginRight: 2 }}>
                <AutocompleteInput
                    source={sources.timezone}
                    sx={{ m: 0 }}
                    size="small"
                    choices={timeRegions}
                    groupBy={(option) => option.group}
                    validate={required()}
                />
            </Grid>
            <Grid item>
                <Button onClick={toggleAllTZ} variant="contained">
                    {`${showAllTZ ? 'Hide' : 'Show'} All Time Zones`}
                </Button>
            </Grid>
        </Grid>
    );
};

export const DateTimeGrid: React.FC<DateTimeGridProps> = ({
    sources,
    ...rest
}) => {
    const record = useRecordContext<Project | Site>();

    if (!record) {
        return <></>;
    }

    const [conversionTZ, setConversionTZ] = useState<string | null>(null);
    const { saving } = useSaveContext();

    // Only set time_region once form has finished saving
    useEffect(() => {
        if (!saving) {
            setConversionTZ(record.time_region);
        }
    }, [saving]);

    return (
        <DateTimeGridComponent
            sources={sources}
            conversionTZ={conversionTZ}
            {...rest}
        />
    );
};
