import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

import { useGetOne, useAuthenticated, useTranslate } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import dataProvider from '../dataProvider';
import { Project } from '../types';
import './CloseOut.css';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';

export const CloseOutReport = () => {
    useAuthenticated();
    const translate = useTranslate();
    const [searchParams] = useSearchParams();
    const evalId = searchParams.get('id');
    const { data: report } = useGetOne('closeout', { id: evalId });
    const [project, setProject] = useState<Project>({} as Project);

    useEffect(() => {
        dataProvider('projectinfo')
            .getOne('projectinfo', { id: report?.site.project_fk })
            .then((data) => setProject(data.data));
    }, [report?.site.project_fk]);

    const downloadPdf = () => {
        window.print();
    };

    return (
        <>
            <Grid className="print" mt={3} ml={3}>
                <button onClick={downloadPdf} type="button">
                    {translate('pos.closeout.download')}
                </button>
            </Grid>
            <Grid
                container
                className="pdfcontainer"
                id="pdfx"
                spacing={0}
                mt={2}
                ml={3}
                mr={3}
            >
                <Grid item xs={11} id="pdf">
                    <Grid item xs={12} mb={2}>
                        <Typography variant="h5" fontWeight="fontWeightBold">
                            {translate('pos.closeout.name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {translate('pos.closeout.created_on')}:{' '}
                        </Typography>
                        <Typography variant="body1" component="span">
                            {report?.created_on
                                ? format(
                                      new Date(report?.created_on),
                                      'MMM dd, yyyy',
                                  )
                                : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {translate('pos.closeout.created_by')}:{' '}
                        </Typography>
                        <Typography variant="body1" component="span" mb={2}>
                            <Typography component="span">
                                {project?.project_manager?.display_name &&
                                    `${project?.project_manager?.display_name}`}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography
                            variant="body1"
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {translate('pos.closeout.project')}:{' '}
                            <Typography variant="body1" component="span" mb={2}>
                                <Typography component="span">
                                    {project?.title && `${project?.title}`}
                                </Typography>
                            </Typography>
                        </Typography>
                        <Typography variant="body1" component="span" mb={2}>
                            {' '}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <Typography
                            variant="body1"
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {translate('pos.closeout.project_code')}:{' '}
                            <Typography variant="body1" component="span" mb={2}>
                                <Typography component="span">
                                    {project?.project_code &&
                                        `${project?.project_code}`}
                                </Typography>
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {report?.purchase_order ? (
                            <>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    fontWeight="fontWeightBold"
                                >
                                    {translate('pos.closeout.purchase_order')}:{' '}
                                </Typography>
                                <Typography variant="body1" component="span">
                                    {report?.purchase_order}
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {report?.field_name && report?.field_value ? (
                            <>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    fontWeight="fontWeightBold"
                                >
                                    {report?.field_name}:{' '}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    mb={2}
                                >
                                    {report?.field_value}
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography
                            variant="body1"
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {translate('pos.closeout.site')}:{' '}
                        </Typography>
                        <Typography variant="body1" component="span">
                            {report?.site.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {translate('pos.closeout.site_address')}:{' '}
                        </Typography>
                        <Typography variant="body1" component="span" mb={2}>
                            {report?.site.address}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography
                            variant="body1"
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {translate('pos.closeout.site_start')}:{' '}
                        </Typography>
                        <Typography variant="body1" component="span">
                            {report?.site.current_start
                                ? format(
                                      new Date(report?.site.current_start),
                                      'MMM dd, yyyy',
                                  )
                                : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <Typography
                            variant="body1"
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {translate('pos.closeout.site_end')}:{' '}
                        </Typography>
                        <Typography variant="body1" component="span">
                            {report?.site.current_end
                                ? format(
                                      new Date(report?.site.current_end),
                                      'MMM dd, yyyy',
                                  )
                                : report?.site.scheduled_end
                                ? format(
                                      new Date(report?.site.scheduled_end),
                                      'MMM dd, yyyy',
                                  )
                                : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography variant="h6">Purpose</Typography>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <Typography
                            textAlign="justify"
                            variant="body2"
                            component="span"
                        >
                            {translate('pos.closeout.content_part1')}{' '}
                            <Typography
                                variant="body2"
                                component="span"
                                fontWeight="fontWeightBold"
                            >
                                {report?.site.title}{' '}
                            </Typography>
                            {translate('pos.closeout.content_part2')}{' '}
                            <Typography
                                variant="body2"
                                component="span"
                                fontWeight="fontWeightBold"
                            >
                                {project?.title && `${project?.title}`}{' '}
                            </Typography>
                            at{' '}
                            <Typography
                                variant="body2"
                                component="span"
                                fontWeight="fontWeightBold"
                            >
                                {report?.site.address}{' '}
                            </Typography>
                            {translate('pos.closeout.content_part3')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Scope</Typography>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <Typography
                            textAlign="justify"
                            variant="body2"
                            dangerouslySetInnerHTML={{
                                __html: report?.site.scope,
                            }}
                        ></Typography>
                    </Grid>
                    <Typography variant="h6" my={2}>
                        {translate('pos.closeout.performance')}
                    </Typography>
                    <Grid item xs={12} mb={2}>
                        <Typography variant="body1" fontWeight="fontWeightBold">
                            {translate('Baseline')}
                        </Typography>
                        <Typography
                            textAlign="justify"
                            mt={0}
                            variant="body2"
                            component="span"
                            dangerouslySetInnerHTML={{
                                __html: report?.baseline,
                            }}
                        ></Typography>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <Typography variant="body1" fontWeight="fontWeightBold">
                            {translate('pos.closeout.end_result')}
                        </Typography>
                        <Typography
                            textAlign="justify"
                            mt={0}
                            variant="body2"
                            component="span"
                            dangerouslySetInnerHTML={{
                                __html: report?.end_result,
                            }}
                        ></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            mb={1}
                            variant="body1"
                            fontWeight="fontWeightBold"
                        >
                            {translate('pos.closeout.overall')}
                        </Typography>
                        <Typography
                            textAlign="justify"
                            mt={0}
                            variant="body2"
                            component="span"
                            dangerouslySetInnerHTML={{
                                __html: report?.assesment,
                            }}
                        ></Typography>
                    </Grid>
                    <Typography variant="h6" mb={0}>
                        {translate('Project Completion Work')}
                    </Typography>
                    <Grid item xs={12} my={2}>
                        <Typography variant="body1" fontWeight="fontWeightBold">
                            {translate('pos.closeout.outstanding')}
                        </Typography>
                        <Typography
                            mt={0}
                            variant="body2"
                            component="span"
                            dangerouslySetInnerHTML={{
                                __html: report?.outstanding,
                            }}
                            textAlign="justify"
                        ></Typography>
                    </Grid>
                    <Typography variant="body1" component="span"></Typography>
                    {report?.site?.operations?.map(
                        (operation: any, index: number) => (
                            <>
                                <Grid item xs={12} mt={1} key={operation.id}>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        fontWeight="fontWeightBold"
                                    >
                                        {translate(
                                            'pos.closeout.operation',
                                        ).toUpperCase()}
                                        :{' '}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {operation.title}
                                    </Typography>
                                    {operation?.tasks?.map(
                                        (task: any, index: number) => (
                                            <Grid
                                                item
                                                xs={12}
                                                key={task.id}
                                                sx={{
                                                    marginBottom: 5,
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    fontWeight="fontWeightBold"
                                                >
                                                    {translate(
                                                        'pos.closeout.task',
                                                    ).toUpperCase()}
                                                    :{' '}
                                                    <Typography
                                                        variant="body2"
                                                        component="span"
                                                        sx={{
                                                            fontWeight:
                                                                'normal',
                                                        }}
                                                    >
                                                        {task.title}{' '}
                                                        {task?.photo_required
                                                            ? ''
                                                            : translate(
                                                                  'pos.closeout.no_photo',
                                                              )}
                                                    </Typography>
                                                </Typography>

                                                {task?.note && (
                                                    <Box>
                                                        <Typography
                                                            variant="body2"
                                                            component="span"
                                                            fontWeight="fontWeightBold"
                                                        >
                                                            {translate(
                                                                'pos.closeout.note',
                                                            ).toUpperCase()}
                                                            :{' '}
                                                            <Typography
                                                                variant="body2"
                                                                component="span"
                                                                sx={{
                                                                    fontWeight:
                                                                        'normal',
                                                                }}
                                                            >
                                                                {task.note}
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                )}

                                                <div
                                                    className="grid"
                                                    key={task.id + 1}
                                                    style={{
                                                        margin: '10px 0 ',
                                                    }}
                                                >
                                                    {task?.photos?.map(
                                                        (photo: any) => (
                                                            <div
                                                                key={photo.id}
                                                                className="cell"
                                                            >
                                                                <img
                                                                    height={200}
                                                                    width={150}
                                                                    src={
                                                                        photo.thumb_url
                                                                    }
                                                                    alt={
                                                                        task.title
                                                                    }
                                                                    crossOrigin="anonymous"
                                                                />
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                            </Grid>
                                        ),
                                    )}
                                </Grid>
                                <Divider>
                                    {index ===
                                        report?.site?.operations?.length - 1 &&
                                        'END OF REPORT'}
                                </Divider>
                            </>
                        ),
                    )}
                </Grid>
            </Grid>
        </>
    );
};
