/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsOffRoundedIcon from '@mui/icons-material/NotificationsOffRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import page_info_icon from '../../assets/page_info_icon.svg';

const AcceptNotificationDialog = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [permission, setPermission] = useState('');

    useEffect(() => {
        if ('Notification' in window) {
            setPermission(window.Notification.permission);
        } else {
            setPermission('');
        }
    }, [window]);

    useEffect(() => {
        checkNotificationPermission();
    }, [permission]);

    const checkNotificationPermission = () => {
        if (['default', 'denied'].includes(permission)) {
            setShowDialog(true);
        } else {
            setShowDialog(false);
        }
    };

    if (permission === 'default') {
        return (
            <Dialog open={showDialog}>
                <DialogTitle>Please Allow Desktop Notifications</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setShowDialog(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <DialogContentText>
                        This website requires notification permissions to
                        provide provide you with important updates and real-time
                        chat messages. To enable notifications:
                    </DialogContentText>
                    <DialogContentText>
                        1. Check your browser's address bar or site settings{' '}
                        <img
                            src={page_info_icon}
                            alt="Page Info Icon"
                            style={{ verticalAlign: 'middle' }}
                        />{' '}
                        {' / '}
                        <InfoOutlinedIcon
                            style={{ verticalAlign: 'middle' }}
                        />{' '}
                        for a permission prompt
                    </DialogContentText>
                    {navigator.userAgent.includes('Edg') && (
                        <DialogContentText>
                            {' '}
                            If you are on Microsoft Edge, look for the{' '}
                            <NotificationsOffRoundedIcon
                                style={{ verticalAlign: 'middle' }}
                            />{' '}
                            in your address bar{' '}
                        </DialogContentText>
                    )}
                    <DialogContentText>
                        2. Click the "Allow" button next to the "Notifications"
                        option
                    </DialogContentText>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        );
    } else if (permission === 'denied') {
        return (
            <Dialog open={showDialog}>
                <DialogTitle>Desktop Notifications Blocked</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setShowDialog(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <DialogContentText>
                        Desktop notifications have been disabled for this
                        website. Please enable them to receive important updates
                        and real-time chat messages. To enable notifications:
                    </DialogContentText>
                    <DialogContentText>
                        1. Click the page info button{' '}
                        <img
                            src={page_info_icon}
                            alt="Page Info Icon"
                            style={{ verticalAlign: 'middle' }}
                        />{' '}
                        {' / '}
                        <InfoOutlinedIcon
                            style={{ verticalAlign: 'middle' }}
                        />{' '}
                        in your browser's address bar to expand site permissions
                    </DialogContentText>
                    <DialogContentText>
                        2. Click the "Allow" button next to the "Notifications"
                        option
                    </DialogContentText>
                    <DialogContentText>
                        3. Refresh the page to apply the changes to the site
                        settings
                    </DialogContentText>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        );
    } else {
        return null;
    }
};

export default AcceptNotificationDialog;
