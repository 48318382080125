/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';

// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906

import * as React from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';

import { App } from './App';

// TODO: when upgrade to react18, we need to change to the new Root API
// https://blog.saeloun.com/2021/07/15/react-18-adds-new-root-api/
ReactDOM.render(<App />, document.getElementById('root'));
// createRoot(document.getElementById('root') as HTMLElement).render(<App />);
