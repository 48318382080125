import React, { useEffect } from 'react';
import uitoolkit from '@zoom/videosdk-ui-toolkit';
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css';
import { Button, useAuthProvider, useGetIdentity } from 'react-admin';
import { useParams } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { ZOOM_ONE_TO_ONE } from '../constants';

const VideoCall: React.FC = () => {
    const [inSession, setInSession] = React.useState(false);
    const { sessionName, callType } = useParams();
    const { identity } = useGetIdentity();
    const authProvider = useAuthProvider();
    const [widthOffset, setWidthOffset] = React.useState(280);
    const [width, setWidth] = React.useState(
        window.innerHeight * 1.44702842377261 - widthOffset,
    );

    React.useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerHeight * 1.44702842377261 - widthOffset);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const previewContainer = document.getElementById('previewContainer');
        if (previewContainer) {
            uitoolkit.openPreview(previewContainer);
            return () => {
                uitoolkit.closePreview(previewContainer);
            };
        }
    }, []);

    const joinSession = async () => {
        try {
            const token = await authProvider.getVideoToken({
                session_name: sessionName,
                role_type: callType == ZOOM_ONE_TO_ONE ? 1 : 0,
            });
            const config = {
                videoSDKJWT: token,
                sessionName: sessionName,
                userName: identity?.display_name,
                features: ['video', 'audio', 'users', 'settings', 'share'],
            };

            const sessionContainer =
                document.getElementById('sessionContainer');

            if (!sessionContainer) {
                throw new Error('Session container not found');
            }
            uitoolkit.joinSession(sessionContainer, config);

            uitoolkit.onSessionJoined(() => {
                setInSession(true);
            });

            uitoolkit.onSessionClosed(() => {
                setInSession(false);
            });
        } catch (e) {
            captureException(e);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: '40px',
                height: '100%',
            }}
        >
            <div
                id="sessionContainer"
                style={{
                    display: inSession ? 'block' : 'none',
                    width: width,
                    zIndex: 999,
                }}
            ></div>
            <div
                id="previewContainer"
                style={{ width: '60%', display: inSession ? 'none' : 'block' }}
            ></div>

            {!inSession && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            width: '40%',
                            padding: '22px',
                        }}
                    >
                        <h2 style={{ textAlign: 'center' }}>{sessionName}</h2>
                        <div>
                            <Button
                                label="Join"
                                size="large"
                                style={{
                                    backgroundColor: '#0d72ed',
                                    color: 'white',
                                    padding: '10px 80px',
                                }}
                                onClick={joinSession}
                            ></Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default VideoCall;
