import {
    Confirm,
    DeleteButton,
    SaveButton,
    Toolbar,
    Button,
    useNotify,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { To, useNavigate } from 'react-router';
import { Button as MUIButton, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ArrowBack, DatasetLinked } from '@mui/icons-material';
import Queue from '@mui/icons-material/Queue';
import { UseMutationOptions } from 'react-query';
import { RaRecord, UseCreateMutateParams } from 'ra-core';
import { useFormState } from 'react-hook-form';
import { durationTimeToMilliseconds } from '../../constants';
import { useBlocker } from '../../contexts/BlockerProvider';
import CloneModal from './component/CloneModal';
import {
    Evaluation,
    Operation,
    Project,
    Provider,
    Site,
    Task,
    User,
    TemplateOperation,
    TemplateTask,
} from '../../types';

type RecordTypes = Evaluation &
    Operation &
    Project &
    Provider &
    Site &
    Task &
    User &
    TemplateOperation &
    TemplateTask;

type LowerLevelResources = 'operation' | 'site' | 'task' | 'template_task';

type Resources =
    | LowerLevelResources
    | 'provider'
    | 'project'
    | 'user'
    | 'template_operation'
    | 'evaluation';
interface DefaultToolbar<
    RecordType extends RaRecord = any,
    MutationOptionsError = unknown,
> {
    mutationOptions?: UseMutationOptions<
        RecordType,
        MutationOptionsError,
        UseCreateMutateParams<RecordType>
    > & { meta?: any };
    resource: Resources;
    showCopy?: boolean;
    showDelete?: boolean;
}
/**
 * This component  to standardize the buttons and behaviours for EDIT components
 * including the messages and notifications
 */
export const DefaultToolbar: React.FC<DefaultToolbar> = ({
    mutationOptions = undefined,
    resource,
    showCopy = false,
    showDelete = false,
}) => {
    const navigate = useNavigate();
    const notify = useNotify();
    const translate = useTranslate();
    const { isDirty } = useFormState();
    const { isOpen = false, cancel, leave, confirmLeave } = useBlocker();
    const [modalIsOpen, setModalOpen] = useState<boolean>(false);
    const record = useRecordContext();

    const beforeUnloadHandler = (event: Event) => {
        // Recommended https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        event.preventDefault();

        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true;
    };

    useEffect(() => {
        if (isDirty) {
            setModalOpen(false);
            notify(translate(`resources.${resource}.notification.isdirty`), {
                autoHideDuration: durationTimeToMilliseconds({ seconds: 5 }),
                type: 'warning',
            });
            window.addEventListener('beforeunload', beforeUnloadHandler);
        } else {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        }

        // cleanup listener on dismount
        return () => {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, [isDirty]);

    const isDirtyMessage = (isDirty: boolean) =>
        isDirty ? (
            <Grid item sx={{ paddingTop: 1, paddingLeft: 2 }}>
                <Typography sx={{ color: '#d32f2f' }}>
                    {translate(`resources.${resource}.notification.isdirty`)}
                </Typography>
            </Grid>
        ) : null;

    const getBackButtonPage = (
        resource: Resources,
        record: RecordTypes,
    ): [To | undefined, string | undefined] => {
        const moveUpLevel = {
            site: {
                to: `/project/${record.project_fk}`,
                button: 'Project',
            },
            operation: {
                to: `/site/${record.site_fk}`,
                button: 'Site',
            },
            task: {
                to: `/operation/${record.operation_fk}`,
                button: 'Operation',
            },
            template_task: {
                to: `/template_operation/${record.tpl_op_fk}`,
                button: 'Template Operation',
            },
        };

        return [
            Object.hasOwn(moveUpLevel, resource)
                ? moveUpLevel[resource as LowerLevelResources].to
                : undefined,
            Object.hasOwn(moveUpLevel, resource)
                ? moveUpLevel[resource as LowerLevelResources].button
                : undefined,
        ];
    };

    const [backPage, buttonLable]: [To | undefined, string | undefined] =
        getBackButtonPage(resource, record as RecordTypes);

    const backNavigate = async (navigateBackTo: To, replace = false) => {
        if (!isDirty) {
            navigate(navigateBackTo, { replace: replace });
            return;
        }

        const toBlock = await confirmLeave();
        if (!toBlock) navigate(navigateBackTo, { replace: replace });
    };

    if (!isDirty && typeof leave === 'function') leave(); // If the form is not dirty, automatically let the user leave.

    return (
        <Toolbar
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Grid
                container
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '20px',
                }}
            >
                <Grid item>
                    <Button
                        label={'pos.button.back_button'}
                        onClick={() => backNavigate(-1 as To)}
                    >
                        {<ArrowBack />}
                    </Button>
                </Grid>
                <Grid item>
                    {backPage ? (
                        <MUIButton
                            startIcon={<DatasetLinked />}
                            onClick={() => backNavigate(backPage, true)}
                            variant="outlined"
                        >
                            {translate('pos.button.back_to_button', {
                                page_name: buttonLable,
                            })}
                        </MUIButton>
                    ) : null}
                </Grid>
                <Grid item>
                    <SaveButton
                        type="button"
                        mutationOptions={
                            !mutationOptions ? {} : mutationOptions
                        }
                    />
                </Grid>
                {isDirtyMessage(isDirty)}
            </Grid>
            {showCopy ? (
                <>
                    {!isDirty ? (
                        <Button
                            label={'Copy'}
                            onClick={() => setModalOpen(true)}
                        >
                            <Queue />
                        </Button>
                    ) : null}
                    <CloneModal isOpen={modalIsOpen} setIsOpen={setModalOpen} />
                </>
            ) : null}
            {showDelete ? (
                <DeleteButton
                    mutationOptions={{
                        onSuccess: () => {
                            notify(
                                `resources.${resource}.notification.deleted`,
                                {
                                    undoable: true,
                                },
                            );
                            navigate(backPage ?? (-1 as To));
                            return;
                        },
                    }}
                />
            ) : null}
            {typeof leave === 'function' && typeof cancel === 'function' ? (
                <Confirm
                    isOpen={isOpen}
                    title={translate('common.prompts.confirmation.isdirty')}
                    onConfirm={leave}
                    onClose={cancel}
                    content={<></>}
                />
            ) : null}
        </Toolbar>
    );
};
