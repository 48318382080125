import React, { MouseEvent, useState, useEffect } from 'react';
import {
    Datagrid,
    Empty,
    FunctionField,
    Identifier,
    ListContextProvider,
    ListView,
    TextField,
    useList,
    useTranslate,
    useRecordContext,
    useDataProvider,
    useNotify,
    useGetIdentity,
} from 'react-admin';
import { User, Site } from '../../types';
import { Button } from '@mui/material';
import { Delete } from '@mui/icons-material/';
import { DefaultPerPage } from '../../constants';
import { DatagridEmpty } from '../../components';
import { useBlocker } from '../../contexts/BlockerProvider';
import { Tooltip } from '@material-ui/core';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import { LocalConversation, useTwilio } from '../../contexts/TwilioProvider';
import { Conversation } from '@twilio/conversations';

type AssignedTechListProps = {
    resource: 'site' | 'project';
    assigned: User[];
    handleDelete: (userId: Identifier) => void;
};

export const AssignedTechList = ({
    resource,
    assigned,
    handleDelete,
}: AssignedTechListProps) => {
    const assignedUserContext = useList({
        data: assigned,
        perPage: DefaultPerPage,
    });

    const { confirmLeave } = useBlocker();
    const postRowClick = async (rowClick: string) => {
        const toBlock = await confirmLeave(); //TODO IAS-1250 remove useBlocker, confirmLeave, toBlock once done.
        if (!toBlock) return rowClick;
        return false;
    };

    const dataProvider = useDataProvider();
    const record = useRecordContext<any>();
    const [activeIDs, setActiveIDs] = useState<string[]>([]);

    useEffect(() => {
        const fetchActiveTechs = async () => {
            if (resource == 'project') {
                const fetchPromises = record.sites.map(async (site: Site) => {
                    const data = await dataProvider.customGetAll(
                        `site/${site.id}`,
                    );
                    return data.data.checking_ids;
                });
                const allSiteIDs = await Promise.all(fetchPromises);

                const allIDs = allSiteIDs.flat();
                setActiveIDs((prev: any) => [...prev, ...allIDs]);
            } else setActiveIDs(record.checking_ids);
        };
        fetchActiveTechs();
    }, [record]);

    return (
        <ListContextProvider value={assignedUserContext}>
            <ListView
                actions={false}
                title={'default.no_title'}
                empty={<Empty resource={'user'} hasCreate={false} />} // Page when there is 0 data
            >
                <Datagrid
                    bulkActionButtons={false}
                    empty={<DatagridEmpty />} // Page when there is 0 item in paginated page
                    rowClick={(id: Identifier) =>
                        postRowClick(`/user/${id}/show`)
                    }
                >
                    <FunctionField
                        label="Technician"
                        render={(record: User) => {
                            return (
                                <StatusIcon
                                    active={
                                        activeIDs?.find(
                                            (id: string) => id === record.id,
                                        ) !== undefined
                                    }
                                />
                            );
                        }}
                    />
                    <FunctionField
                        render={(userRecord: User) => (
                            <ChatTechButton userRecord={userRecord} />
                        )}
                        onClick={(event: MouseEvent<HTMLElement>) =>
                            event.stopPropagation()
                        }
                    />

                    <FunctionField
                        label="Technician"
                        render={(record: User) =>
                            `${record.first_name} ${record.last_name}`
                        }
                    />
                    <TextField source="email" />

                    <FunctionField
                        render={(userRecord: User) => {
                            if (!userRecord.site_fk && resource == 'site')
                                return <></>;
                            return (
                                <DeleteTechButton
                                    userRecord={userRecord}
                                    handleDelete={handleDelete}
                                />
                            );
                        }}
                        onClick={(event: MouseEvent<HTMLElement>) =>
                            event.stopPropagation()
                        }
                    />
                </Datagrid>
            </ListView>
        </ListContextProvider>
    );
};

interface DeleteTechButtonProps {
    userRecord: User;
    handleDelete: (userId: Identifier) => void;
}

const DeleteTechButton: React.FC<DeleteTechButtonProps> = ({
    userRecord,
    handleDelete,
}) => {
    const translate = useTranslate();
    const { id: userId } = userRecord;

    return (
        <Button
            variant="text"
            onClick={() => handleDelete(userId)}
            color="error"
            startIcon={<Delete />}
        >
            {translate('actions.assign.unassign')}
        </Button>
    );
};

interface ChatTechButtonProps {
    userRecord: User;
}

const ChatTechButton: React.FC<ChatTechButtonProps> = ({ userRecord }) => {
    const navigate = useNavigate();
    const notify = useNotify();
    const translate = useTranslate();

    const { data } = useGetIdentity();
    const { conversations, setLocalConversation } = useTwilio() as {
        conversations: Conversation[];
        setLocalConversation: React.Dispatch<LocalConversation>;
    };

    const navigateToChat = async () => {
        if (userRecord.email === data?.email) {
            notify(`Can't create a conversation with yourself`, {
                type: 'error',
            });
            return;
        }

        const selectedPeople = [userRecord.email, data?.email].sort();

        for (const conversation of conversations)
            if (conversation.uniqueName == selectedPeople.join('')) {
                navigate(`/chat/${conversation.sid}`);
                return;
            }

        setLocalConversation({
            uniqueName: selectedPeople.join(''),
            selectedPeople: selectedPeople,
            otherPeople: [`${userRecord.first_name} ${userRecord.last_name}`],
            type: 'user',
        });

        navigate(`/chat`);
    };
    return (
        <Button
            variant="text"
            onClick={() => navigateToChat()}
            startIcon={<ChatIcon />}
        >
            {translate('actions.chat')}
        </Button>
    );
};

const StatusIcon = ({ active }: { active: boolean }) => {
    const translate = useTranslate();
    const [color, setColor] = useState('red');

    useEffect(() => {
        if (active) {
            setColor('green');
        } else setColor('red');
    }, [active]);

    return (
        <Tooltip title={<h2>{translate(`status.technician.${color}`)}</h2>}>
            <CircleIcon sx={{ color: color }} />
        </Tooltip>
    );
};
