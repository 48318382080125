import FileCopyIcon from '@mui/icons-material/FileCopy';
import { OperationTemplateList } from './OperationTemplateList';
import { OperationTemplateEdit } from './OperationTemplateEdit';
import { OperationTemplateShow } from './OperationTemplateShow';
import { NotFound } from 'react-admin';

export default {
    create: NotFound,
    edit: OperationTemplateEdit,
    icon: FileCopyIcon,
    list: OperationTemplateList,
    show: OperationTemplateShow,
};
