import React from 'react';
import {
    Datagrid,
    List,
    ListActions,
    NotFound,
    TextField,
    UrlField,
} from 'react-admin';
import { DefaultPerPage } from '../constants';
import { EnhancedPagination } from '../components';

export const ReferenceList: React.FC = () => {
    return (
        <>
            <List
                actions={<ListActions hasCreate={false} exporter={false} />}
                pagination={<EnhancedPagination />}
                perPage={DefaultPerPage}
                title="resources.reference.name"
                empty={<NotFound />}
            >
                <Datagrid optimized bulkActionButtons={false}>
                    <TextField source="title" />
                    <TextField source="description" />
                    <UrlField
                        emptyText="resources.reference.notifications.empty"
                        source="url"
                        target="_blank"
                        rel="noopener noreferrer"
                    />
                </Datagrid>
            </List>
        </>
    );
};
