//Helper functions and interfaces for DFRM Routing

import { parseISO } from 'date-fns';

export const timeSpans = {
    currentWeek: 'week',
    currentMonth: 'month',
    lastWeek: 'lastWeek',
    lastTwoWeeks: 'lastTwoWeeks',
    lastMonth: 'lastMonth',
    lastYear: 'lastYear',
    custom: 'customSpan',
};

export interface dateSpan {
    start: Date | null;
    end: Date | null;
}

export const readDFRMParams: () => {
    timeSpan: string;
    dateFilter: dateSpan;
} = () => {
    const result = {
        timeSpan: timeSpans.currentWeek,
        dateFilter: { start: null, end: null },
    };
    try {
        //Have to do it this way since the '/#/' in the URL messes up the window.location.search URL
        const searchParams = new URLSearchParams(
            window.location.href.substring(
                window.location.href.indexOf('?') + 1,
            ),
        );
        const dateFilter = searchParams.get('dateFilter');
        if (dateFilter !== null) {
            const dateSpan = JSON.parse(dateFilter);
            // parse the datestrings into Date objects
            dateSpan.start = parseISO(dateSpan.start);
            dateSpan.end = parseISO(dateSpan.end);
            result.dateFilter = dateSpan;
        }
        return result;
    } catch (e) {
        return result;
    }
};

export const writeDFRMParams = (
    writeFilter: { start: string; end: string },
    setSearchParams: any,
) => {
    setSearchParams({
        dateFilter: JSON.stringify(writeFilter),
    });
};
