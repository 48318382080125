import {
    Autocomplete,
    AutocompleteProps,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderInputParams,
    Button,
    ButtonGroup,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    TextField,
} from '@mui/material';
import { Cancel, Refresh } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import {
    Identifier,
    RaRecord,
    useNotify,
    useRecordContext,
    useTranslate,
} from 'react-admin';

export type DuplicateSelectAutocompleteProps<T extends RaRecord> = {
    action: (arrayIds: string, recordId: Identifier) => Promise<void>;
    getDefaultValues?: (record: RaRecord) => T[];
    labelResource: (record: T) => string;
    loadResource: (recordId: Identifier) => Promise<any>;
    resource: string;
} & Omit<
    AutocompleteProps<T, true, true, false>,
    'options' | 'renderInput' | 'multiple'
>;

export const DuplicateSelectAutoComplete = <T extends RaRecord>({
    action,
    getDefaultValues = () => [],
    labelResource,
    loadResource,
    resource,
    ...rest
}: DuplicateSelectAutocompleteProps<T>) => {
    const [options, setOptions] = useState<T[]>([]);
    const [values, setValues] = useState<T[]>([]);
    const [listLoading, setListLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [counters, setCounters] = useState<
        { optionId: Identifier; freq: number }[]
    >(options.map((option) => ({ optionId: option.id, freq: 0 })));

    const notify = useNotify();
    const record = useRecordContext();
    const translate = useTranslate();

    const refreshList = async () => {
        setListLoading(true);
        setValues(getDefaultValues(record));
        try {
            const res = await loadResource(record.id);
            setOptions(res.data);
        } catch (error) {
            notify(
                `resources.${resource}.autocomplete.notification.getFailed`,
                {
                    type: 'info',
                },
            );
        }
        setListLoading(false);
    };

    const resetCounters = () => {
        setCounters(
            options.map((option) => ({ optionId: option.id, freq: 0 })),
        );
    };

    const increaseCounters = (option: T) => {
        const newCounters = counters.map((counter) =>
            counter.optionId == option.id
                ? {
                      optionId: counter.optionId,
                      freq: counter.freq + 1,
                  }
                : counter,
        );
        setCounters(newCounters);
        const newValues = [...values, option];
        setValues(newValues);
    };

    const decreaseCounters = (option: T) => {
        const newCounters = counters.map((counter) =>
            counter.optionId == option.id
                ? {
                      optionId: counter.optionId,
                      freq: counter.freq - 1,
                  }
                : counter,
        );
        setCounters(newCounters);
        const removalIndex = values.findIndex((i) => i.id === option.id);
        const newValues = [
            ...values.slice(0, removalIndex),
            ...values.slice(removalIndex + 1),
        ];
        setValues(newValues);
    };

    useEffect(() => {
        refreshList();
    }, []);

    useEffect(() => {
        if (!listLoading || !actionLoading) {
            resetCounters();
        }
    }, [listLoading, actionLoading]);

    const submit = (input: T[]) => {
        setActionLoading(true);
        const selectedIds: Identifier[] = [];
        input.forEach((value) => {
            selectedIds.push(value.id);
        });
        const arrayIds = encodeURIComponent(JSON.stringify(selectedIds));
        action(arrayIds, record.id)
            .then(() => {
                notify(`resources.${resource}.notification.saved`, {
                    type: 'info',
                });
                setValues([]);
                refreshList();
            })
            .catch(() => {
                notify(
                    `resources.${resource}.autocomplete.notification.actionFailed`,
                    {
                        type: 'error',
                    },
                );
            })
            .finally(() => {
                setActionLoading(false);
            });
    };

    const renderOptions = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: T,
    ) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onClick, ...rest } = props;
        const { id } = option;
        const newKey = `autocomplete-option-${id}`;
        const optionProps = { ...rest, key: newKey };
        return (
            <li {...optionProps}>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>{labelResource(option)}</Grid>
                    <Grid item>
                        <ButtonGroup
                            size="small"
                            aria-label="small outlined button group"
                        >
                            <Button
                                onClick={() => {
                                    increaseCounters(option);
                                }}
                            >
                                +
                            </Button>

                            <Button
                                disabled
                                sx={{
                                    '&.Mui-disabled': {
                                        color: 'darkblue',
                                    },
                                }}
                            >
                                {
                                    counters.find(
                                        (counter) =>
                                            counter.optionId == option.id,
                                    )?.freq
                                }
                            </Button>

                            <Button
                                disabled={
                                    (counters.find(
                                        (counter) =>
                                            counter.optionId == option.id,
                                    )?.freq ?? 0) <= 0
                                }
                                onClick={() => {
                                    decreaseCounters(option);
                                }}
                            >
                                -
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </li>
        );
    };

    const renderTags = (
        tagValue: T[],
        getTagProps: AutocompleteRenderGetTagProps,
    ) => {
        return tagValue.map((option, index) => (
            <Chip
                {...getTagProps({ index })}
                label={labelResource(option)}
                key={`autocomplete-${index}`}
                deleteIcon={
                    <div
                        onMouseDown={() => {
                            const newCounters = counters.map((counter) =>
                                counter.optionId == option.id
                                    ? {
                                          optionId: counter.optionId,
                                          freq: counter.freq - 1,
                                      }
                                    : counter,
                            );
                            const newValues = values.filter(
                                (_, i) => i !== index,
                            );
                            setCounters(newCounters);
                            setValues(newValues);
                        }}
                    >
                        <Cancel fontSize="small" sx={{ marginTop: '4px' }} />
                    </div>
                }
            />
        ));
    };

    const renderInput = (params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                label={translate(`resources.${resource}.autocomplete.label`)}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {(actionLoading || listLoading) && (
                                <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        );
    };

    return (
        <Grid container spacing={2} alignItems="center" direction="row">
            <Grid item sx={{ width: '80%' }}>
                <Autocomplete<T, true, true, false>
                    {...rest}
                    disableCloseOnSelect
                    multiple
                    options={options}
                    value={values}
                    disabled={actionLoading}
                    getOptionLabel={(option) => labelResource(option)}
                    renderOption={(props, option) =>
                        renderOptions(props, option)
                    }
                    renderTags={(tagValue, getTagProps) =>
                        renderTags(tagValue, getTagProps)
                    }
                    renderInput={(params) => renderInput(params)}
                    onClose={() => {
                        if (!values || !values.length) return;
                        submit(values);
                    }}
                    onChange={(_event, value, reason) => {
                        if (reason == 'clear') resetCounters();
                        setValues(value);
                    }}
                />
            </Grid>
            <Grid item>
                <IconButton
                    disabled={actionLoading || listLoading}
                    onClick={refreshList}
                >
                    <Refresh />
                </IconButton>
            </Grid>
        </Grid>
    );
};
