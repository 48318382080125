import React from 'react';
import {
    BooleanField,
    ReferenceManyField,
    SimpleForm,
    TextInput,
    TextField,
    useRecordContext,
    required,
    ArrayInput,
    SimpleFormIterator,
    DeleteButton,
    useNotify,
    FormDataConsumer,
    useRefresh,
} from 'react-admin';
import { Operation } from '../types';
import {
    DefaultEditToolbar,
    DraggableDatagrid,
    EnhancedListView,
    EnhancedPagination,
    InfoIcon,
    LinkWrapper,
    SublistTopToolbar,
    SublistEdit,
} from '../components';
import { Grid, Typography } from '@mui/material';

const OperationTitle = () => {
    const record = useRecordContext<Operation>();
    return record ? (
        <span>
            Operation {record.id} - {record.title}
            <InfoIcon resource={'operation'} record={record} />
        </span>
    ) : null;
};

export const OperationEdit = () => {
    const disableAdd = (data: any) => data.links?.length > 0;
    const notify = useNotify();
    const refresh = useRefresh();

    return (
        <SublistEdit
            resource="operations"
            title={<OperationTitle />}
            component="div"
        >
            <Grid container direction="column" rowSpacing={5}>
                <Grid item>
                    <Grid
                        item
                        sx={{
                            border: `2px solid lightgrey`,
                            borderRadius: `12px`,
                            paddingBottom: `2px`,
                        }}
                        padding={'1.3em'}
                    >
                        <SimpleForm
                            toolbar={
                                <DefaultEditToolbar resource="operation" />
                            }
                        >
                            <TextInput
                                autoFocus
                                source="title"
                                sx={{ width: '80% ' }}
                                validate={required()}
                            />
                            <TextInput
                                source="description"
                                sx={{ width: '80% ' }}
                                label="resources.operation.fields.description"
                            />
                            <FormDataConsumer>
                                {({ formData }) => (
                                    <ArrayInput source="links" margin="normal">
                                        <SimpleFormIterator
                                            inline
                                            disableClear
                                            disableAdd={disableAdd(formData)}
                                        >
                                            <TextInput
                                                source="title"
                                                label="File title"
                                                helperText={false}
                                                sx={{
                                                    width: '30%',
                                                }}
                                            />
                                            <TextInput
                                                source="uri"
                                                helperText={false}
                                                type="url"
                                                label="link"
                                                sx={{
                                                    width: '67%',
                                                }}
                                            />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                )}
                            </FormDataConsumer>
                        </SimpleForm>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid
                        item
                        sx={{
                            border: `2px solid lightgrey`,
                            borderRadius: `12px`,
                            paddingBottom: `2px`,
                        }}
                        padding={'1.3em'}
                    >
                        <div style={{ height: 5 }} />
                        <Typography variant="subtitle2" color="grey">
                            Tasks:
                        </Typography>
                        <ReferenceManyField
                            label="Tasks"
                            reference="task"
                            target="operation_fk"
                            sort={{ field: 'priority_number', order: 'ASC' }}
                        >
                            <EnhancedListView
                                resource="task"
                                actions={
                                    <SublistTopToolbar
                                        label="Create Task +"
                                        resource="task"
                                    />
                                }
                                pagination={<EnhancedPagination />}
                            >
                                <DraggableDatagrid>
                                    <TextField
                                        source="priority_number"
                                        label="resources.task.fields.completion_order"
                                        sortable={false}
                                    />
                                    <TextField
                                        source="status"
                                        sortable={false}
                                    />
                                    <LinkWrapper
                                        source="title"
                                        sortable={false}
                                    >
                                        <TextField
                                            source="title"
                                            sx={{
                                                color: 'black',
                                                textDecoration: 'underline',
                                            }}
                                        />
                                    </LinkWrapper>
                                    <TextField
                                        source="description"
                                        sortable={false}
                                    />
                                    <BooleanField
                                        source="note_required"
                                        sortable={false}
                                    />
                                    <BooleanField
                                        source="photo_required"
                                        sortable={false}
                                    />
                                    <DeleteButton
                                        mutationOptions={{
                                            onSuccess: () => {
                                                refresh();
                                                notify(
                                                    `resources.task.notification.deleted`,
                                                    {
                                                        undoable: true,
                                                    },
                                                );
                                            },
                                        }}
                                    />
                                </DraggableDatagrid>
                            </EnhancedListView>
                        </ReferenceManyField>
                    </Grid>
                </Grid>
            </Grid>
        </SublistEdit>
    );
};
