import { DataProvider, withLifecycleCallbacks } from 'react-admin';
import axios from 'axios';
import { GetAllParams, sanitizeListQueryParams } from './helpers';
import { apiUrl, httpClient } from './client';
import { ExternalAPIMethods } from './ExternalAPIMethods';
import { RequiredMethods } from './RequiredMethods';
import { ReportMethods } from './ReportMethods';
import { projectLifeCycleCallbacks } from '../projects/ProjectLifeCycleCallbacks';
import { siteLifeCycleCallbacks } from '../sites/SiteLifeCycleCallbacks';
import { operationLifeCycleCallbacks } from '../operations/OperationLifeCycleCallbacks';
export * from './helpers';
export { apiUrl } from './client';

const debug = true;

const geocodingApiKey = 'AIzaSyCJXcmVRhFveqvQOqbsGuWvFw8H5WuxbfI'; // Okos's key

export default (type: string) => {
    // The fake servers require to generate data, which can take some time.
    // Here we start the server initialization but we don't wait for it to finish
    const dataProviderPromise = getDataProvider(type);

    // Instead we return this proxy which may be called immediately by react-admin if the
    // user is already signed-in. In this case, we simply wait for the dataProvider promise
    // to complete before requesting it the data.
    // If the user isn't signed in, we already started the server initialization while they see
    // the login page. By the time they come back to the admin as a signed-in user,
    // the fake server will be initialized.
    const dataProviderWithGeneratedData = new Proxy(defaultDataProvider, {
        get(_, name) {
            return (resource: string, params: any) => {
                return dataProviderPromise.then((dataProvider) => {
                    return dataProvider[name.toString()](resource, params);
                });
            };
        },
    });

    return dataProviderWithGeneratedData;
};

const getDataProvider = async (type: string): Promise<DataProvider> => {
    //await fakeServerFactory(process.env.REACT_APP_DATA_PROVIDER || '');
    /**
     * This demo can work with
     * either a fake REST server, or a fake GraphQL server.
     *
     * To avoid bundling both libraries, the dataProvider and fake server factories
     * use the import() function, so they are asynchronous.
     */
    // if (type === 'graphql') {
    //     return import('./graphql').then((factory) => factory.default());
    // }
    return defaultDataProvider;
};

const baseDataProvider: DataProvider = {
    ...ExternalAPIMethods,
    ...RequiredMethods,
    ...ReportMethods,
    /*
     * Used specifically to get the full of data
     */
    getAll: (resource: string, params: GetAllParams) => {
        return httpClient(`${apiUrl}/${resource}`, {
            ...sanitizeListQueryParams(params),
        }).then(({ json }) => ({
            data: json,
        }));
    },
    getOneWithBody: (resource: string, params: any) => {
        const { ids, ...rest } = params;
        const path = ids.join('/');
        return httpClient(`${apiUrl}/${resource}/${path}`, {
            ...rest,
        }).then(({ json }) => ({ data: json }));
    },
    customGetAll: (resource: string, params: any) => {
        return httpClient(`${apiUrl}/${resource}`, {
            params,
        }).then(({ json }) => ({
            data: json,
        }));
    },
    customUpdateOne: (resource: string, params: any) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            data: params.data,
        }).then(({ json }) => ({ data: json }));
    },
    updateTables: (resource: string, params: any) => {
        return httpClient(
            `${apiUrl}/${resource}/${params.table}/${params.id}`,
            {
                method: 'PUT',
                data: params.data,
            },
        ).then(({ json }) => ({ data: json }));
    },
    customCopy: (resource: string, params: any) => {
        return httpClient(
            `${apiUrl}/${resource}/${params.first_id}/copy/${params.second_id}`,
            {
                method: 'PUT',
                data: params.data,
            },
        ).then(({ json }) => ({ data: json }));
    },
    customCopyManyTemplates: (resource: string, params: any) => {
        return httpClient(
            `${apiUrl}/${resource}/${params.first_id}/copytemplates?ids=${params.idArray}`,
            {
                method: 'PUT',
                data: params.data,
            },
        ).then(({ json }) => ({ data: json }));
    },
    resetLink: (resource: string, params: any) => {
        return httpClient(`${apiUrl}/${resource}/${params.resource_id}/links`, {
            method: 'POST',
            data: params.data,
        }).then(({ json }) => ({ data: json }));
    },
    // should deprecated
    getIdentity: (resource: any) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            data: {},
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            }),
        }).then(({ json }) => ({ data: json }));
    },
    createChatRoom: (params: any) => {
        return httpClient(`${apiUrl}/chat/conversation`, {
            method: 'POST',
            params: params,
        }).then(({ json }) => ({ data: json }));
    },
    generateProjectReport: (resource: string, params: any) => {
        return httpClient(
            `${apiUrl}/${resource}?start=${params.start}&end=${params.end}`,
        ).then(({ json }) => ({ data: json }));
    },
    googleGet: async (uri: string, params: any) => {
        return axios.get(uri, {
            params: {
                key: geocodingApiKey,
                ...params,
            },
        });
    },
    // unassignAllTechs: (resource: string, params: any) => {
    //     return httpClient(
    //         `${apiUrl}/${resource}/${params.resource_id}/claimed/all`,
    //         {
    //             method: 'PUT',
    //             body: JSON.stringify(params.data),
    //         },
    //     );
    // },
};

const defaultDataProvider = withLifecycleCallbacks(baseDataProvider, [
    siteLifeCycleCallbacks,
    operationLifeCycleCallbacks,
    projectLifeCycleCallbacks,
]);
