import React, { useEffect } from 'react';
import {
    BooleanInput,
    FormDataConsumer,
    NumberInput,
    NumberInputProps,
    useTranslate,
} from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { BooleanInputProps } from 'react-admin';
import { Grid } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch';

type NumberToggleProps = {
    boolSrc: string;
    boolLabel: string;
    numSrc: string;
    numLabel: string;
    options?: SwitchProps;
} & Omit<BooleanInputProps, 'source' | 'options'> &
    Omit<NumberInputProps, 'source'>;

export const BooleanNumberToggle = ({
    boolSrc,
    boolLabel,
    numSrc,
    numLabel,
    options,
    ...restOfProps
}: NumberToggleProps) => {
    const translate = useTranslate();
    const { setValue, resetField } = useFormContext();

    const boolValue = useWatch({ name: boolSrc });

    const validateNumInput = (value: number) => {
        if (value <= 0) {
            const err = translate('resources.task.field_errors.num_photos');
            return err;
        }
    };

    useEffect(() => {
        if (!boolValue) {
            setValue(numSrc, 0);
        } else {
            resetField(numSrc);
        }
    }, [boolValue]);

    return (
        <>
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.photo_required && (
                                <NumberInput
                                    source={numSrc}
                                    label={numLabel}
                                    validate={validateNumInput}
                                    {...rest}
                                    {...restOfProps}
                                />
                            )
                        }
                    </FormDataConsumer>
                </Grid>
                <Grid item>
                    <BooleanInput
                        source={boolSrc}
                        label={boolLabel}
                        options={options}
                        {...restOfProps}
                    />
                </Grid>
            </Grid>
        </>
    );
};
