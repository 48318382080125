import React from 'react';
import { useRecordContext } from 'react-admin';
import { Datagrid, InfiniteList } from 'react-admin';
import { OperationExpand } from './OperationExpand';
import { Box } from '@mui/material';
import { InfiniteListLoadMoreBtn } from '../../components/InfiniteListLoadMoreBtn';
import PrefixTextField from './components/PrefixTextField';

export const SiteExpand = () => {
    const record = useRecordContext();

    return (
        <InfiniteList
            resource="operation"
            filter={{ site_fk: record.id }}
            sort={{ field: 'order', order: 'ASC' }}
            actions={false}
            pagination={<InfiniteListLoadMoreBtn buttonType="Operations" />}
            title={'default.no_title'}
        >
            <Box
                sx={{
                    border: '1px solid rgba(0,0,0,0.2)',
                    borderRadius: '5px',
                }}
            >
                <Datagrid
                    bulkActionButtons={false}
                    expand={<OperationExpand />}
                    sx={{
                        '& .RaDatagrid-row': {
                            backgroundColor: 'WhiteSmoke',
                        },
                    }}
                >
                    <PrefixTextField
                        label="Operations"
                        source="title"
                        prefix="Operation"
                        sortable={false}
                    />
                </Datagrid>
            </Box>
        </InfiniteList>
    );
};
