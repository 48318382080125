import React, {
    createContext,
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

interface BlockerProviderProps {
    children?: React.ReactNode;
}

export type BlockerProps = {
    isOpen: boolean;
    cancel: null | (() => void);
    leave: null | (() => void);
};

type BlockerContext = [BlockerProps, Dispatch<SetStateAction<BlockerProps>>];

const defaultBlockerState = {
    isOpen: false,
    cancel: null,
    leave: null,
};

const BlockerContext = createContext<BlockerContext | null>(null);

export const BlockerProvider: FunctionComponent<BlockerProviderProps> = ({
    children,
}) => {
    const [blocker, setBlocker] = useState<BlockerProps>({
        ...defaultBlockerState,
    });
    return (
        <BlockerContext.Provider value={[blocker, setBlocker]}>
            {children}
        </BlockerContext.Provider>
    );
};

export type UseBlockerProps = {
    confirmLeave: () => Promise<boolean>;
} & BlockerProps;

/**
 * @params children all other react props
 * @returns isOpen indicates if modal should open
 * @returns cancel takes on the Promise resolve for the modalPromise
 * @returns leave takes on the Promise reject for the modalPromise
 */
export function useBlocker(): UseBlockerProps {
    const blockerContext = useContext(BlockerContext);

    if (blockerContext === null) {
        throw new Error(
            'useBlocker must be used within a SocketContext Provider',
        );
    }
    const [blocker, setBlocker] = blockerContext;
    const [touched, setTouched] = useState<boolean>(false);

    const confirmLeave = useCallback(() => {
        const modalPromise = new Promise<void>((resolve, reject) => {
            setBlocker({
                isOpen: true,
                cancel: resolve,
                leave: reject,
            });

            setTouched(true); // indicate clean-up needed on modal unmount
        });

        const reset = () => {
            setBlocker({ ...defaultBlockerState });
            setTouched(false);
        };
        return modalPromise.then(
            //onFulfilled = Cancel
            () => {
                reset();
                return true;
            },
            //onRejected = Confirm
            () => {
                reset();
                return false;
            },
        );
    }, []);

    useEffect(() => {
        return () => {
            if (blocker.cancel && touched) {
                blocker.cancel(); // fn to go with each state change to cleanup on modal unmount
            }
        };
    }, [touched, blocker]);

    return { ...blocker, confirmLeave };
}
