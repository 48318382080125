import React from 'react';
import {
    Datagrid,
    FunctionField,
    ListContextProvider,
    RaRecord,
    TextField,
    useList,
    useListContext,
} from 'react-admin';
import { getHoursAndMinutes } from '../../helpers';
import { ClickableLocationIcon, LinkWrapper, TimeZoneDateField } from '../';
import { Grid } from '@mui/material';

const WORKEVENT = 'Work';

export const DateFilteredList: React.FC = () => {
    const { data } = useListContext();
    const listContext = useList({ data });

    return (
        <ListContextProvider value={listContext}>
            <Datagrid bulkActionButtons={false} size="medium">
                <LinkWrapper
                    label="resources.site.fields.title"
                    resource="site"
                    target="site_fk"
                    sortable={false}
                >
                    <TextField
                        source="site_title"
                        sx={{
                            color: 'black',
                            textDecoration: 'underline',
                        }}
                    />
                </LinkWrapper>
                <LinkWrapper
                    label="resources.project.fields.title_project"
                    resource="project"
                    target="project_fk"
                    sortable={false}
                >
                    <TextField
                        source="project_title"
                        sx={{
                            color: 'black',
                            textDecoration: 'underline',
                        }}
                    />
                </LinkWrapper>
                <TimeZoneDateField source="start_time" user_event />
                <TimeZoneDateField source="end_time" user_event />
                <TextField source="event_type" />
                <FunctionField
                    source="duration_minutes"
                    label="Duration"
                    render={(record: RaRecord) => {
                        if (!record.duration_minutes) return 'No data';
                        return getHoursAndMinutes(record.duration_minutes);
                    }}
                />
                <FunctionField
                    label="Check In / Check Out"
                    render={(record: RaRecord) => {
                        const { site_check_in, site_check_out, event_type } =
                            record;
                        return (
                            event_type == WORKEVENT && (
                                <Grid container direction="column">
                                    <Grid item>
                                        <ClickableLocationIcon
                                            lat={site_check_in?.lat}
                                            lng={site_check_in?.lng}
                                            label={'Check in'}
                                        />
                                    </Grid>
                                    <Grid>
                                        <ClickableLocationIcon
                                            lat={site_check_out?.lat}
                                            lng={site_check_out?.lng}
                                            label={'Check out'}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        );
                    }}
                />
            </Datagrid>
        </ListContextProvider>
    );
};
