import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { ReferenceList } from './ReferenceList';
import { NotFound } from 'react-admin';

export default {
    create: NotFound,
    edit: NotFound,
    icon: DocumentScannerIcon,
    list: ReferenceList,
};
