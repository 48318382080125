import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DatagridHeader,
    DatagridRow,
    TextField,
    useListContext,
    useRecordContext,
    useDataProvider,
    Identifier,
} from 'react-admin';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DroppableProvided,
    DraggableProvided,
    DropResult,
} from 'react-beautiful-dnd';
import { Table, TableBody } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Operation, Task, TemplateTask } from '../../types';
import { useBlocker } from '../../contexts/BlockerProvider';

interface DraggableDatagridProps {
    children: React.ReactNode;
}

export const DraggableDatagrid: React.FC<DraggableDatagridProps> = ({
    children,
}) => {
    const { confirmLeave } = useBlocker();
    const onRowClick = async (resource: string, id: Identifier) => {
        const toBlock = await confirmLeave(); //TODO IAS-1250 remove useBlocker, confirmLeave, toBlock once done.
        if (!toBlock) {
            navigate(`/${resource}/${id}`);
        }
    };

    const { data, resource, page, perPage, isLoading, isFetching, ...rest } =
        useListContext();

    const record = useRecordContext();
    const dataProvider = useDataProvider();

    const [orderedData, setOrderedData] = useState(data);

    const handleDragEnd = async (result: DropResult) => {
        const { source, destination, draggableId } = result;

        if (!destination || source.index === destination.index) {
            return;
        }

        const oldOrderedData = [...orderedData];

        try {
            const sourceTask = orderedData[source.index];
            const orderedDataCopy = [...orderedData];
            orderedDataCopy.splice(source.index, 1);
            orderedDataCopy.splice(destination.index, 0, sourceTask);

            const startIndex =
                source.index > destination.index ? destination.index : 0;

            if (resource === 'task' || resource === 'template_task') {
                for (let i = startIndex; i < orderedDataCopy.length; i++) {
                    orderedDataCopy[i] = { ...orderedDataCopy[i] };
                    orderedDataCopy[i].priority_number = i + 1;
                }
            }

            if (resource === 'operation') {
                for (let i = startIndex; i < orderedDataCopy.length; i++) {
                    orderedDataCopy[i] = { ...orderedDataCopy[i] };
                    orderedDataCopy[i].order = i + 1;
                }
            }

            setOrderedData(orderedDataCopy);
            const response = await dataProvider.updateTables('orderlist', {
                table: resource,
                id: draggableId,
                data: {
                    start: source.index,
                    end: destination.index,
                    page: page,
                    per_page: perPage,
                },
            });
            setOrderedData(response.data);
        } catch (error) {
            setOrderedData(oldOrderedData);
            console.error('Error updating order:', error);
        }
    };

    const isDragDisabled = (
        entry: Operation | Task | TemplateTask,
        resource: string,
    ) => {
        if (resource === 'task' || isLoading) {
            return (
                entry.status &&
                entry.status !== 'initial' &&
                entry.status !== 'delayed'
            );
        }
        return false;
    };

    const navigate = useNavigate();

    useEffect(() => {
        setOrderedData(data);
    }, [data]);

    if (!orderedData || orderedData.length === 0) return null;

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={record.id.toString()}>
                {(provided: DroppableProvided) => (
                    <Table ref={provided.innerRef} {...provided.droppableProps}>
                        <DatagridHeader>
                            <TextField />
                            {children}
                        </DatagridHeader>
                        <TableBody>
                            {orderedData.map(
                                (
                                    entry: Operation | Task | TemplateTask,
                                    index: number,
                                ) => (
                                    <Draggable
                                        key={entry.id}
                                        draggableId={entry.id.toString()}
                                        index={index}
                                        isDragDisabled={isDragDisabled(
                                            entry,
                                            resource,
                                        )}
                                    >
                                        {(provided: DraggableProvided) => (
                                            <DatagridRow
                                                record={entry}
                                                onClick={() =>
                                                    onRowClick(
                                                        resource,
                                                        entry.id,
                                                    )
                                                }
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <DragHandleIcon />
                                                {children}
                                            </DatagridRow>
                                        )}
                                    </Draggable>
                                ),
                            )}
                            {provided.placeholder}
                        </TableBody>
                    </Table>
                )}
            </Droppable>
        </DragDropContext>
    );
};
