import axios from 'axios';
import { serverURL } from '../contexts/SocketProvider';
import { HttpError } from 'react-admin';

export const apiUrl = serverURL;
export const hubApiUrl = 'https://io.okos.ca';
// export const adminUrl = 'http://localhost:3000';
export const adminUrl = 'https://mgmt.okos.ca'; // test web server
// export const adminUrl = 'https://projects.okos.ca'; //prod web server

interface CustomError extends Error {
    response?: {
        status: number;
        statusText: string;
        headers: any;
        data: any;
    };
}

export const fetchJson = (url: string, options: any) => {
    return (
        axios({ url, ...options, headers: options.headers })
            .then(function (response) {
                const res = {
                    status: response?.status,
                    headers: response?.headers,
                    body: response?.data,
                    json: response?.data,
                };
                return res;
            })
            // https://marmelab.com/react-admin/DataProviderWriting.html#error-format
            .catch(function (stuff) {
                const { response } = stuff;
                const { status, statusText } = response;
                return Promise.reject(new HttpError(statusText, status));
            })
    );
};

export const httpClient = (url: string, options: any = {}) => {
    const headers = {};

    if (!options.headers) {
        Object.assign(headers, { 'Content-Type': 'application/json' });

        const token = localStorage.getItem('token');

        if (token) {
            Object.assign(headers, { Authorization: `Bearer ${token}` });
        }

        options.headers = headers;
    }
    return fetchJson(url, options);
};
