import React from 'react';
import { Box, Button } from '@mui/material';
import { useInfinitePaginationContext } from 'react-admin';

interface InfiniteListLoadMoreBtnProps {
    buttonType: string;
}

export const InfiniteListLoadMoreBtn: React.FC<
    InfiniteListLoadMoreBtnProps
> = ({ buttonType }) => {
    const { hasNextPage, fetchNextPage, isFetchingNextPage } =
        useInfinitePaginationContext();
    return hasNextPage ? (
        <Box mt={1} textAlign="center">
            <Button
                disabled={isFetchingNextPage}
                onClick={() => fetchNextPage()}
            >
                Load more {buttonType}
            </Button>
        </Box>
    ) : null;
};
