export const operationLifeCycleCallbacks = {
    resource: 'operation',
    beforeUpdate: async (params: any, dataProvider: any) => {
        const { data } = params;
        const { links, ...rest } = data;
        dataProvider.resetLink('operation', {
            resource_id: data?.id,
            data: { links: links },
        });
        params.data = rest;
        return params;
    },
};
