import React from 'react';
import { TopToolbar } from 'react-admin';
import { ListCreateButton } from '../ListCreateButton';

interface SublistTopToolbarProps {
    label?: string;
    resource:
        | 'site'
        | 'task'
        | 'template_task'
        | 'operation'
        | 'project'
        | 'template_operation'
        | 'evaluation';
}

export const SublistTopToolbar: React.FC<SublistTopToolbarProps> = ({
    resource,
    label,
}) => {
    return (
        <TopToolbar>
            <ListCreateButton resource={resource} label={label} />
        </TopToolbar>
    );
};
