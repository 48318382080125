import React, { useState } from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    FilterButton,
    List,
    ListActionsProps,
    TextField,
    TextInput,
    TopToolbar,
    usePermissions,
} from 'react-admin';
import { DefaultPerPage, dateTimeOptions, defaultLocale } from '../constants';
import {
    EnhancedEmpty,
    EnhancedPagination,
    ListCreateButton,
    QuickFilter,
} from '../components/';
import { FilterOptions, RBACFilters } from '../helpers';

interface RestrictedListActionProps extends ListActionsProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    permissions: any;
}

const OperationTemplateAllFilters: FilterOptions[] = [
    {
        component: <TextInput key={'tpl-op-filters-00'} source="title" />,
        role: 'all',
    },
    {
        component: <TextInput key={'tpl-op-filters-01'} source="description" />,
        role: 'all',
    },
    {
        component: (
            <QuickFilter
                key={'tpl-op-filters-02'}
                source="is_active"
                defaultValue={true}
            />
        ),
        role: 'Admin',
    },
];

export const OperationTemplateList: React.FC = () => {
    const OperationTemplateActions = (props: RestrictedListActionProps) => {
        const { permissions } = props;
        return (
            <TopToolbar>
                <FilterButton />
                {permissions === 'Admin' ? (
                    <ListCreateButton resource={'template_operation'} />
                ) : null}
            </TopToolbar>
        );
    };

    const { permissions } = usePermissions();

    const OperationTemplateFilters: React.ReactElement[] = RBACFilters(
        OperationTemplateAllFilters,
        permissions,
    );
    const [permanentFilter, setPermanentFilter] = useState(
        permissions !== 'Admin' ? { is_active: true } : {},
    );
    return (
        <>
            <List
                actions={<OperationTemplateActions permissions={permissions} />}
                pagination={<EnhancedPagination />}
                perPage={DefaultPerPage}
                filter={{ ...permanentFilter }} // only Admin can see inactive/archived templates
                filters={OperationTemplateFilters}
                filterDefaultValues={{ is_active: true }}
                empty={<EnhancedEmpty resource="template_operation" />}
                queryOptions={{
                    onError: () => setPermanentFilter({}),
                }} // TODO: remove when API is deployed. Use for backward compatability
            >
                <Datagrid
                    optimized
                    bulkActionButtons={false}
                    rowClick={permissions === 'Admin' ? 'edit' : 'show'}
                >
                    <TextField source="title" />
                    <TextField source="description" />
                    <DateField
                        source="created_on"
                        locales={defaultLocale}
                        options={dateTimeOptions}
                    />
                    <DateField
                        source="updated_on"
                        locales={defaultLocale}
                        options={dateTimeOptions}
                    />
                    <BooleanField source="is_active" />
                </Datagrid>
            </List>
        </>
    );
};
