import AssessmentIcon from '@mui/icons-material/Assessment';
import { SiteEvaluationEdit } from './SiteEvaluationEdit';
import { SiteEvaluationCreate } from './SiteEvaluationCreate';
import { SiteEvaluationList } from './SiteEvaluationList';

export default {
    create: SiteEvaluationCreate,
    edit: SiteEvaluationEdit,
    icon: AssessmentIcon,
    list: SiteEvaluationList,
};
