import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConversationList, Search } from '@chatscope/chat-ui-kit-react';
import { Loading, useGetIdentity, useNotify } from 'react-admin';
import { Conversation } from '@twilio/conversations';

import { LocalConversation, useTwilio } from '../../contexts/TwilioProvider';
import { getAvatar } from '../components/ChatAvatar';
import { User } from '../../types';
import { CheckboxContainer } from './styles';

interface ContactSelectionProps {
    setIsCreatingNewChat: (IsCreatingNewChat: boolean) => void;
    chatContacts: User[];
}

const ContactSelection: React.FC<ContactSelectionProps> = ({
    setIsCreatingNewChat,
    chatContacts,
}) => {
    const notify = useNotify();

    const [search, setSearch] = useState<string>('');

    const { data } = useGetIdentity();
    const { conversations, setLocalConversation } = useTwilio() as {
        conversations: Conversation[];
        setLocalConversation: React.Dispatch<LocalConversation>;
    };

    const navigate = useNavigate();

    const handleNewConversation = async (other_user: User) => {
        if (other_user.email === data?.email) {
            setIsCreatingNewChat(false);
            notify(`Can't create a conversation with yourself`, {
                type: 'error',
            });
            return;
        }

        const selectedPeople = [other_user.email, data?.email].sort();

        for (const conversation of conversations)
            if (conversation.uniqueName == selectedPeople.join('')) {
                navigate(`/chat/${conversation.sid}`);
                setIsCreatingNewChat(false);
                return;
            }

        setLocalConversation({
            uniqueName: selectedPeople.join(''),
            selectedPeople: selectedPeople,
            otherPeople: [`${other_user.first_name} ${other_user.last_name}`],
            type: 'user',
        });

        navigate(`/chat`);
        setIsCreatingNewChat(false);
    };

    if (!chatContacts) return <Loading />;

    return (
        <>
            <Search
                value={search}
                placeholder="Search Users..."
                onChange={(e: string) => setSearch(e)}
                onClearClick={() => setSearch('')}
            />
            <ConversationList>
                {chatContacts
                    .filter((user: User) => user.email != data?.email)
                    .filter(
                        (user: User) =>
                            user.display_name
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                            user.email.includes(search.toLowerCase()),
                    )
                    .map((user: User) => (
                        <CheckboxContainer
                            key={user.email}
                            onClick={() => handleNewConversation(user)}
                        >
                            {getAvatar(`${user.first_name} ${user.last_name}`)}
                            <div className="tech-content">
                                <span className="name">{`${user.first_name} ${user.last_name}`}</span>
                                <span className="email">{user.email}</span>
                            </div>
                            <span className="role">{user.role}</span>
                        </CheckboxContainer>
                    ))}
            </ConversationList>
        </>
    );
};

export default ContactSelection;
