import React from 'react';
import { RaRecord, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/browser';

export const TimeZoneDateField = ({
    source,
    record = null,
    user_event = false,
}: {
    source: string;
    record?: null | RaRecord;
    user_event?: boolean;
}) => {
    let validDate = false;
    let finalDate = '';
    let sourceTime = null;
    try {
        if (!record) {
            record = useRecordContext() as RaRecord;
        }
        const timeZone = user_event
            ? record['site_time_region']
            : record.time_region ?? 'UTC';

        sourceTime = record[source];

        if (!record || sourceTime === null) {
            return (
                <Typography variant="body1" fontSize="14px">
                    No Date Available
                </Typography>
            );
        }

        finalDate = DateTime.fromISO(sourceTime, {
            zone: timeZone,
        })
            .setLocale('en-CA')
            .toFormat('yyyy-MM-dd, t ZZZZ');

        validDate = DateTime.fromISO(sourceTime).isValid;
        if (validDate) {
            return (
                <Typography variant="body1" fontSize="14px">
                    {finalDate}
                </Typography>
            );
        } else {
            return (
                <Typography variant="body1" fontSize="14px">
                    Error with Date Display
                </Typography>
            );
        }
    } catch (err) {
        Sentry.captureException(err);
        return (
            <Typography variant="body1" fontSize="14px">
                Error
            </Typography>
        );
    }
};
