import React, { useState, useEffect } from 'react';
import { Button } from './styles';
import { Sidebar } from '@chatscope/chat-ui-kit-react';
import { useDataProvider } from 'react-admin';

import ContactSelection from './ContactSelection';
import ConversationSelection from './ConversationSelection';
import { User } from '../../types';

export const SideBar: React.FC = () => {
    const [isCreatingNewChat, setIsCreatingNewChat] = useState(false);

    const { customGetAll } = useDataProvider();
    const [chatContacts, setContacts] = useState<User[]>([]);

    useEffect(() => {
        customGetAll('userlist', {
            filter: `{ "is_active": "true" }`,
        }).then(({ data }: { data: User[] }) => {
            setContacts(data);
        });
    }, []);

    return (
        <Sidebar position="left" scrollable={true}>
            <Button onClick={() => setIsCreatingNewChat((n) => !n)}>
                {isCreatingNewChat ? <>X</> : <>Start new chat</>}
            </Button>

            {isCreatingNewChat ? (
                <ContactSelection
                    setIsCreatingNewChat={setIsCreatingNewChat}
                    chatContacts={chatContacts}
                />
            ) : (
                <ConversationSelection />
            )}
        </Sidebar>
    );
};
