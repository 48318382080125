import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@material-ui/core';

interface InfoIcon {
    resource: any;
    record: any;
}

export const InfoIcon: React.FC<InfoIcon> = ({ resource, record }) => {
    const { id, status } = record;
    const DisplayByContext: any = {
        project: {
            Id: id,
            Status: status,
            Code: record.project_code,
        },
        site: {
            Id: id,
            Status: status,
            Foreign_Key: record.project_fk,
        },
        operation: {
            Id: id,
            Status: status,
            Foreign_Key: record.site_fk,
        },
        task: {
            Id: id,
            Status: status,
            Foreign_Key: record.operation_fk,
        },
        operation_template: {
            Id: id,
        },
        task_template: {
            Id: id,
            Foreign_Key: record.tpl_op_fk,
        },
        user: {
            Id: id,
        },
        evaluation: {
            Id: id,
            Foreign_Key: record.site_fk,
        },
    };

    const GetContext: React.FC = () => {
        const displayFields: { [key: string]: string } =
            DisplayByContext[resource];
        return (
            <h2>
                {Object.entries(displayFields).map(([key, value], i) => (
                    <div key={`${key}-${i}`}>
                        {`${key}: ${value}`}
                        <br />
                    </div>
                ))}
            </h2>
        );
    };

    return (
        <Tooltip title={<GetContext />}>
            <InfoOutlinedIcon sx={{ paddingTop: 1 }} />
        </Tooltip>
    );
};
