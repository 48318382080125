import { BarChart, Bar, XAxis, YAxis, Tooltip, Label, Legend } from 'recharts';
import { useListContext } from 'ra-core';
import React, { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { eachDayOfInterval, format, isSameDay, parseISO } from 'date-fns';
import { DateFilteredReferenceManyContext } from '../DateFilteredReferenceMany';

export const BarGraph: React.FunctionComponent = () => {
    const translate = useTranslate();
    const { data } = useListContext();
    const { timeSpan, filterValues } = useContext(
        DateFilteredReferenceManyContext,
    );

    const getReportData = () => {
        const daysInWeek = eachDayOfInterval({
            start: new Date(filterValues.start + '\n'),
            end: new Date(filterValues.end + '\n'),
        });

        const work: number[] = [];
        const breaks: number[] = [];

        daysInWeek.forEach((dateInGraph) => {
            const dailySum: { [id: string]: number } = {
                Work: 0,
                Break: 0,
            };
            data.forEach((reportInstance) => {
                if (
                    isSameDay(parseISO(reportInstance.start_time), dateInGraph)
                ) {
                    const eventType: string = reportInstance.event_type;
                    if (dailySum[eventType] === undefined) {
                        throw new Error('Invalid Event Type!');
                    } else {
                        dailySum[eventType] +=
                            reportInstance.duration_minutes / 60;
                    }
                }
            });

            work.push(Math.round(dailySum['Work'] * 10) / 10);
            breaks.push(Math.round(dailySum['Break'] * 10) / 10);
        });
        return daysInWeek.map((date: Date, index) => ({
            Date: format(date, 'yyyy-MMM-dd'),
            Work: work[index],
            Breaks: breaks[index],
        }));
    };

    const graphTitle = () => translate(`reporting.graph.titles.${timeSpan}`);

    return (
        <>
            <BarChart
                width={1000}
                height={600}
                data={getReportData() ?? []}
                margin={{
                    top: 50,
                    right: 30,
                    left: 20,
                    bottom: 20,
                }}
            >
                <text
                    x={1000 / 2}
                    y={30}
                    fillOpacity={0.7}
                    textAnchor="middle"
                    dominantBaseline="central"
                >
                    <tspan fontSize="20">{graphTitle()}</tspan>
                </text>
                <XAxis dataKey="Date">
                    <Label
                        fontSize="20"
                        value={translate('reporting.graph.labels.x_axis')}
                        offset={-25}
                        position="insideBottom"
                    />
                </XAxis>
                <YAxis>
                    <Label
                        fontSize="20"
                        value={translate('reporting.graph.labels.y_axis')}
                        position="insideLeft"
                        offset={-10}
                        angle={-90}
                    />
                </YAxis>
                <Tooltip />
                <Legend fontSize="20" align="left" />
                <Bar dataKey="Work" fill="#4f3cc9" />
                <Bar dataKey="Breaks" fill="#ff5349" />
            </BarChart>
        </>
    );
};
