import React, { FC, useEffect, useState } from 'react';
import { CheckBox, CheckBoxOutlineBlank, Download } from '@mui/icons-material';
import { apiUrl } from '../../dataProvider/index';
import { Task, TaskPhoto } from '../../types';
import { Box, Checkbox, Grid, Paper } from '@mui/material';
import { saveAs } from 'file-saver';
import { ImageModal } from '../../components';
import {
    Button,
    Identifier,
    ImageInput,
    ImageField,
    useRecordContext,
} from 'react-admin';
import { useUploadButton } from './';

type TransformedPhoto = {
    index: Identifier;
    url: string;
    thumb_url: string;
};

export const TaskImageField: FC = () => {
    const record = useRecordContext<Task>();
    if (!record) return null;

    const [photos, setPhotos] = useState<TaskPhoto[]>([]);

    useEffect(() => {
        if (record.photos) setPhotos(record.photos);
    }, [record]);

    const [transformedPhotos, setTransformedPhotos] = useState<
        TransformedPhoto[]
    >([] as TransformedPhoto[]);

    useEffect(() => {
        const urlPhotoList: {
            index: Identifier;
            url: string;
            thumb_url: string;
        }[] = [];

        photos.forEach((photo: TaskPhoto) => {
            let url = photo.url;
            let thumb_url = photo.thumb_url;
            if (thumb_url === null) thumb_url = url;
            if (url.indexOf('http') === -1) {
                url = apiUrl + '/' + url;
                thumb_url = apiUrl + '/' + thumb_url;
            }
            urlPhotoList.push({ index: photo.id, url, thumb_url });
        });
        setTransformedPhotos(urlPhotoList);
    }, [photos]);

    const [open, setOpen] = useState<boolean>(false);
    const [dialogPhoto, setDialogPhoto] = useState<TransformedPhoto>();
    const [photosSelected, setPhotosSelected] = useState<TransformedPhoto[]>(
        [],
    );
    const [selectedImage, setSelectedImage] = useState(0);

    const handleOpen = (photo: TransformedPhoto, p: number) => {
        setDialogPhoto(photo);
        setOpen(true);
        setSelectedImage(p);
    };

    const handleDownload = () => {
        if (open)
            saveAs(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore:next-line
                dialogPhoto?.url,
                dialogPhoto?.url.split('/').pop(),
            );
        else
            photosSelected.map((photo: TransformedPhoto) => {
                saveAs(photo.url, photo.url.split('/').pop());
            });
    };

    const handleSelect = (event: any, photo: TransformedPhoto) => {
        event.stopPropagation();
        setPhotosSelected((prevPhotosSelected) => {
            if (event.target.checked) return [...prevPhotosSelected, photo];
            else
                return prevPhotosSelected.filter(
                    (p) => JSON.stringify(photo) !== JSON.stringify(p),
                );
        });
    };

    const handleSelectAll = () => {
        if (photosSelected.length === transformedPhotos.length)
            setPhotosSelected([]);
        else setPhotosSelected(transformedPhotos);
    };

    const headerInformation = (
        <Box
            sx={{
                marginLeft: '10px',
                paddingLeft: '5px',
                marginTop: '10px',
            }}
        >
            <Button autoFocus onClick={handleDownload} label="Download">
                <Download sx={{ marginLeft: '10px' }} />
            </Button>
        </Box>
    );
    const UploadButton = useUploadButton(setPhotos);

    return (
        <>
            <Grid
                container
                sx={{
                    gap: 2,
                    marginBottom: '10px',
                    marginRight: '10px',
                }}
            >
                {UploadButton}
                <Button
                    onClick={handleSelectAll}
                    variant="outlined"
                    color="primary"
                    label={
                        photosSelected.length === transformedPhotos.length
                            ? 'Deselect All'
                            : 'Select All'
                    }
                    sx={{
                        minWidth: '145px',
                    }}
                    size="medium"
                />
                {photosSelected.length > 0 && (
                    <Button
                        autoFocus
                        onClick={handleDownload}
                        size="medium"
                        label="Download"
                    >
                        <Download />
                    </Button>
                )}
            </Grid>
            <Grid
                container
                sx={{
                    gap: 2,
                }}
            >
                {transformedPhotos.map((photo: TransformedPhoto, p: number) => (
                    <Paper
                        elevation={2}
                        sx={styles.paper}
                        key={photo.index}
                        onClick={() => handleOpen(photo, p)}
                    >
                        <Checkbox
                            sx={styles.checkbox}
                            checked={photosSelected.some(
                                (p) =>
                                    JSON.stringify(photo) === JSON.stringify(p),
                            )}
                            onClick={(event) => handleSelect(event, photo)}
                        />
                        <img
                            src={photo.thumb_url}
                            alt={`Photo ${p}`}
                            style={{ ...styles.img, objectFit: 'cover' }}
                            crossOrigin="anonymous"
                        />
                    </Paper>
                ))}
            </Grid>

            <ImageModal
                urlPhotoList={transformedPhotos}
                open={open}
                setOpen={setOpen}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                ModalHeader={headerInformation}
                ModalFooter={<></>}
            />
        </>
    );
};

const styles = {
    checkbox: {
        position: 'absolute',
        top: 0,
        left: 0,
        color: 'primary.main',
        '&.Mui-checked': {
            color: 'primary.main',
        },
        backgroundColor: 'rgba(255, 255, 255, 0.7)',

        '&:hover .MuiSvgIcon-root': {
            fill: 'rgba(0, 0, 255, 0.4)',
        },
        borderRadius: '2px',
        padding: '0px',
        margin: '7px',
        zIndex: 2,
    },
    paper: {
        width: '320px',
        height: '240px',
        cursor: 'pointer',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
            img: {
                transform: 'scale(1.05)',
                opacity: '0.7',
            },
        },
        '&:hover .MuiCheckbox-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
        },
        '.MuiCheckbox-root:hover + img': {
            transform: 'none',
            opacity: '1',
        },
    },
    img: {
        width: '100%',
        height: '100%',
        transition: 'transform 0.3s ease, opacity 0.3s ease',
        loading: 'lazy',
    },
};
