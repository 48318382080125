import {
    Autocomplete,
    AutocompleteProps,
    CircularProgress,
    Grid,
    IconButton,
    TextField,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import {
    Identifier,
    RaRecord,
    useNotify,
    useRecordContext,
    useTranslate,
} from 'react-admin';

export type EnhancedSingleAutoCompleteProps<T extends RaRecord> = {
    action: (arrayIds: string, recordId: Identifier | null) => Promise<void>;
    assignedOptions?: T[];
    getDefaultValue?: (record: RaRecord) => T | null;
    labelResource: (record: T) => string;
    loadResource: (recordId: Identifier) => Promise<any>;
    resource: string;
} & Omit<
    AutocompleteProps<T, false, false, false>,
    'options' | 'renderInput' | 'multiple'
>;

export const EnhancedSingleAutoComplete = <T extends RaRecord>({
    action,
    assignedOptions = [],
    getDefaultValue = () => null,
    labelResource,
    loadResource,
    resource,
    ...rest
}: EnhancedSingleAutoCompleteProps<T>) => {
    const [options, setOptions] = useState<T[]>(assignedOptions);
    const [value, setValue] = useState<T | null>(null);
    const [listLoading, setListLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);

    const notify = useNotify();
    const record = useRecordContext();
    const translate = useTranslate();
    const refreshList = async () => {
        setListLoading(true);
        setValue(getDefaultValue(record));
        try {
            if (assignedOptions.length === 0) {
                const res = await loadResource(record.id);
                setOptions(res.data);
            }
        } catch (error) {
            notify(
                `resources.${resource}.autocomplete.notification.getFailed`,
                {
                    type: 'info',
                },
            );
        }
        setListLoading(false);
    };

    useEffect(() => {
        refreshList();
    }, [record]);

    const submit = (input: T | null) => {
        setActionLoading(true);
        action(record.id.toString(), input ? input.id : null)
            .then(() => {
                notify(`resources.${resource}.notification.saved`, {
                    type: 'info',
                });
            })
            .catch(() => {
                notify(
                    `resources.${resource}.autocomplete.notification.actionFailed`,
                    {
                        type: 'error',
                    },
                );
            })
            .finally(() => {
                setActionLoading(false);
            });
    };

    return (
        <Grid container spacing={2} alignItems="center" direction="row">
            <Grid item sx={{ width: '50%' }}>
                <Autocomplete<T, false, false, false>
                    {...rest}
                    options={options}
                    value={value}
                    disabled={actionLoading}
                    getOptionLabel={(option) => labelResource(option)}
                    renderOption={(props, option) => (
                        <li {...props}>{labelResource(option)}</li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={translate(
                                `resources.${resource}.autocomplete.label`,
                            )}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {(actionLoading || listLoading) && (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        )}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                    onChange={(_event, value) => {
                        setValue(value);
                        submit(value);
                    }}
                />
            </Grid>
            <Grid item>
                <IconButton
                    disabled={actionLoading || listLoading}
                    onClick={refreshList}
                >
                    <Refresh />
                </IconButton>
            </Grid>
        </Grid>
    );
};
