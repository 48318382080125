import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, deleteToken } from 'firebase/messaging';
import { Client } from '@twilio/conversations';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export async function requestNotificationPermission(
    client: Client,
): Promise<string | void> {
    console.log('Requesting permission...');
    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(firebaseApp);
    try {
        const currentToken = await getToken(messaging, {
            vapidKey: vapidKey,
        });
        console.log('currentToken', currentToken);
        if (currentToken && client !== null) {
            await client.setPushRegistrationId('fcm', currentToken);
        }
    } catch (err) {
        console.error('An error occurred while retrieving token. ', err);
    }
}

// Listen to service worker messages sent via postMessage()
if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('message', (event) => {
        if (!event.data.action) {
            return;
        }

        switch (event.data.action) {
            case 'redirect-from-notificationclick':
                window.location.href = event.data.url;
                break;
            // no default
        }
    });
}

// deleteTokenFromFirebase deletes the FCM token from Firebase cloud messaging.
export async function deleteTokenFromFirebase() {
    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(firebaseApp);
    try {
        await getToken(messaging, { vapidKey: vapidKey })
            .then((currentToken) => {
                deleteToken(messaging).then(() => {
                    console.log('FCM Token Deleted.', currentToken);
                });
            })
            .catch((error: any) => {
                console.log(
                    'An error occurred while deleting FCM token.',
                    error,
                );
            });
    } catch (error) {
        console.log('An error occurred while deleting FCM token.', error);
    }
}
