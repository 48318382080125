import React from 'react';
import { format } from 'date-fns';
import { Message } from '@chatscope/chat-ui-kit-react';
import { Client, Message as TwilioMessage } from '@twilio/conversations';

import { getAvatar } from '../components/ChatAvatar';
import Linkify from 'linkify-react';
import DOMPurify from 'dompurify';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import { useNavigate } from 'react-router-dom';
interface TextMessageProps {
    message: TwilioMessage;
    client: Client;
    friendly_map: Record<string, string>;
}

interface Attributes {
    type?: string;
    sessionName?: string;
    callType?: string;
}

const TextMessage: React.FC<TextMessageProps> = ({
    message,
    client,
    friendly_map,
}) => {
    const attributes = message.attributes as Attributes;
    const navigate = useNavigate();

    return (
        <div>
            <Message
                model={{
                    sentTime: message.dateCreated
                        ? message.dateCreated.toDateString()
                        : '',
                    sender: message.author
                        ? friendly_map[message.author] || message.author
                        : 'Unkown',
                    direction:
                        message.author === client.user.identity
                            ? 'outgoing'
                            : 'incoming',
                    position: 'single',
                }}
            >
                {message.author &&
                    getAvatar(
                        (
                            message.attributes as {
                                authorFriendlyName: string;
                            }
                        )?.authorFriendlyName || message.author
                            ? friendly_map[message.author] || message.author
                            : 'Unkown',
                    )}
                <Message.Header>
                    {message.dateCreated
                        ? format(message.dateCreated, 'PPpp')
                        : ''}
                </Message.Header>

                {attributes?.type === 'video_invite' ? (
                    <Message.CustomContent>
                        <div
                            onClick={() => {
                                window.open(
                                    `#/video-call/${attributes.sessionName}/${attributes.callType}`,
                                    'Video Call',
                                    'height=768,width=1024,menubar=no,toolbar=no,location=no,status=no',
                                );
                                // navigate(
                                //     `/video-call/${attributes.sessionName}`,
                                // );
                            }}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <h4 style={{ marginRight: 16 }}>Join the Call</h4>
                            <VideoCameraFrontOutlinedIcon fontSize="large" />
                        </div>
                    </Message.CustomContent>
                ) : (
                    <Message.TextContent>
                        <Linkify
                            options={{
                                target: '_blank',
                                rel: 'noopener noreferrer',
                            }}
                        >
                            {DOMPurify.sanitize(message.body || '')}
                        </Linkify>
                    </Message.TextContent>
                )}
            </Message>
        </div>
    );
};

export default TextMessage;
