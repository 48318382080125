import React from 'react';
import { Avatar as UIAvatar } from '@chatscope/chat-ui-kit-react';
import Avatar from 'react-avatar';

const AvatarComponent = (name: string): React.ReactNode => {
    // @ts-expect-error: component from lib is not JSX
    return <Avatar name={name} size="40" round maxInitials={2} />;
};

export const getAvatar = (name: string): React.ReactNode => {
    return <UIAvatar>{AvatarComponent(name)}</UIAvatar>;
};
