import { stringify } from 'query-string';
import { apiUrl, httpClient } from './client';

export const ReportMethods = {
    getUserEvent: (total: boolean, params: any) => {
        let query: { sort?: any; filter?: any } = {};
        if (params.sort) {
            const { field, order } = params.sort;
            query.sort = JSON.stringify([field, order]);
        }
        query.filter = JSON.stringify(
            typeof params.filter === 'string'
                ? JSON.parse(params.filter)
                : params.filter,
        );
        const url = `${apiUrl}/report/user_event${
            total && '/total'
        }?${stringify(query)}`;
        return httpClient(url).then((response) => ({
            data: response.json,
        }));
    },
};
