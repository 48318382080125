import React from 'react';
import { WithRecord } from 'react-admin';
import { Box, Typography } from '@mui/material';

export const TaskNote = () => (
    <>
        <WithRecord
            render={(record) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingBottom: 1,
                        paddingLeft: 1,
                    }}
                >
                    <Typography sx={{ fontWeight: '500' }}>Note: </Typography>
                    <Typography sx={{ fontWeight: '400', marginLeft: 1 }}>
                        {record.note}
                    </Typography>
                </Box>
            )}
        />
    </>
);
