import React from 'react';
import { Chip } from '@mui/material';
import {
    CommonInputProps,
    ResettableTextFieldProps,
    useTranslate,
} from 'react-admin';

export const QuickFilter = (props: QuickFilterProps) => {
    const {
        source,
        resource,
        label = `resources.${resource}.fields.${source}`,
    } = props;
    const translate = useTranslate();
    return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

export type QuickFilterProps = CommonInputProps &
    Omit<ResettableTextFieldProps, 'label' | 'helperText'> & { label?: string };
