import EngineeringIcon from '@mui/icons-material/Engineering';
import TaskEdit from './TaskEdit';
import { NotFound } from 'react-admin';

export const tasks = {
    create: NotFound,
    edit: TaskEdit,
    icon: EngineeringIcon,
    list: NotFound,
};
