import React, { useEffect, useState } from 'react';
import {
    DeleteButton,
    EditButton,
    ListButton,
    RefreshButton,
    Show,
    TabbedShowLayout,
    TopToolbar,
    useRecordContext,
    Button,
    useNotify,
    useGetIdentity,
    useDataProvider,
    useTranslate,
} from 'react-admin';
import { Project, Site, User } from '../../types';
import { InfoIcon } from '../../components';
import { DateFilteredList } from '../../components/DateFilteredList';
import CheckInList from './CheckInList';
import { BarGraph } from '../../components/BarGraph';
import { DateFilteredReferenceMany } from '../../components/DateFilteredReferenceMany';
import { useNavigate } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import { LocalConversation, useTwilio } from '../../contexts/TwilioProvider';
import { Conversation } from '@twilio/conversations';
import { Tooltip } from '@material-ui/core';
import CircleIcon from '@mui/icons-material/Circle';

const UserTitle = () => {
    const record = useRecordContext<User>();
    const [active, setActive] = useState(false);

    const { customGetAll } = useDataProvider();

    useEffect(() => {
        const controller = new AbortController();

        if (!record) return;

        customGetAll(`/user/${record.id}/projects`).then(
            ({ data }: { data: Project[] }) => {
                data.forEach((p: Project) => {
                    p.sites.forEach((s: Site) => {
                        customGetAll(`/site/${s.id}`).then(
                            ({ data }: { data: Site }) => {
                                if (data.checking_ids.includes(record.id))
                                    setActive(true);
                            },
                        );
                    });
                });
            },
        );
        return () => {
            controller.abort();
        };
    }, [record]);

    return !record ? (
        <></>
    ) : (
        <span>
            <StatusIcon active={active} /> User - {record.first_name}{' '}
            {record.last_name} <InfoIcon resource={'user'} record={record} />
        </span>
    );
};

const StatusIcon = ({ active }: { active: boolean }) => {
    const translate = useTranslate();
    const [color, setColor] = useState('red');

    useEffect(() => {
        if (active) {
            setColor('green');
        } else setColor('red');
    }, [active]);

    return (
        <Tooltip title={<h2>{translate(`status.technician.${color}`)}</h2>}>
            <CircleIcon sx={{ color: color }} />
        </Tooltip>
    );
};

const ChatButton = () => {
    const notify = useNotify();

    const record = useRecordContext<User>();
    const { data } = useGetIdentity();
    const { conversations, setLocalConversation } = useTwilio() as {
        conversations: Conversation[];
        setLocalConversation: React.Dispatch<LocalConversation>;
    };

    const navigate = useNavigate();

    const navigateToChat = async () => {
        if (record.email === data?.email) {
            notify(`Can't create a conversation with yourself`, {
                type: 'error',
            });
            return;
        }

        const selectedPeople = [record.email, data?.email].sort();

        for (const conversation of conversations)
            if (conversation.uniqueName == selectedPeople.join('')) {
                navigate(`/chat/${conversation.sid}`);
                return;
            }

        setLocalConversation({
            uniqueName: selectedPeople.join(''),
            selectedPeople: selectedPeople,
            otherPeople: [`${record.first_name} ${record.last_name}`],
            type: 'user',
        });

        navigate(`/chat`);
    };

    return (
        <Button onClick={navigateToChat} label="Chat">
            <ChatIcon />
        </Button>
    );
};

const UserShowActions = () => (
    <TopToolbar>
        <ChatButton />
        <RefreshButton />
        <ListButton />
        <EditButton />
        <DeleteButton />
    </TopToolbar>
);

export const UserShow: React.FunctionComponent = () => {
    // TOODO: review the state in both tabs and add query paramter in uri
    const [timeSpan, setTimeSpan] = useState<string>('week');

    const [filterValues, setFilterValues] = useState<
        | {
              start: string;
              end: string;
          }
        | undefined
    >(undefined);

    const commonProps = {
        timeSpan,
        setTimeSpan,
        filterValues,
        setFilterValues,
    };

    return (
        <Show title={<UserTitle />} actions={<UserShowActions />}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Check-In Status">
                    <DateFilteredReferenceMany
                        label=" "
                        reference="sitecheck"
                        target="user_fk"
                        {...commonProps}
                    >
                        <CheckInList />
                    </DateFilteredReferenceMany>
                </TabbedShowLayout.Tab>

                <TabbedShowLayout.Tab label="Time Summary">
                    <DateFilteredReferenceMany
                        label="Report"
                        reference="report/user_event"
                        target="user_fk"
                        {...commonProps}
                    >
                        <BarGraph />
                    </DateFilteredReferenceMany>
                </TabbedShowLayout.Tab>

                <TabbedShowLayout.Tab label="Time Breakdown">
                    <DateFilteredReferenceMany
                        label="Report"
                        reference="report/user_event"
                        target="user_fk"
                        {...commonProps}
                    >
                        <DateFilteredList />
                    </DateFilteredReferenceMany>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
};
