import React, { MouseEvent } from 'react';
import {
    Button,
    Link,
    List,
    TextField,
    TextInput,
    ReferenceField,
    DateField,
    FunctionField,
    DatagridConfigurable,
    useGetIdentity,
    RaRecord,
} from 'react-admin';
import { EnhancedEmpty, EnhancedPagination, QuickFilter } from '../components';
import { DefaultPerPage, dateOptions, defaultLocale } from '../constants';
import SummarizeIcon from '@mui/icons-material/Summarize';

export const SiteEvaluationList: React.FC = () => {
    const { data } = useGetIdentity();
    if (data === undefined || data.id === undefined) return null;

    const PrintButton = (record: RaRecord) => {
        const evalId = record?.id;

        const handlereportClick = (e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
        };

        return (
            <Link to={`/closeout/?id=${evalId}`} target="_blank">
                <Button
                    onClick={handlereportClick}
                    startIcon={<SummarizeIcon />}
                    label={'resources.evaluation.actions.print_report'}
                />
            </Link>
        );
    };

    const siteFilters = [
        <QuickFilter
            label="resources.evaluation.filters.pm_fk"
            source="pm_fk"
            defaultValue={data?.id}
            key={'evaluation-filters-01'}
        />,
        <TextInput
            key={'evaluation - filters - 02'}
            source="title"
            label="Site Title"
            placeholder={`site title`}
        />,
        <TextInput
            key={'evaluation-filters-03'}
            source="address"
            label="Site Address"
            placeholder={`site's address`}
        />,
        <TextInput
            key={'evaluation-filters-04'}
            source="project_code"
            label="Project Code"
            placeholder={`Project Code`}
        />,
    ];

    return (
        <>
            <List
                filters={siteFilters}
                filterDefaultValues={{ pm_fk: data?.id }}
                sort={{ field: 'created_on', order: 'DESC' }}
                pagination={<EnhancedPagination />}
                perPage={DefaultPerPage}
                empty={<EnhancedEmpty resource="evaluation" />}
                title={
                    <>
                        <span>Site Evaluation</span>&ensp;
                        <span style={{ fontSize: '11px' }}>
                            (This page displays all generated Site Evaluation
                            Reports. To create a new report, select ‘CREATE’ on
                            the top right.)
                        </span>
                    </>
                }
            >
                <DatagridConfigurable rowClick="edit" bulkActionButtons={false}>
                    <ReferenceField
                        label="Project Code"
                        source="site.project_fk"
                        reference="projectinfo"
                        sortable={false}
                    >
                        <TextField source="project_code" />
                    </ReferenceField>
                    <TextField label="Site Title" source="site.title" />
                    <TextField
                        label="Site Address"
                        source="site.address"
                        sortable={false}
                    />
                    <ReferenceField
                        label="Project Manager/Coordinator"
                        source="site.project_fk"
                        reference="projectinfo"
                        sortable={false}
                    >
                        <TextField source="project_manager.display_name" />
                    </ReferenceField>
                    <DateField
                        source="created_on"
                        locales={defaultLocale}
                        options={dateOptions}
                    />
                    <FunctionField
                        label={'resources.evaluation.fields.print_button'}
                        render={(record: RaRecord) => PrintButton(record)}
                    />
                </DatagridConfigurable>
            </List>
        </>
    );
};
