import React from 'react';
import {
    Button,
    ButtonProps,
    CreateParams,
    UseCreateMutateParams,
    useCreate,
    useNotify,
    useRecordContext,
    useRedirect,
} from 'react-admin';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useBlocker } from '../../contexts/BlockerProvider';
import { LinkWrapper } from '../LinkWrapper';

export type ListCreateButtonProps = {
    resource:
        | 'site'
        | 'task'
        | 'template_task'
        | 'operation'
        | 'project'
        | 'template_operation'
        | 'user'
        | 'evaluation'
        | 'provider';
} & ButtonProps;

export const ListCreateButton: React.FC<ListCreateButtonProps> = ({
    resource,
    ...rest
}) => {
    const [create, { isLoading }] = useCreate(resource);
    const notify = useNotify();
    const redirect = useRedirect();
    const record = useRecordContext();
    const getDefault = (resource: string): object => {
        switch (resource) {
            case 'site': {
                return {
                    project_fk: record.id,
                } as Partial<CreateParams<any>>;
            }
            case 'task': {
                return {
                    operation_fk: record.id,
                    // project_fk: record.project_fk,
                    // site_fk: record.site_fk,
                } as Partial<CreateParams<any>>;
            }
            case 'template_task': {
                return {
                    tpl_op_fk: record.id,
                } as Partial<CreateParams<any>>;
            }
            case 'operation': {
                return {
                    site_fk: record.id,
                    // project_fk: record.project_fk,
                } as Partial<CreateParams<any>>;
            }
            case 'project': {
                return {} as Partial<CreateParams<any>>;
            }
            case 'template_operation': {
                return {} as Partial<CreateParams<any>>;
            }
            case 'user': {
                return {} as Partial<CreateParams<any>>;
            }
            case 'provider': {
                return {} as Partial<CreateParams<any>>;
            }
            case 'evaluation': {
                return {} as Partial<CreateParams<any>>;
            }
            default: {
                return {};
            }
        }
    };
    const mutationOptions = {
        onSuccess: (
            data: any,
            { resource }: Partial<UseCreateMutateParams<any>>,
        ): void => {
            notify(`resources.${resource}.notification.created`); //TODO I18n error thrown in console
            redirect('edit', resource, data?.id, data);
        },
    };
    const { confirmLeave } = useBlocker();

    // use this function when the create is part of Edit ReferenceMany's List
    const handleSublistClick = async () => {
        const toBlock = await confirmLeave(); //TODO IAS-1250 remove useBlocker, confirmLeave, toBlock once done.
        if (!toBlock) {
            create(
                resource,
                { data: getDefault(resource) },
                { ...mutationOptions },
            );
        }
    };

    // Use this function when the create is in a Create's list
    const handleListClick = async () => {
        create(
            resource,
            { data: getDefault(resource) },
            { ...mutationOptions },
        );
    };

    const finalClick =
        Object.keys(getDefault(resource)).length === 0
            ? handleListClick
            : handleSublistClick;

    return (
        <Button
            {...rest}
            disabled={isLoading}
            onClick={() => finalClick()}
            label={
                resource === 'template_task'
                    ? `Create Task`
                    : resource === 'template_operation'
                    ? `Create Operation`
                    : `Create ${resource}`
            }
            startIcon={<AddCircleOutlineIcon />}
        />
    );
};
