import React from 'react';
import { BooleanInput, NumberInput, required } from 'react-admin';
import { FormDialog } from '../../FormDialog';
import { Grid } from '@mui/material';
interface CloneModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloneModal: React.FC<CloneModalProps> = ({ isOpen, setIsOpen }) => {
    return (
        <FormDialog
            isOpen={isOpen}
            title={'actions.copy.title'}
            onClose={setIsOpen}
        >
            <NumberInput
                source="count"
                label="resources.site.fields.count"
                fullWidth
                validate={required()}
                defaultValue={1}
                min={1}
                max={99}
                step={1}
            />
            <BooleanInput
                source="includeOperations"
                label="resources.site.fields.include_operations"
            />
        </FormDialog>
    );
};

export default CloneModal;
