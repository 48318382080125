import * as React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { DefaultCreateToolbar } from '../components';

export const ProviderCreate = () => {
    return (
        <Create>
            <SimpleForm toolbar={<DefaultCreateToolbar resource="provider" />}>
                <TextInput source="name" label="resources.provider.name" />
            </SimpleForm>
        </Create>
    );
};
