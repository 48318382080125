import styled from 'styled-components';
import { Button as CreateChatButton } from '@chatscope/chat-ui-kit-react';

export const Button = styled(CreateChatButton)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 0;
    color: #000;
    background-color: #fff;
    border: 1px solid #000;

    @media (max-width: 768px) {
        border: none;
    }
`;

export const SelectButton = styled(CreateChatButton)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: 0 0.5rem;
    padding: 0.5rem 0;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    position: sticky;
    bottom: 0;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 0 0;
    padding: 10px 0px 10px 10px;
    &:hover {
        background-color: #f0f0f0; // Example hover effect
    }

    .tech-content {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        span {
            font-size: 13px;
        }
    }

    .role {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 14px;
        align-self: right;
        right: 0;
        position: absolute;
    }
`;
