import React, { FunctionComponent, ReactElement } from 'react';
import { Create, CreateProps, useNotify, useRedirect } from 'react-admin';
import { useNavigate } from 'react-router';

interface SublistCreatetProps extends CreateProps {
    children: ReactElement;
}

export const SublistCreate: FunctionComponent<SublistCreatetProps> = ({
    resource,
    title,
    children,
    ...rest
}) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = async () => {
        notify(`resources.${resource}.notification.saved`, {
            type: 'info',
            messageArgs: { resources: { resource } },
            undoable: true,
        });
        redirect('list', resource);
    };

    return !children ? (
        <></>
    ) : (
        <Create {...rest} mutationOptions={{ onSuccess }} title={title}>
            {children}
        </Create>
    );
};
