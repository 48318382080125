import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { TemplateTaskEdit } from './TemplateTaskEdit';
import { NotFound } from 'react-admin';

export default {
    create: NotFound,
    edit: TemplateTaskEdit,
    icon: BusinessCenterIcon,
    list: NotFound,
};
