import React, { useState, useMemo } from 'react';
import {
    Conversation as ChatscopeConversation,
    ConversationList,
    Search,
    AvatarGroup,
} from '@chatscope/chat-ui-kit-react';

import { Conversation } from '@twilio/conversations';
import { getAvatar } from '../components/ChatAvatar';
import {
    useTwilio,
    ConversationData,
    LocalConversation,
} from '../../contexts/TwilioProvider';
import { useNavigate, useParams } from 'react-router-dom';

const ConversationSelection: React.FC = () => {
    const { conversations, conversationData } = useTwilio() as {
        conversations: Conversation[];
        conversationData: { [key: string]: ConversationData };
        setLocalConversation: React.Dispatch<LocalConversation>;
    };
    const navigate = useNavigate();
    const { conversationId } = useParams();

    const [searchSolo, setSearchSolo] = useState<string>('');
    const [searchGroup, setSearchGroup] = useState<string>('');

    const filteredSoloConversations = useMemo(
        () =>
            conversations.filter((conversation: Conversation) => {
                if (
                    (
                        conversation.attributes as {
                            type: 'user' | 'project' | 'site';
                        }
                    ).type !== 'user'
                )
                    return false;

                return conversationData[conversation.sid]?.userNames
                    .join(', ')
                    .toLowerCase()
                    .includes(searchSolo.toLowerCase());
            }),
        [conversations, searchSolo, conversationData],
    );

    const filteredGroupConversations = useMemo(
        () =>
            conversations.filter((conversation: Conversation) => {
                if (
                    (
                        conversation.attributes as {
                            type: 'user' | 'project' | 'site';
                        }
                    ).type === 'user'
                )
                    return false;

                if (conversation.friendlyName)
                    return conversation.friendlyName
                        .toLowerCase()
                        .includes(searchGroup.toLowerCase());
                return conversationData[conversation.sid]?.userNames
                    .join(', ')
                    .toLowerCase()
                    .includes(searchGroup.toLowerCase());
            }),
        [conversations, searchGroup, conversationData],
    );

    const getConversationInfo = (conversationSid: string) => {
        const info = conversationData[conversationSid];
        if (!info || !info.latestMessage) return 'No latest message';
        if (info.latestMessage.type === 'text') return info.latestMessage.body;
        else return 'Image';
    };

    return (
        <>
            <Search
                value={searchSolo}
                placeholder="Search Conversations..."
                onChange={(e: string) => setSearchSolo(e)}
                onClearClick={() => setSearchSolo('')}
            />
            <ConversationList>
                {filteredSoloConversations.map((conversation: Conversation) => (
                    <ChatscopeConversation
                        key={conversation.uniqueName}
                        name={
                            conversationData[conversation.sid]?.userNames.join(
                                ', ',
                            ) ||
                            conversation.uniqueName ||
                            'No Title'
                        }
                        lastSenderName={
                            conversationData[conversation.sid]?.senderName ||
                            'Unknown'
                        }
                        info={getConversationInfo(conversation.sid)}
                        active={conversation.sid == conversationId}
                        onClick={async () => {
                            await conversation.setAllMessagesRead();
                            navigate(`/chat/${conversation.sid}`);
                        }}
                        unreadCnt={
                            conversation.sid == conversationId
                                ? 0
                                : conversationData[conversation.sid]
                                      ?.unreadCount || 0
                        }
                    >
                        <AvatarGroup size="sm">
                            {conversationData[conversation.sid]?.userNames.map(
                                (name: string) => getAvatar(name),
                            )}
                        </AvatarGroup>
                    </ChatscopeConversation>
                ))}
            </ConversationList>

            <Search
                value={searchGroup}
                placeholder="Search Project/Site"
                onChange={(e: string) => setSearchGroup(e)}
                onClearClick={() => setSearchGroup('')}
            />
            <ConversationList>
                {filteredGroupConversations.map(
                    (conversation: Conversation) => (
                        <ChatscopeConversation
                            key={conversation.uniqueName}
                            name={
                                conversation.friendlyName ||
                                conversationData[
                                    conversation.sid
                                ]?.userNames.join(', ') ||
                                'No Title'
                            }
                            lastSenderName={
                                conversationData[conversation.sid]
                                    ?.senderName || 'Unknown'
                            }
                            info={getConversationInfo(conversation.sid)}
                            active={conversation.sid == conversationId}
                            onClick={async () => {
                                await conversation.setAllMessagesRead();
                                navigate(`/chat/${conversation.sid}`);
                            }}
                            unreadCnt={
                                conversation.sid == conversationId
                                    ? 0
                                    : conversationData[conversation.sid]
                                          ?.unreadCount || 0
                            }
                        >
                            <AvatarGroup size="sm">
                                {getAvatar(conversation.friendlyName || 'GR')}
                            </AvatarGroup>
                        </ChatscopeConversation>
                    ),
                )}
            </ConversationList>
        </>
    );
};

export default ConversationSelection;
