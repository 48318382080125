import { DateTime } from 'luxon';
import { GetListParams, PaginationPayload } from 'react-admin';

export interface GetAllParams extends Omit<GetListParams, 'pagination'> {
    perPage: 'all';
}

type SanitizeFieldType = {
    name: keyof GetListParams | keyof GetAllParams;
    stringify: boolean;
};
type SanitizeInput = GetListParams | GetAllParams;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
/*
 * Returns filters based on roles
 */
export function sanitizeListQueryParams(params: SanitizeInput) {
    if (!params) return { params: {} };
    const sanitized = {};
    if ('filter' in params) {
        Object.assign(sanitized, { filter: JSON.stringify(params.filter) });
    }

    if ('sort' in params) {
        const { field, order } = params.sort;
        Object.assign(sanitized, { sort: JSON.stringify([field, order]) });
    }

    if ('pagination' in params && !('perPage' in params)) {
        const { page, perPage } = params.pagination;
        Object.assign(sanitized, {
            ...params.pagination,
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        });
    }

    return { params: sanitized };
}

export const timeZone = 'America/Edmonton';
export const FORMAT_BACKEND_TIME = "yyyy-MM-dd'T'HH:mm:ssZZZ";

/**
 * This helper function formats the time into a string that
 * is agnostic of time zones. It strips off all time zone
 * information and returns a string instance of the time.
 */
export const formatTime = (
    time: Date | null | string,
    time_region: string | null,
): string => {
    if (time === null || time === '') {
        return '';
    }

    // Time is from the Date Time picker
    if (time instanceof Date) {
        const newTime = DateTime.fromJSDate(time).toISO({
            suppressMilliseconds: true,
            suppressSeconds: true,
            includeOffset: false,
        });
        return newTime === null ? '' : newTime;
    }

    // Time is from the backend, or set by the form value
    if (typeof time === 'string') {
        const newString = DateTime.fromISO(time, { zone: 'UTC' })
            .setZone(time_region ? time_region : 'system')
            .setZone('system', { keepLocalTime: true })
            .toISO({
                suppressMilliseconds: true,
                suppressSeconds: true,
                includeOffset: false,
            });
        return newString === null ? '' : newString;
    }

    return '';
};

/**
 * This helper function converts a date or string time instance
 * into UTC date time string so that the backend receives consistent
 * formatting and standardized time conversions.
 */
export function convertToUTCDateTime(
    time: Date | string,
    time_region: string,
    previous_time_region: string,
): string {
    let newTime;
    let newDateTime;
    // If time is an instance of Date, it is from the date picker
    if (time instanceof Date) {
        newDateTime = DateTime.fromJSDate(time).toISO({
            suppressMilliseconds: true,
            suppressSeconds: true,
            includeOffset: false,
        });
        if (newDateTime === null) newDateTime = '';
        newTime = DateTime.fromISO(newDateTime, {
            zone: time_region ? time_region : 'UTC',
        })
            .toUTC()
            .toFormat(FORMAT_BACKEND_TIME);
        return newTime;
        // Time region has been modified and is in string format
    } else {
        newDateTime = DateTime.fromISO(time, {
            zone: previous_time_region ? previous_time_region : 'UTC',
        }).toISO({
            suppressMilliseconds: true,
            suppressSeconds: true,
            includeOffset: false,
        });
        if (newDateTime === null) newDateTime = '';
        newTime = DateTime.fromISO(newDateTime, {
            zone: previous_time_region,
        })
            .setZone(time_region, { keepLocalTime: true })
            .toUTC()
            .toFormat(FORMAT_BACKEND_TIME);
        return newTime;
    }
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
export const convertFileToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () =>
            resolve({
                reader: reader.result,
                title: file.title,
            });
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });

/**
 * Parse Content-Range header
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Range
 */
export interface ContentRange {
    unit: string;
    start?: number | null;
    end?: number | null;
    size?: number;
}

export function contentRangeHeaderParser(
    header: string | undefined,
): ContentRange | null {
    if (!header) return null;
    const contentRangeRegex = /^(\w+) (\d+)-(\d+)\/(\d+|\*)$/;
    const contentRange = header.match(contentRangeRegex);
    if (!contentRange) return null;
    const [, unit, start, end, size] = contentRange;

    const range = {
        unit,
        start: parseInt(start, 10),
        end: parseInt(end, 10),
        size: size === '*' ? 0 : parseInt(size, 10),
    };

    if (!range.start && !range.end && !range.size) return null;

    return range;
}
