import React from 'react';
import {
    BooleanInput,
    FormDataConsumer,
    SimpleForm,
    TextInput,
    useRecordContext,
    required,
} from 'react-admin';
import { Task } from '../types';
import { TaskImageField } from './components';
import { DefaultEditToolbar, SublistEdit, InfoIcon } from '../components';
import { BooleanNumberToggle } from '../components/BooleanNumberToggle';

const TaskTitle = () => {
    const record = useRecordContext<Task>();
    return record ? (
        <span>
            Task - {record.title}
            <InfoIcon resource={'task'} record={record} />
        </span>
    ) : null;
};

export default function TaskEdit() {
    return (
        <SublistEdit resource="task" title={<TaskTitle />}>
            <TaskEditForm />
        </SublistEdit>
    );
}

function TaskEditForm() {
    // TODO: validate what is the purpose of the FormDataConsume + should Unregister below
    return (
        <SimpleForm
            toolbar={<DefaultEditToolbar resource="task" />}
            shouldUnregister
        >
            <TextInput
                source="title"
                autoFocus
                sx={{ width: '80% ' }}
                resettable
                validate={required()}
            />
            <TextInput
                source="description"
                sx={{ width: '80% ' }}
                multiline
                resettable
            />

            <BooleanNumberToggle
                boolSrc="photo_required"
                boolLabel="resources.task.fields.photo_req"
                numSrc="photo_num"
                numLabel="resources.task.fields.num_photos"
                min={1}
            />
            <BooleanInput source="note_required" label="Note Required?" />

            <TextInput source="note" sx={{ width: '80% ' }} multiline />

            <FormDataConsumer>
                {() => {
                    return (
                        <BooleanInput
                            source="status"
                            label="Completed"
                            format={(value) => {
                                if (value == 'completed') return true;
                            }}
                            parse={(complete) => {
                                if (complete) {
                                    return 'completed';
                                } else {
                                    return 'in progress';
                                }
                            }}
                        />
                    );
                }}
            </FormDataConsumer>
            <TaskImageField />
        </SimpleForm>
    );
}
