import React, { MouseEvent, useState, useEffect } from 'react';
import {
    Button,
    DeleteButton,
    Identifier,
    ReferenceManyField,
    SimpleForm,
    TextField,
    TextInput,
    required,
    useDataProvider,
    useNotify,
    useRecordContext,
    ArrayInput,
    SimpleFormIterator,
    useTranslate,
    regex,
    TopToolbar,
    useGetIdentity,
    useRedirect,
} from 'react-admin';
import { Typography, Grid, InputAdornment } from '@mui/material';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Site, TemplateOperation } from '../types';
import {
    AssignTechnicians,
    DateTimeGrid,
    DefaultEditToolbar,
    DraggableDatagrid,
    DuplicateSelectAutoComplete,
    EnhancedListView,
    InfoIcon,
    LinkWrapper,
    SublistTopToolbar,
    SublistEdit,
} from '../components';
import dataProvider from '../dataProvider/index';
import {
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    ClearButtons,
    ColorButtons,
} from 'ra-input-rich-text';
import useCreateTempGroupChat from '../hooks/useCreateTempGroupChat';
import { Chat, OpenInNew } from '@mui/icons-material';

const Actions = () => {
    const createTempGroupChat = useCreateTempGroupChat();
    const site = useRecordContext<Site>();
    const action = site?.evaluation_id ? 'edit' : 'create';
    const [projectManagerID, setProjectManagerID] = useState('');
    const { data } = useGetIdentity();
    const redirect = useRedirect();

    useEffect(() => {
        if (site)
            dataProvider('project')
                .getOne('project', { id: site.project_fk })
                .then((data) =>
                    setProjectManagerID(data.data.project_manager.id),
                );
    }, [site]);

    const handlereportClick = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        redirect(
            action,
            'evaluation',
            site?.evaluation_id,
            {},
            { record: { site_fk: site?.id } },
        );
    };

    return (
        <TopToolbar>
            {data?.id === projectManagerID && (
                <Button
                    color="primary"
                    onClick={() => {
                        createTempGroupChat('site');
                    }}
                    startIcon={<Chat />}
                    label="resources.site.buttons.chat"
                />
            )}
            <Button
                onClick={handlereportClick}
                startIcon={<OpenInNew />}
                label={`resources.evaluation.actions.${action}_report`}
            />
        </TopToolbar>
    );
};

export const SiteEdit = () => {
    return (
        <SublistEdit
            resource="site"
            title={<SiteTitle />}
            component="div"
            actions={<Actions />}
        >
            <Grid container direction="column">
                <SiteInfoEdit />
                <Technicians />
                <SiteOperations />
            </Grid>
        </SublistEdit>
    );
};

const SiteTitle = () => {
    const record = useRecordContext<Site>();
    return record ? (
        <span>
            Site - {record.address}
            <InfoIcon resource={'site'} record={record} />
        </span>
    ) : null;
};

const ProjectName: React.FC = () => {
    const [projectName, setProjectName] = useState('Loading...');
    const site = useRecordContext<Site>();

    useEffect(() => {
        dataProvider('project')
            .getOne('project', { id: site.project_fk })
            .then((data) => setProjectName(data.data.title));
    }, [site]);

    return (
        <Typography variant="subtitle2" color="grey">
            {projectName && `Project: ${projectName}`}
        </Typography>
    );
};

interface Map {
    address: string;
}

const Map: React.FC<Map> = ({ address }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const site = useRecordContext<Site>();
    const translate = useTranslate();

    const handlePressMap = async (address: string) => {
        await dataProvider
            .googleGet('https://maps.googleapis.com/maps/api/geocode/json', {
                address: address ? address : site.address,
            })
            .then((res: any) => {
                if (res.data.status === 'ZERO_RESULTS') {
                    notify(translate('resources.site.map.failed'), {
                        type: 'error',
                        autoHideDuration: 3000,
                    });
                } else {
                    const latitude = res.data.results[0].geometry.location.lat;
                    const longitude = res.data.results[0].geometry.location.lng;
                    const url =
                        'https://maps.google.com?q=' +
                        latitude +
                        ',' +
                        longitude;
                    window.open(url);
                }
            });
    };

    return (
        <InputAdornment position="start">
            <Button
                color="inherit"
                onClick={() => handlePressMap(address)}
                sx={{
                    paddingRight: 3,
                }}
            >
                <MapOutlinedIcon />
            </Button>
        </InputAdornment>
    );
};

type CustomRichTextInputProps = {
    source: string;
};

const CustomRichTextInput = ({ source }: CustomRichTextInputProps) => {
    return (
        /* disable typecheck for RichTextInput defaultValue
        eslint-disable-next-line @typescript-eslint/ban-ts-comment
        */
        <RichTextInput
            source={source}
            fullWidth
            label={`Site ${source.charAt(0).toUpperCase() + source.slice(1)}`}
            toolbar={
                <RichTextInputToolbar>
                    <LevelSelect />
                    <FormatButtons />
                    <ColorButtons />
                    <AlignmentButtons />
                    <ListButtons />
                    <ClearButtons />
                </RichTextInputToolbar>
            }
        />
    );
};

const SiteInfoEdit = () => {
    const validateURL = regex(
        /^https?:\/\//,
        'URL must begin with https:// or http://',
    );

    const [address, setAddress] = useState('');

    return (
        <Grid
            item
            sx={{
                padding: '1.3em',
                background: 'white',
                border: '2px solid lightgrey',
                borderRadius: '16px',
                paddingBottom: 0,
                marginBottom: '40px',
            }}
        >
            <ProjectName />

            <SimpleForm
                toolbar={<DefaultEditToolbar resource="site" showCopy />}
                sx={{
                    paddingX: 0,
                    '.ProseMirror': {
                        minHeight: '122px',
                    },
                    '.ra-input-scope': {
                        alignItems: 'flex-start',
                    },
                    '.ra-input-description': {
                        alignItems: 'flex-start',
                    },
                }}
            >
                <Grid container spacing={2} sx={{ maxWidth: '80vw' }}>
                    <Grid item sm={11}>
                        <TextInput
                            source="title"
                            autoFocus
                            fullWidth
                            validate={required()}
                        />
                    </Grid>
                    <Grid item sm={11}>
                        <TextInput
                            fullWidth
                            validate={required()}
                            source="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            InputProps={{
                                startAdornment: <Map address={address} />,
                            }}
                        />
                    </Grid>
                    <Grid item sm={11}>
                        <CustomRichTextInput source="description" />
                    </Grid>
                    <Grid item sm={11}>
                        <CustomRichTextInput source="scope" />
                    </Grid>
                </Grid>
                <ArrayInput source="links" margin="normal">
                    <SimpleFormIterator inline disableClear>
                        <TextInput
                            source="title"
                            label="File title"
                            helperText={''}
                            sx={{
                                width: '243px',
                            }}
                        />
                        <TextInput
                            source="uri"
                            helperText={''}
                            type="url"
                            label="link"
                            validate={validateURL}
                            defaultValue="https://"
                            sx={{
                                minWidth: '243px',
                                flex: 1,
                            }}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <DateTimeGrid
                    sources={{
                        start: 'scheduled_start',
                        end: 'scheduled_end',
                        timezone: 'time_region',
                    }}
                    margin="normal"
                />
            </SimpleForm>
        </Grid>
    );
};

const Technicians = () => {
    return (
        <ReferenceManyField target="site_fk" reference="site/technicians">
            <AssignTechnicians resource="site" />
        </ReferenceManyField>
    );
};

const SiteOperations = () => {
    const dataProvider = useDataProvider();

    const notify = useNotify();

    const loadTemplates = async () => {
        return dataProvider.getAll('template_operation', {
            perPage: 'all',
            filter: { is_active: true },
        });
    };

    const copyTemplates = async (arrayIds: string, siteId: Identifier) =>
        dataProvider.customCopyManyTemplates('site', {
            first_id: siteId,
            idArray: arrayIds,
            data: {},
        });

    return (
        <Grid
            item
            sx={{
                padding: '1.3em',
                background: 'white',
                border: '2px solid lightgrey',
                borderRadius: '16px',
                marginBottom: '40px',
            }}
        >
            <Typography
                variant="subtitle2"
                color="grey"
                // sx={{ position: 'absolute' }}
            >
                Site Operations:
            </Typography>
            <DuplicateSelectAutoComplete<TemplateOperation>
                action={copyTemplates}
                labelResource={(o) => o.title}
                loadResource={loadTemplates}
                resource="template_operation"
                sx={{ mt: 2 }}
                clearOnBlur={false}
            />
            <ReferenceManyField
                label="Operations blah"
                reference="operation"
                target="site_fk"
                sort={{ field: 'order', order: 'ASC' }}
            >
                <EnhancedListView
                    title={''}
                    resource="operation"
                    actions={
                        <SublistTopToolbar
                            label="Create Operation +"
                            resource="operation"
                        />
                    }
                >
                    <DraggableDatagrid>
                        <TextField source="status" sortable={false} />
                        <LinkWrapper source="title" sortable={false}>
                            <TextField
                                source="title"
                                sx={{
                                    color: 'black',
                                    textDecoration: 'underline',
                                }}
                            />
                        </LinkWrapper>
                        <TextField source="description" sortable={false} />
                        <DeleteButton
                            mutationOptions={{
                                onSuccess: () => {
                                    notify(
                                        'resources.operation.notification.deleted',
                                        {
                                            undoable: true,
                                        },
                                    );
                                },
                            }}
                        />
                    </DraggableDatagrid>
                </EnhancedListView>
            </ReferenceManyField>
        </Grid>
    );
};
