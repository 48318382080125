import React, { ChangeEvent, Dispatch, useState } from 'react';
import {
    CreateResult,
    Link,
    useDataProvider,
    useGetIdentity,
    useNotify,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { CloudUpload, HourglassBottom } from '@mui/icons-material/';
import { Alert, Button } from '@mui/material';
import { AxiosHeaders } from 'axios';
import { TaskPhoto, TaskPhotoSuccessful } from '../../types';
import { durationTimeToMilliseconds } from '../../constants';

/*
 * s3 POST Object
 * https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
 */
type S3Request = {
    key: string;
    policy: string;
    'x-amz-algorithm': string;
    'x-amz-credential': string;
    'x-amz-date': string;
    'x-amz-signature': string;
};

type S3PreSigned = {
    fields: S3Request;
    url: string;
};

type PresignedResponse = {
    data: {
        data: S3PreSigned[];
    };
};

type S3Response = {
    body: string;
    headers: AxiosHeaders;
    json: JSON;
    status: number;
};

export class ValidationError extends Error {
    constructor(msg: string) {
        super(msg);
        this.name = 'ValidationError'; // allow check on instanceof
    }
}

export const useUploadButton = (
    setPhotos: Dispatch<React.SetStateAction<TaskPhoto[]>>,
) => {
    const maxUploads = 6;
    const { data } = useGetIdentity();
    const record = useRecordContext();
    const { id: taskId } = record;
    const userId = data?.id;

    const { externalPOST, getOneWithBody, create } = useDataProvider();
    const [loading, setLoading] = useState<boolean>(false);

    const translate = useTranslate();
    const notify = useNotify();

    // TODO: incorporate this into future non-recoverable errors messages
    const onError = (message = '') => {
        notify(
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            <Alert severity="error" onClose={() => {}} variant="filled">
                {`An Error Occured ${message}. Please `}
                <Link
                    to={
                        'https://docs.google.com/forms/d/e/1FAIpQLSdB_lgOPjvIbeZ0QXAb3CdOcaappjoiPQ6hlNLX-nqvodb05g/viewform?vc=0&c=0&w=1&flr=0'
                    }
                    target="_blank"
                >
                    Click Here To Report It.
                </Link>
            </Alert>,
            {
                autoHideDuration: durationTimeToMilliseconds({ seconds: 60 }),
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            },
        );
    };

    const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const target: HTMLInputElement | undefined = e.target;
        if (!target?.files || target.files.length === 0) return;
        if (target.files.length > maxUploads) {
            notify(
                translate('actions.upload.photo_limit', { count: maxUploads }),
                { type: 'warning' },
            );
            return;
        }

        const files: FileList = target.files;
        setLoading(true);
        try {
            // Get s3 presigned url
            const response: PresignedResponse = await getOneWithBody(
                'presignedurl',
                {
                    ids: [userId, taskId],
                    params: { count: files?.length ?? 1 },
                },
            );

            const uris = response?.data?.data ?? [];
            const s3Uploads: Promise<S3Response>[] = [];

            // loop through files to append s3 presigned url
            uris.forEach((presigned: S3PreSigned, index: number) => {
                const form = new FormData();
                Object.entries(presigned.fields).forEach(([key, value]) =>
                    form.append(key, value as string),
                );
                form.append('file', files[index]);
                s3Uploads.push(
                    externalPOST(presigned.url, {
                        data: form,
                    }),
                );
            });

            // Upload to s3 with POST Object
            const s3Uploaded: S3Response[] = await Promise.all(s3Uploads);

            // Get create Promises to upload API
            const creates: Promise<CreateResult<TaskPhotoSuccessful>>[] =
                s3Uploaded.reduce(
                    (
                        acc: Promise<CreateResult<TaskPhotoSuccessful>>[],
                        res: S3Response,
                        i: number,
                    ): Promise<CreateResult<TaskPhotoSuccessful>>[] => {
                        if (res?.status === 204) {
                            acc.push(
                                create(`task_photo/${userId}/${taskId}`, {
                                    data: {
                                        img_url: `${uris[i].url}${uris[i].fields.key}`,
                                    },
                                }),
                            );
                        }
                        return acc;
                    },
                    [] as Promise<CreateResult<TaskPhotoSuccessful>>[],
                );
            const created: CreateResult<TaskPhotoSuccessful>[] =
                await Promise.all(creates);

            // Optimistic render image component
            // TODO: fix create's response body
            const success: TaskPhoto[] = created.reduce(
                (acc: TaskPhoto[], curr: CreateResult<TaskPhotoSuccessful>) => {
                    acc.push({
                        id: curr.data.id,
                        thumb_url: curr.data.img_url,
                        url: curr.data.img_url,
                    } as TaskPhoto);
                    return acc;
                },
                [] as TaskPhoto[],
            );
            setPhotos((prevPhotos: TaskPhoto[]) => prevPhotos.concat(success));

            // Check for missing upload
            if (creates.length !== s3Uploaded.length)
                throw new ValidationError('Not All Files Uploaded');
        } catch (err) {
            const message =
                err instanceof ValidationError ? ` Due To ${err}` : '';
            onError(`During File Upload${message}`);
        } finally {
            setLoading(false);
            e.target.value = ''; // reset the upload button
        }
    };

    return (
        <Button
            component="label"
            variant="contained"
            startIcon={loading ? <HourglassBottom /> : <CloudUpload />}
        >
            {loading ? 'Uploading' : 'Upload file'}
            <input
                type="file"
                accept="image/*" // only used as suggestions in popup
                multiple
                style={{ width: 0 }}
                onChange={onChange}
            />
        </Button>
    );
};
