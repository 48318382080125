import * as React from 'react';
import { Avatar, Button, Card, CardActions } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
    Form,
    Notification,
    required,
    TextInput,
    SelectInput,
    PasswordInput,
    useDataProvider,
    useNotify,
    useTranslate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import OkosBgImg from '../assets/okos_bg2.png';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { registerRoles } from '../constants';

export const Register: React.FunctionComponent<Record<string, never>> = () => {
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const translate = useTranslate();
    const handleBackClick = async () => navigate('/login');

    const handleClick = async (data: FieldValues): Promise<void> => {
        const {
            email,
            first_name,
            last_name,
            password,
            phone,
            provider_code,
            role,
        } = data;

        const params = {
            data: {
                email,
                first_name,
                last_name,
                password,
                phone,
                provider_code,
                role,
            },
        };

        try {
            await dataProvider.create('user', params);
            notify('Registration Successful', {
                type: 'success',
            });
            navigate('/login');
        } catch (error) {
            notify('Registration Unsuccessful', {
                type: 'error',
            });
        }
    };

    return (
        <>
            <Form onSubmit={(data) => handleClick(data)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        backgroundImage: OkosBgImg,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                >
                    <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                        <Box
                            sx={{
                                margin: '1em',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Avatar sx={{ bgcolor: 'secondary.main' }}>
                                <LockIcon />
                            </Avatar>
                        </Box>
                        <Box
                            sx={{
                                marginTop: '1em',
                                display: 'flex',
                                justifyContent: 'center',
                                color: (theme) => theme.palette.grey[500],
                            }}
                        ></Box>
                        <Box sx={{ padding: '0 1em 1em 1em' }}>
                            <Box sx={{ marginTop: '1em' }}>
                                <TextInput
                                    autoFocus
                                    source="first_name"
                                    label="pos.register.first_name"
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <TextInput
                                    source="last_name"
                                    label="pos.register.last_name"
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <SelectInput
                                    label="pos.register.role"
                                    source="role"
                                    choices={registerRoles}
                                    defaultValue={'Manager'}
                                    disabled={true}
                                    fullWidth
                                />
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <PasswordInput
                                    autoComplete="new-password"
                                    source="password"
                                    label="pos.register.password"
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <TextInput
                                    source="email"
                                    label="pos.register.email"
                                    type="email"
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <TextInput
                                    source="phone"
                                    label="pos.register.phone"
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <PasswordInput
                                    autoComplete="false"
                                    label="pos.register.provider"
                                    source="provider_code"
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                        </Box>

                        <CardActions
                            sx={{
                                padding: '0 1em 1em 1em',
                            }}
                        >
                            <Button
                                variant="contained"
                                type="submit"
                                color="secondary"
                                fullWidth
                            >
                                {translate('auth.register')}
                            </Button>
                        </CardActions>
                        <Box sx={{ padding: '0 1em 1em 1em' }}>
                            <Button
                                onClick={handleBackClick}
                                color="primary"
                                variant="outlined"
                                fullWidth
                            >
                                {translate('pos.button.back_to_button', {
                                    page_name: 'Log In',
                                })}
                            </Button>
                        </Box>
                    </Card>
                    <Notification />
                </Box>
            </Form>
        </>
    );
};
