import React from 'react';
import {
    BooleanInput,
    SelectInput,
    SimpleForm,
    TextInput,
    usePermissions,
    useRecordContext,
} from 'react-admin';
import { Grid } from '@mui/material';
import { User } from '../types';
import { roles } from '../constants';
import { DefaultEditToolbar, InfoIcon, SublistEdit } from '../components';

const UserTitle = () => {
    const record = useRecordContext<User>();
    return !record ? (
        <></>
    ) : (
        <span>
            User - {record.first_name} {record.last_name}{' '}
            <InfoIcon resource={'user'} record={record} />
        </span>
    );
};

export const UserEdit: React.FC = () => {
    const { permissions } = usePermissions();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (
        <>
            <SublistEdit title={<UserTitle />} resource="user">
                <SimpleForm toolbar={<DefaultEditToolbar resource="user" />}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <TextInput source="first_name" disabled={true} />
                        </Grid>
                        <Grid item>
                            <TextInput source="last_name" disabled={true} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item>
                            <TextInput source="email" disabled={true} />
                        </Grid>
                        <Grid item>
                            <TextInput source="phone" />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <SelectInput
                                label="resources.user.fields.role"
                                source="role"
                                choices={roles}
                                disabled={
                                    permissions === 'Admin' ? false : true
                                }
                            />
                        </Grid>
                        <Grid item>
                            <BooleanInput source="is_active" />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </SublistEdit>
        </>
    );
};
