import React from 'react';
import { EmptyProps, useListContext, useTranslate  } from 'react-admin';
import { Grid, Button } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

export type DatagridEmptyProps = {
    onClick?: () => void;
} & EmptyProps;

/*
 * This component should be used in the DataGrid with paginations.
 * This is the back-stop to return user to first page of the pagination if
 * any last item in page is deleted; this will replace the default "no results available"
 */

export const DatagridEmpty: React.FC<DatagridEmptyProps> = ({ onClick }) => {
    const { page, setPage } = useListContext();
    const returnToFirstPage = () =>
        page >= 2 ? setPage(page - 1) : setPage(1);
    const translate = useTranslate();

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item>
                <Button
                    onClick={onClick ?? returnToFirstPage}
                    startIcon={<ChevronLeft />}
                >
                    {translate('common.components.pagination.previous')}
                </Button>
            </Grid>
        </Grid>
    );
};
