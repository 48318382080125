import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
    createContext,
} from 'react';
import { io, Socket } from 'socket.io-client';
import {
    MessageToServerProps,
    ServerRoomProps,
    SocketMessageProps,
    TypingIndicatorProps,
} from '../types';

const useLocalServer = false;
export const serverURL = useLocalServer
    ? 'http://localhost:5001'
    : process.env.REACT_APP_DATA_PROVIDER ?? 'http://localhost:5001';

interface SocketProviderProps {
    children?: React.ReactNode;
}

export interface ServerToClientEvents {
    'receive-image': (data: SocketMessageProps) => void;
    'receive-message': (data: SocketMessageProps) => void;
    'user-is-typing': (data: TypingIndicatorProps) => void;
}

export interface ClientToServerEvents {
    'join-room': (data: ServerRoomProps) => void;
    'leave-room': (data: ServerRoomProps) => void;
    'send-image': (data: MessageToServerProps) => void;
    'send-message': (data: MessageToServerProps) => void;
    'user-typing': (data: TypingIndicatorProps) => void;
}

export type SocketContext = {
    setConnected: (connected: boolean) => void;
    socket: Socket<ServerToClientEvents, ClientToServerEvents>;
};

const SocketContext = createContext<SocketContext | null>(null);
const IsConnectedContext = createContext<boolean>(false);

export const SocketProvider: FunctionComponent<SocketProviderProps> = ({
    children,
}) => {
    const [isConnected, setConnected] = useState<boolean>(false);

    /* socket is set to not connect on instance creation */
    const [socket] = useState<
        Socket<ServerToClientEvents, ClientToServerEvents>
    >(() => io(serverURL, { autoConnect: false }));

    useEffect(() => {
        return () => {
            socket.close();
            setConnected(false);
        };
    }, [serverURL]);

    const value = { socket, setConnected };
    return (
        <SocketContext.Provider value={value}>
            <IsConnectedContext.Provider value={isConnected}>
                {children}
            </IsConnectedContext.Provider>
        </SocketContext.Provider>
    );
};

export function useSocket(): SocketContext {
    const socketContext = useContext(SocketContext);
    if (socketContext === null) {
        throw new Error(
            'useSocket must be used within a SocketContext Provider',
        );
    }
    return { ...socketContext };
}

export function useIsConnected(): boolean {
    return useContext(IsConnectedContext);
}
