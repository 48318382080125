/* eslint-disable prettier/prettier */
import React from 'react';
import {
    DatagridConfigurable,
    EditButton,
    ReferenceManyField,
    RefreshButton,
    Show,
    TabbedShowLayout,
    TopToolbar,
    WithRecord,
    ListView,
    Button,
    useRecordContext,
    useGetIdentity,
    useShowContext,
} from 'react-admin';
import { ProjectTitle } from './components/ProjectTitle';
import { SiteExpand } from './SiteExpand';
import { Box } from '@mui/material';
import PrefixTextField from './components/PrefixTextField';
import Chat from '@mui/icons-material/Chat';

import useCreateTempGroupChat from '../../hooks/useCreateTempGroupChat';
import { Project } from '../../types';

const ProjectShowActions = () => {
    const createTempGroupChat = useCreateTempGroupChat();
    const { data } = useGetIdentity();
    const record = useRecordContext<Project>();
    return (
        <TopToolbar>
            {data?.id === record?.project_manager?.id && (
                <Button
                    color="primary"
                    onClick={() => {
                        createTempGroupChat('project');
                    }}
                    label="Project Chat"
                >
                    <Chat />
                </Button>
            )}

            <RefreshButton />
            <EditButton />
        </TopToolbar>
    );
};

const ChatButton = () => {
    const createTempGroupChat = useCreateTempGroupChat();
    const { data } = useGetIdentity();
    const { record } = useShowContext<Project>();

    if (data?.id !== record?.project_manager?.id) return null;
    return (
        <Button
            color="primary"
            onClick={() => {
                createTempGroupChat('site');
            }}
            label="Site Chat"
        >
            <Chat />
        </Button>
    );
};

const ProjectTitleSite = () => {
    return <WithRecord render={(record) => <span>{record.title}</span>} />;
};

export const ProjectShow: React.FC = () => {
    // TODO: decouple Tabbed to separate component, so we can call useShowContext to improve loading

    return (
        <Show
            title={<ProjectTitle />}
            actions={<ProjectShowActions />}
            sx={{ width: '100%' }}
        >
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label={<ProjectTitleSite />}>
                    <ReferenceManyField
                        label="Sites"
                        reference="site"
                        target="project_fk"
                    >
                        <ListView actions={false}>
                            <Box
                                sx={{
                                    border: '1px solid rgba(0,0,0,0.2)',
                                    borderRadius: '5px',
                                }}
                            >
                                <DatagridConfigurable
                                    bulkActionButtons={false}
                                    expand={<SiteExpand />}
                                    rowStyle={() => {
                                        return {
                                            justifyContent: 'space-between',
                                        };
                                    }}
                                >
                                    <PrefixTextField
                                        source="title"
                                        label="Sites"
                                        prefix="Site"
                                        sortable={false}
                                    />
                                    <ChatButton />
                                </DatagridConfigurable>
                            </Box>
                        </ListView>
                    </ReferenceManyField>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
};
