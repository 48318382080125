import React from 'react';
import {
    BooleanField,
    Datagrid,
    NumberField,
    Pagination,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    TextField,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { OperationTemplate } from '../types';
import { InfoIcon } from '../components';
import { Divider } from '@mui/material';

const OperationTemplateTitle: React.FC = () => {
    const record = useRecordContext<OperationTemplate>();
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.template_operation.name')} - {record.title}
            <InfoIcon resource={'operation_template'} record={record} />
        </span>
    ) : null;
};

export const OperationTemplateShow: React.FC = () => {
    return (
        <Show
            resource="template_operation"
            title={<OperationTemplateTitle />}
            component="div"
            actions={false}
        >
            <SimpleShowLayout divider={<Divider flexItem />}>
                <TextField source="title" />
                <TextField source="description" />
                <ReferenceManyField
                    label="Tasks in Template"
                    pagination={<Pagination />}
                    reference="template_task"
                    sort={{
                        field: 'priority_number',
                        order: 'ASC',
                    }}
                    target="tpl_op_fk"
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="priority_number" />
                        <TextField source="title" sortable={false} />
                        <TextField source="description" sortable={false} />
                        <BooleanField source="note_required" sortable={false} />
                        <BooleanField
                            source="photo_required"
                            sortable={false}
                        />
                        <NumberField source="photo_num" sortable={false} />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    );
};
