import { TranslationMessages, StringMap } from 'react-admin';
import englishMessages from 'ra-language-english';

interface CustomTranslationMessages
    extends Omit<TranslationMessages, 'update_application'> {
    pos: StringMap;
    actions: StringMap;
    resources: StringMap;
}

export const customEnglishMessages: CustomTranslationMessages = {
    ...englishMessages,
    auth: {
        login: 'Log In',
        login_error: 'Authentication failed, please retry',
        register: 'Register',
    },
    pos: {
        feedback: 'Feedback',
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        button: {
            back_button: 'Back',
            back_to_button: 'To %{page_name}',
            save_button: 'Save',
        },
        dashboard: {
            name: 'Dashboard - Your Live Projects',
            alerts: 'Active Alerts',
            projects: 'Active Projects',
            upcomingprojects: 'Upcoming Projects',
            metrics: 'Metrics',
            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue History',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items: 'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Okos Smarthome ',
                subtitle: 'To begin create a Project',
                ra_button: 'react-admin site',
                demo_button: 'Create new project',
            },
        },
        closeout: {
            name: 'Okos Project Closeout Report',
            created_on: 'Report Created on',
            created_by: 'Created by',
            project: 'Project',
            project_code: 'Project Code',
            purchase_order: 'Purchase Order (PO)',
            client_code: 'Client Code',
            site: 'Site',
            site_address: 'Site Address',
            site_start: 'Site Start',
            site_end: 'Site End',
            purpose: 'Purpose',
            scope: 'Scope',
            performance: 'Project Performance Assessment',
            baseline: 'Baseline',
            end_result: 'Project End-Result',
            overall: 'Overall Project Assessment',
            completion: 'Project Completion Work',
            outstanding: 'Outstanding Issues',
            operation: 'Operation',
            task: 'Task',
            note: 'Note / Response',
            no_photo: '(No Photos Required)',
            download: 'Download PDF',
            content_part1:
                'The purpose of this document is to mark the completion of the Site',
            content_part2: 'of Project',
            content_part3:
                "by assessing the project's performance, identifying the lessons learned, and confirming that essential contractual and other activities have been completed.",
        },
        menu: {
            users: 'User Management',
            projects: 'Projects Planning',
            inventories: 'Inventory Mgmt',
            reports: 'Reports',
            devices: 'Device Mgmt',
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
        },
        register: {
            register: 'Register',
            first_name: 'First Name',
            last_name: 'Last Name',
            email: 'Email',
            password: 'Password',
            phone: 'Phone Number',
            provider: 'Provider Code',
            role: 'Role',
        },
    },
    actions: {
        assign: {
            unassign: 'unassign',
        },
        project_chat: 'Project Chat',
        photos: 'Photos',
        chat: 'Chat',
        in_progress: 'In Progress',
        copy: {
            error: 'Cannot copy %(resource)s: %(error)',
            notification:
                '%{resource} Copied |||| %{smart_count} %{resource}s Copied',
            title: 'Confirm Number Of Copies',
        },
        upload: {
            photo_limit:
                'Please keep the photo uploads to a maximum of %{count} photos',
        },
    },
    common: {
        duration: {
            hour: '%{smart_count} hour |||| %{smart_count} hours',
            minute: '%{smart_count} minute |||| %{smart_count} minutes',
        },
        prompts: {
            confirmation: {
                isdirty: 'Do you want to discard unsaved changes?',
            },
        },
        components: {
            pagination: {
                previous: 'Previous Page',
            },
        },
    },
    status: {
        project: {
            red: 'No technicians checked in',
            green: 'Technicians checked in',
            grey: 'Project inactive',
        },
        site: {
            red: 'No technicians checked in',
            green: 'Technicians checked in',
            grey: 'Site inactive',
        },
        technician: {
            red: 'Checked out',
            green: 'Checked in',
        },
    },
    resources: {
        scheduling: {
            name: 'Scheduling',
        },
        manager: {
            name: 'Users',
            empty: 'Please assign a manager',
            autocomplete: {
                label: 'Project Manager',
                notification: {
                    getFailed:
                        'Failed to get the managers assignable to this project',
                    actionFailed: 'Failed to assign the selected managers',
                },
            },
            notification: {
                saved: 'Manager Saved',
                deleted: 'Manager Deleted',
                created: 'New Manager Created',
                isdirty:
                    "Unsaved Changes! Click 'Save' To Ensure Your Updates Are Saved",
            },
            fields: {
                role: 'Role',
            },
            placeholders: {
                id: 'Find by ID',
                role: 'Find by Role',
                first_name: 'Find by First Name',
                last_name: 'Find by Last Name',
                email: 'Find by Email',
                phone: 'Find by Phone',
            },
        },
        user: {
            name: 'Users',
            empty: 'Please assign a technician',
            autocomplete: {
                label: 'Assignable Technicians',
                notification: {
                    getFailed:
                        'Failed to get the technicians assignable to this project',
                    actionFailed: 'Failed to assign the selected technicians',
                },
            },
            notification: {
                saved: 'User Saved',
                deleted: 'User Deleted',
                created: 'New User Created',
                isdirty:
                    "Unsaved Changes! Click 'Save' To Ensure Your Updates Are Saved",
            },
            fields: {
                first_name: 'First Name',
                last_name: 'Last Name',
                email: 'Email',
                phone: 'Phone',
                role: 'Role',
            },
            filters: {
                is_active: 'Active Users',
            },
            placeholders: {
                id: 'Find by ID',
                role: 'Find by Role',
                first_name: 'Find by First Name',
                last_name: 'Find by Last Name',
                email: 'Find by Email',
                phone: 'Find by Phone',
            },
            location: {
                getFailed: 'Could not find location',
            },
        },
        site: {
            name: 'Sites',
            notification: {
                saved: 'Site Saved',
                deleted: 'Site Deleted',
                created: 'New Site Created',
                isdirty:
                    "Unsaved Changes! Click 'Save' To Ensure Your Updates Are Saved",
            },
            fields: {
                id: 'ID',
                title: 'Site Title',
                description: 'Description',
                status: 'Status',
                address: 'Address',
                scheduled_start: 'Scheduled Start',
                scheduled_end: 'Scheduled End',
                current_start: 'Actual Start',
                current_end: 'Actual End',
                created_on: 'Created On',
                updated_on: 'Updated On',
                project_id: 'Project ID',
                technicians: 'Site Technicians',
                csv: 'Upload Bulk CSV',
                links: 'File link',
                count: 'Number of Copies',
                include_operations: 'Include Operations',
            },
            list: {
                failed: 'Failed to get sites',
            },
            map: {
                failed: 'Invalid address, please try again!',
            },
            buttons: {
                chat: 'Site Chat',
            },
        },
        task: {
            name: 'Tasks',
            notification: {
                saved: 'Task Saved',
                deleted: 'Task Deleted',
                created: 'New Task Created',
                isdirty:
                    "Unsaved Changes! Click 'Save' To Ensure Your Updates Are Saved",
            },
            fields: {
                completion_order: 'Completion Order',
                status: 'Status',
                title: 'Task Title',
                description: 'Description',
                bundle_id: 'task bundle id',
                project: 'Relevant project',
                num_photos: 'Number of Photos',
                bundle: 'Task Bundle',
                photo_req: 'Photo Required',
            },
            placeholders: {
                id: 'Find by ID',
                status: 'Find by Status',
                title: 'Find by Title',
                description: 'Find by Description',
            },
            field_errors: {
                num_photos: 'Must have at least 1 photo',
            },
        },
        template_task: {
            name: 'Task Template',
            notification: {
                saved: 'Task Template Saved',
                deleted: 'Task Template Deleted',
                created: 'New Task Template Created',
                isdirty:
                    "Unsaved Changes! Click 'Save' To Ensure Your Updates Are Saved",
            },
            fields: {
                description: 'Description',
                sublist: 'Template Tasks:',
                note_required: 'Note Required',
                photo_required: 'Photo Required',
                photo_num: 'Number of Photos',
                priority_number: 'Completion Order',
                title: 'Title',
            },
        },
        devices: {
            name: 'Devices',
        },
        devicemap: {
            name: 'Device Map',
        },
        devicetypes: {
            name: 'Device Types',
        },
        project: {
            name: 'Projects',
            notification: {
                saved: 'Project Saved',
                deleted: 'Project Deleted',
                created: 'New Project Created',
                isdirty:
                    "Unsaved Changes! Click 'Save' To Ensure Your Updates Are Saved",
            },
            fields: {
                title: 'Title',
                title_project: 'Project Title',
                project_code: 'Project Code',
                description: 'Description',
                status: 'Status',
                scheduled_start: 'Scheduled Start',
                scheduled_end: 'Scheduled End',
                current_start: 'Actual Start',
                current_end: 'Actual End',
                updated_on: 'Updated On',
                complete: 'Complete',
                sites: 'Project Sites',
                technicians: 'Project Technicians',
                time_region: 'Time Region with Offset Names',
            },
            filters: {
                pm_fk: 'Project Manager',
            },
            errors: {
                title: 'Title Required',
                scheduled_start: 'Start date must be before end date',
            },
            buttons: {
                chat: 'Project Chat',
                photos: 'Photos',
            },
        },
        chat: {
            name: 'Chat Rooms',
        },
        reference: {
            name: 'Reference',
            fields: {
                description: 'Description',
                title: 'Title',
                url: 'Link Address',
            },
            notifications: {
                empty: 'No Link Available',
            },
        },
        supports: {
            name: 'Project Supports',
            autocomplete: {
                label: 'Project Supports',
                notification: {
                    getFailed:
                        'Failed to get the supports assignable to this project',
                    actionFailed: 'Failed to assign the selected supports',
                    saved: 'Project supports saved',
                },
            },
            notification: {
                saved: 'Project supports saved',
            },
        },
        chatrooms: {
            name: 'Chat Rooms',
        },
        provider: {
            name: 'Providers',
            notification: {
                saved: 'Provider Saved',
                deleted: 'Provider Deleted',
                created: 'New Provider Created',
                isdirty:
                    "Unsaved Changes! Click 'Save' To Ensure Your Updates Are Saved",
            },
            fields: {
                name: 'Name',
                provider_code: 'Provider Code',
            },
            placeholders: {
                id: 'Find by ID',
                name: 'Find by Name',
                provider_code: 'Find by Provider Code',
            },
        },
        taskbundles: {
            name: 'Task Bundles',
            notification: {
                saved: 'Task Bundle Saved',
                deleted: 'Task Bundle Deleted',
                created: 'Task Bundle Created',
            },
        },
        operation: {
            name: 'Operation',
            notification: {
                saved: 'Operation Saved',
                deleted: 'Operation Deleted',
                created: 'Operation Created',
                isdirty:
                    "Unsaved Changes! Click 'Save' To Ensure Your Updates Are Saved",
            },
            fields: {
                id: 'ID',
                status: 'Status',
                title: 'Operation Title',
                description: 'Description',
                site_id: 'Site ID',
            },
        },
        template_operation: {
            name: 'Operation Templates',
            list: 'Operation Templates',
            autocomplete: {
                label: 'Add Operation Templates',
                notification: {
                    getFailed: 'Failed to get the operation templates',
                    actionFailed: 'Failed to copy the operation templates',
                },
            },
            notification: {
                saved: 'Operation Template saved',
                deleted: 'Operation Template deleted',
                created: 'New Operation Template Created',
                listFailed: 'Failed to get the Operation Templates',
                copyFailed: 'Failed to copy the Operation Templates',
                isdirty:
                    "Unsaved Changes! Click 'Save' To Ensure Your Updates Are Saved",
            },
            fields: {
                id: 'ID',
                title: 'Template Title',
                description: 'Description',
                created_on: 'Created On',
                updated_on: 'Updated On',
                is_active: 'Active',
            },
        },
        evaluation: {
            name: 'Site Evaluation',
            list: 'Site Evaluations',
            autocomplete: {
                label: 'Add Site Evaluation',
                notification: {
                    getFailed: 'Failed to get the site evaluation',
                    actionFailed: 'Failed to copy the site evaluation',
                },
            },
            notification: {
                saved: 'site evaluation saved',
                deleted: 'site evaluation deleted',
                created: 'New site evaluation Created',
                listFailed: 'Failed to get the site evaluation',
                copyFailed: 'Failed to copy the site evaluation',
                isdirty:
                    "Unsaved Changes! Click 'Save' To Ensure Your Updates Are Saved",
            },
            fields: {
                id: 'ID',
                search: 'Site',
                outstanding: 'Outstanding Issues',
                baseline: 'Baseline',
                end_result: 'Project End-Result',
                assesment: 'Overall Project Assessment',
                purchase_order: 'Purchase Order (PO)',
                field_name: 'Field Name',
                field_value: 'Field Value',
                created_on: 'Created On',
                updated_on: 'Updated On',
                print_button: 'Closeout Report',
                list_buttons: 'Site Evaluation',
            },
            filters: {
                pm_fk: 'Your Sites Evaluation',
            },
            actions: {
                project_link: 'Project',
                create_report: 'Create',
                edit_report: 'Edit',
                print_report: 'Print',
            },
            errors: {
                no_id: 'Site Evaluation Not Found',
            },
        },
        inventorytypes: {
            name: 'Inventory Types',
        },
        inventories: {
            name: 'Inventories',
        },
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                stateAbbr: 'State',
            },
            filters: {
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                has_ordered: 'Has ordered',
                has_newsletter: 'Has newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        commands: {
            name: 'Orders |||| ',
            amount: '1 order |||| %{smart_count} orders',
            title: 'Order %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    taxes: 'Tax',
                    total: 'Total',
                    site_price: 'Site Price',
                },
                address: 'Address',
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                nb_items: 'Nb Items',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
            section: {
                order: 'Order',
                customer: 'Customer',
                shipping_address: 'Shipping Address',
                items: 'Items',
                total: 'Totals',
            },
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            fields: {
                date: 'Invoice date',
                customer_id: 'Customer',
                command_id: 'Order',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                address: 'Address',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                sales: 'Sales',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
            filters: {
                categories: 'Categories',
                stock: 'Stock',
                no_stock: 'Out of stock',
                low_stock: '1 - 9 items',
                average_stock: '10 - 49 items',
                enough_stock: '50 items & more',
                sales: 'Sales',
                best_sellers: 'Best sellers',
                average_sellers: 'Average',
                low_sellers: 'Low',
                never_sold: 'Never sold',
            },
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',
            },
        },
        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
        segments: {
            name: 'Segment |||| Segments',
            fields: {
                customers: 'Customers',
                name: 'Name',
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer',
            },
        },
        instantReport: {
            name: 'Instant Report',
        },
        scheduledReports: {
            name: 'Scheduled Reports',
        },
    },
    default: {
        no_title: '',
    },
    reporting: {
        time_span: {
            week: 'This week',
            month: 'This month',
            lastWeek: 'Last 7 Days',
            lastTwoWeeks: 'Last 14 Days',
            lastMonth: 'Last 30 Days',
            lastYear: 'Last Year',
        },
        total_time: {
            week: 'All projects this week',
            month: 'All projects this month',
            lastWeek: 'All projects last 7 days',
            lastTwoWeeks: 'All projects last 14 days',
            lastMonth: 'All projects last 30 days',
        },
        graph: {
            titles: {
                week: 'Hours worked this week',
                month: 'Hours worked this month',
                lastWeek: 'Hours worked last 7 days',
                lastTwoWeeks: 'Hours worked last 14 days',
                lastMonth: 'Hours worked last 30 days',
                customSpan: 'Hours worked between start and end date',
            },
            labels: {
                y_axis: 'Hours worked',
                x_axis: 'Days',
            },
        },
        total: {
            fetchFail: 'Failed to get data',
            noData: 'No data available',
        },
    },
};

export { englishMessages };
