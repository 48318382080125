import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';
import { SubMenu } from './SubMenu';
import users from '../users';
import projects from '../projects';
import ChatIcon from '@mui/icons-material/Chat';
import providers from '../providers';
import operationTemplate from '../operationTemplate';
import { checkPermission } from '../authProvider';
import { Badge } from '@material-ui/core';
import { useTwilio } from '../contexts/TwilioProvider';
import projectEvaluation from '../siteEvaluation';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

type MenuName =
    | 'menuProjects'
    | 'menuUsers'
    | 'menuChats'
    | 'menuReports'
    | 'menuInventories';

export const Menu = ({ dense = false }: MenuProps) => {
    // only one of the items in the object can be true at a time which will be the submenu that is clicked on
    const [showSubmenu, setShowSubmenu] = useState({
        menuProjects: true,
        menuUsers: false,
        menuChats: false,
        menuReports: false,
        menuInventories: false,
    });

    const translate = useTranslate();
    const [open] = useSidebarState();
    const { unreadCount } = useTwilio() as {
        unreadCount: number;
    };

    const handleToggle = (menu: MenuName) =>
        setShowSubmenu((state) => ({ ...state, [menu]: !state[menu] }));

    const _admin_ = checkPermission('manager');

    // TODO: fix react liniting
    /* eslint-disable react/react-in-jsx-scope */
    return (
        <Box
            sx={{
                width: open ? 500 : 100,
                marginTop: 1,
                marginBottom: 1,
                transition: (theme) =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />
            {_admin_ && (
                <SubMenu
                    handleToggle={() => handleToggle('menuUsers')}
                    isOpen={showSubmenu.menuUsers}
                    name="pos.menu.users"
                    dense={dense}
                    icon={<users.icon />}
                >
                    <MenuItemLink
                        to="/user"
                        state={{ _scrollToTop: true }}
                        primaryText={'resources.user.name'}
                        leftIcon={<users.icon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/provider"
                        state={{ _scrollToTop: true }}
                        primaryText={'resources.provider.name'}
                        leftIcon={<providers.icon />}
                        dense={dense}
                    />
                </SubMenu>
            )}
            {_admin_ && (
                <SubMenu
                    handleToggle={() => handleToggle('menuProjects')}
                    isOpen={showSubmenu.menuProjects}
                    name="pos.menu.projects"
                    dense={dense}
                    icon={<projects.planningIcon />}
                >
                    <MenuItemLink
                        to="/project"
                        state={{ _scrollToTop: true }}
                        primaryText={'resources.project.name'}
                        leftIcon={<projects.icon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/template_operation"
                        state={{ _scrollToTop: true }}
                        primaryText={'resources.template_operation.name'}
                        leftIcon={<operationTemplate.icon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/evaluation"
                        state={{ _scrollToTop: true }}
                        primaryText={'resources.evaluation.name'}
                        leftIcon={<projectEvaluation.icon />}
                        dense={dense}
                    />
                </SubMenu>
            )}
            {_admin_ && (
                <MenuItemLink
                    to="/chat"
                    state={{ _scrollToTop: true }}
                    primaryText={'resources.chatrooms.name'}
                    leftIcon={
                        <>
                            {unreadCount > 0 ? (
                                <Badge
                                    overlap="rectangular"
                                    badgeContent={unreadCount}
                                    color="error"
                                >
                                    <ChatIcon />
                                </Badge>
                            ) : (
                                <ChatIcon />
                            )}
                        </>
                    }
                    dense={dense}
                />
            )}
            {_admin_ && (
                <MenuItemLink
                    to="/reference"
                    state={{ _scrollToTop: true }}
                    primaryText={'resources.reference.name'}
                    leftIcon={<DocumentScannerIcon />}
                    dense={dense}
                />
            )}
        </Box>
    );
};
