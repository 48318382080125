import ProviderIcon from '@mui/icons-material/Business';
import { ProviderList } from './ProviderList';
import { ProviderCreate } from './ProviderCreate';
import { NotFound } from 'react-admin';

export default {
    create: ProviderCreate,
    edit: NotFound,
    icon: ProviderIcon,
    list: ProviderList,
};
