import React, { MouseEvent } from 'react';
import {
    required,
    useDataProvider,
    useGetIdentity,
    useRecordContext,
    useRedirect,
    useTranslate,
    useUpdateMany,
    Button,
    BooleanInput,
    Datagrid,
    DeleteButton,
    FunctionField,
    Identifier,
    RaRecord,
    ReferenceManyField,
    SimpleForm,
    TextField,
    TextInput,
    TopToolbar,
} from 'react-admin';
import PhotoIcon from '@mui/icons-material/Photo';
import { Grid, Typography } from '@mui/material';
import { Project, UserList } from '../types';
import {
    AssignTechnicians,
    DatagridEmpty,
    DateTimeGrid,
    DefaultEditToolbar,
    EnhancedPagination,
    EnhancedSingleAutoComplete,
    EnhancedMultiAutoComplete,
    EnhancedListView,
    InfoIcon,
    LinkWrapper,
    StatusIcon,
    SublistEdit,
    SublistTopToolbar,
    TimeZoneDateField,
} from '../components';
import { useBlocker } from '../contexts/BlockerProvider';
import {
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    ClearButtons,
    ColorButtons,
} from 'ra-input-rich-text';
import { Chat, OpenInNew } from '@mui/icons-material';
import useCreateTempGroupChat from '../hooks/useCreateTempGroupChat';

export const ProjectEdit: React.FC = () => {
    return (
        <SublistEdit
            resource="project"
            title={<ProjectTitle />}
            component="div"
            actions={<Actions />}
        >
            <Grid container direction="column">
                <ProjectInfo />
                <UsersSelect />
                <Technicians />
                <ProjectSites />
            </Grid>
        </SublistEdit>
    );
};

const ProjectTitle = () => {
    const record = useRecordContext<Project>();
    return record ? (
        <span>
            Project - {record.title}{' '}
            {record?.project_code ? `(${record.project_code})` : ''}
            <InfoIcon resource={'project'} record={record} />
        </span>
    ) : null;
};

const Actions = () => {
    const record = useRecordContext<Project>();
    const redirect = useRedirect();
    const handlePhotosClick = () => {
        redirect('show', 'project', record.id);
    };
    const createTempGroupChat = useCreateTempGroupChat();
    const { data } = useGetIdentity();

    return (
        <TopToolbar>
            {data?.id === record?.project_manager?.id && (
                <Button
                    color="primary"
                    onClick={() => {
                        createTempGroupChat('project');
                    }}
                    startIcon={<Chat />}
                    label="resources.project.buttons.chat"
                />
            )}

            <Button
                color="primary"
                onClick={handlePhotosClick}
                startIcon={<PhotoIcon />}
                label="resources.project.buttons.photos"
            />
        </TopToolbar>
    );
};

type CustomRichTextInputProps = {
    source: string;
};

const CustomRichTextInput = ({ source }: CustomRichTextInputProps) => {
    return (
        /* disable typecheck for RichTextInput defaultValue
        eslint-disable-next-line @typescript-eslint/ban-ts-comment
        */
        <RichTextInput
            source={source}
            fullWidth
            label={`Project ${
                source.charAt(0).toUpperCase() + source.slice(1)
            }`}
            toolbar={
                <RichTextInputToolbar>
                    <LevelSelect />
                    <FormatButtons />
                    <ColorButtons />
                    <AlignmentButtons />
                    <ListButtons />
                    <ClearButtons />
                </RichTextInputToolbar>
            }
        />
    );
};

const ProjectInfo = () => {
    return (
        <Grid
            item
            sx={{
                border: `2px solid lightgrey`,
                borderRadius: `12px`,
                padding: '1.3em',
                paddingBottom: '0',
                marginBottom: '40px',
            }}
        >
            <SimpleForm
                toolbar={<DefaultEditToolbar resource="project" />}
                sx={{
                    paddingX: 0,
                    '.ProseMirror': {
                        minHeight: '122px',
                    },
                    '.ra-input': {
                        alignItems: 'flex-start',
                    },
                }}
            >
                <Grid container spacing={2}>
                    <Grid item sm={7}>
                        <TextInput
                            source="title"
                            autoFocus
                            fullWidth
                            validate={required()}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <TextInput source="project_code" fullWidth />
                    </Grid>
                    <Grid item sm={11}>
                        <CustomRichTextInput source="description" />
                    </Grid>
                    <Grid item sm={11}>
                        <CustomRichTextInput source="scope" />
                    </Grid>
                </Grid>
                <DateTimeGrid
                    sources={{
                        timezone: 'time_region',
                        start: 'scheduled_start',
                        end: 'scheduled_end',
                    }}
                />
                <BooleanInput
                    label="resources.project.fields.complete"
                    source="status"
                    format={(status: string) => status === 'completed'}
                    parse={(complete: boolean) =>
                        complete ? 'completed' : 'in progress'
                    }
                />
            </SimpleForm>
        </Grid>
    );
};

const ManagerSelect: React.FC = () => {
    const dataProvider = useDataProvider();

    // all available PM/Admin can be a manager
    const loadManagers = async () =>
        dataProvider.getAll(`user`, {
            filter: { role__in: ['Admin', 'Manager'], is_active: true },
            perPage: 'all',
            sort: { field: 'first_name', order: 'ASC' },
        });

    const updateProjectManager = async (
        projectId: string,
        mgr_id: Identifier | null,
    ) => {
        const params = {
            id: projectId,
            data: {
                pm_fk: mgr_id,
            },
        };
        return dataProvider.customUpdateOne('project', params);
    };

    const sanitizePM = (project: RaRecord): UserList | null => {
        const { project_manager } = project;
        if (!project_manager) return null;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { phone, ...rest } = project_manager;
        return { ...rest };
    };

    const equalityCheck = (option: UserList, value: UserList): boolean =>
        option.id === value.id;

    return (
        <EnhancedSingleAutoComplete<UserList>
            sx={{
                paddingTop: '10px',
                paddingBottom: '30px',
            }}
            action={updateProjectManager}
            getDefaultValue={sanitizePM}
            labelResource={(t) => `${t.first_name} ${t.last_name}, ${t.email}`}
            loadResource={loadManagers}
            isOptionEqualToValue={equalityCheck}
            resource="manager"
        />
    );
};

const SupportsSelect: React.FC = () => {
    const dataProvider = useDataProvider();

    // the project manager cannot be assigned here; therefore, it needs to call assignable end-point.
    const loadSupports = async (id: Identifier) =>
        dataProvider.getAll(`user/assignables/pm/project/${id}`);
    const [updateMany] = useUpdateMany();

    const updateSupports = async (
        supports_ids: Identifier[],
        project_id: Identifier,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<void | any[]> => {
        return updateMany(`project/${project_id}/supports`, {
            ids: supports_ids,
        });
    };

    const sanitizeSupports = (project: RaRecord): UserList[] | [] => {
        const { supports } = project;
        if (!supports) return [];

        return supports;
    };

    const equalityCheck = (option: UserList, value: UserList): boolean =>
        option.id === value.id;
    return (
        <EnhancedMultiAutoComplete<UserList>
            sx={{
                paddingTop: '10px',
                paddingBottom: '30px',
            }}
            action={updateSupports}
            getDefaultValues={sanitizeSupports}
            labelResource={(t) => `${t.first_name} ${t.last_name}, ${t.email}`}
            loadResource={loadSupports}
            isOptionEqualToValue={equalityCheck}
            resource="supports"
            submitOnClear
            onDelete={() => undefined}
        />
    );
};

const UsersSelect: React.FC = () => {
    return (
        <Grid
            item
            sx={{
                border: `2px solid lightgrey`,
                borderRadius: `12px`,
                paddingBottom: `2px`,
                marginBottom: '40px',
            }}
            padding={'1.3em'}
        >
            <ManagerSelect />
            <SupportsSelect />
        </Grid>
    );
};

const Technicians = () => {
    return <AssignTechnicians resource="project" />;
};

const ProjectSites = () => {
    const { confirmLeave } = useBlocker(); //TODO IAS-1250 remove useBlocker, confirmLeave, toBlock once done.
    const translate = useTranslate();
    const redirect = useRedirect();

    const postRowClick = async (rowClick: 'edit' | 'show') => {
        const toBlock = await confirmLeave();
        if (!toBlock) return rowClick;
        return false;
    };

    const RedirectButton = (record: RaRecord) => {
        const action = record?.evaluation_id ? 'edit' : 'create';

        const handlereportClick = (e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            redirect(
                action,
                'evaluation',
                record?.evaluation_id,
                {},
                { record: { site_fk: record?.id } },
            );
        };
        return (
            <Button
                onClick={handlereportClick}
                startIcon={<OpenInNew />}
                label={`resources.evaluation.actions.${action}_report`}
            />
        );
    };

    return (
        <Grid
            item
            sx={{
                border: `2px solid lightgrey`,
                borderRadius: `12px`,
                padding: '1.3em',
                marginBottom: '40px',
            }}
        >
            <Typography variant="subtitle2" color="grey">
                {translate('resources.project.fields.sites')}
            </Typography>
            <ReferenceManyField
                label="Sites"
                reference="site"
                target="project_fk"
            >
                <EnhancedListView
                    resource="site"
                    actions={
                        <SublistTopToolbar
                            resource="site"
                            label="Create Site +"
                        />
                    }
                    pagination={<EnhancedPagination />}
                >
                    <Datagrid
                        rowClick={() => postRowClick('edit')}
                        bulkActionButtons={false}
                        empty={<DatagridEmpty />}
                    >
                        <StatusIcon resource="site" />
                        <TextField source="status" />
                        <LinkWrapper source="title">
                            <TextField
                                source="title"
                                sx={{
                                    color: 'black',
                                    textDecoration: 'underline',
                                }}
                            />
                        </LinkWrapper>
                        <TextField source="address" />
                        <TimeZoneDateField source="current_start" />
                        <TimeZoneDateField source="current_end" />
                        <TimeZoneDateField source="scheduled_start" />
                        <TimeZoneDateField source="scheduled_end" />

                        <FunctionField
                            label={'resources.evaluation.fields.list_buttons'}
                            render={(record: RaRecord) =>
                                RedirectButton(record)
                            }
                        />
                        <DeleteButton redirect={false} />
                    </Datagrid>
                </EnhancedListView>
            </ReferenceManyField>
        </Grid>
    );
};
