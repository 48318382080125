import React from 'react';
import { Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';

interface PrefixTextFieldProps {
    label: string;
    source: string;
    prefix: string;
    sortable?: boolean;
}

const PrefixTextField = (props: PrefixTextFieldProps) => {
    const { source, prefix } = props;
    const record = useRecordContext();
    if (!record) {
        return null;
    }

    const title = record[source];
    const prefixedValue = title !== undefined ? `${prefix}: ${title}` : '';

    return (
        <Typography variant="body2" sx={{ marginLeft: '15px' }}>
            {prefixedValue}
        </Typography>
    );
};

export default PrefixTextField;
