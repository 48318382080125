import UserIcon from '@mui/icons-material/PersonPin';
import { UserList } from './UserList';
import { UserEdit } from './UserEdit';
import { UserShow } from './UserShow';
import { NotFound } from 'react-admin';

export default {
    create: NotFound,
    edit: UserEdit,
    icon: UserIcon,
    list: UserList,
    show: UserShow,
};
