import React, {
    AdminContext,
    AdminUI,
    CustomRoutes,
    Resource,
    Login,
} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router';
import { authProvider } from './authProvider';
import { Layout } from './layout';
import { Dashboard } from './dashboard';
import { customEnglishMessages } from './i18n/en';
import { customFrenchMessages } from './i18n/fr';
import { lightTheme } from './layout/themes';
import project from './projects';
import sites from './sites';
import { tasks } from './tasks';
import users from './users';
import { ChatRoom } from './chats';
import references from './references';
import providers from './providers';
import dataProviderFactory from './dataProvider';
import operations from './operations';
import operationTemplate from './operationTemplate';
import { SocketProvider, serverURL } from './contexts/SocketProvider';
import { BlockerProvider } from './contexts/BlockerProvider';
import OkosBgImg from './assets/okos_bg2.png';
import templateTask from './taskTemplate';
import { Register } from './layout/Register';
import * as Sentry from '@sentry/react';
import { CustomLoginForm } from './components/CustomLoginForm';
import { TwilioProvider } from './contexts/TwilioProvider';
import projectEvaluation from './siteEvaluation';
import { CloseOutReport } from './reports/CloseOut';
import VideoCall from './videoCall';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const messages: Record<string, any> = {
    en: customEnglishMessages,
    fr: customFrenchMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'en', {
    allowMissing: true,
});

const MyLoginPage = () => (
    <>
        <Login backgroundImage={OkosBgImg}>
            <CustomLoginForm />
        </Login>
    </>
);

const sentryServerConfig = {
    dsn: 'https://3f8adccfd7453e234e1877bceb6ac9b8@o4505087452250112.ingest.us.sentry.io/4505087458476032',
    release: '1.1.0',
    environment: process.env.ENVIRONMENT,
    // TODO: removed replay until error with multiple reply instance is researched
    // https://github.com/getsentry/sentry/issues/47532
    // integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    integrations: [new Sentry.BrowserTracing()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

export const App = () => {
    const unregisterBrokenSW = async () => {
        if ('serviceWorker' in navigator) {
            const registrations =
                await navigator.serviceWorker.getRegistrations();
            registrations.forEach((registration) => {
                if (
                    registration.active?.scriptURL?.includes(
                        'coi-serviceworker.min.js',
                    )
                ) {
                    registration.unregister();
                    window.location.reload();
                }
            });
        }
    };
    unregisterBrokenSW();
    const dataProvider = dataProviderFactory(serverURL);

    const sentryConfig =
        location.hostname === 'localhost' ||
        location.hostname === '0.0.0.0' ||
        location.hostname === '127.0.0.1'
            ? {}
            : sentryServerConfig;
    Sentry.init(sentryConfig);

    // const { permissions } = usePermissions();

    return (
        // @ts-expect-error issue with @type/react as not JSX
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            <SocketProvider>
                <BlockerProvider>
                    <AdminContext
                        authProvider={authProvider}
                        dataProvider={dataProvider}
                        i18nProvider={i18nProvider}
                        theme={lightTheme}
                    >
                        <TwilioProvider>
                            <AdminUI
                                title="Okos Management Backend"
                                layout={Layout}
                                dashboard={Dashboard}
                                disableTelemetry
                                loginPage={MyLoginPage}
                            >
                                {(permissions) => {
                                    return (
                                        <>
                                            <CustomRoutes noLayout>
                                                <Route
                                                    path="/register"
                                                    element={<Register />}
                                                />
                                                <Route
                                                    path="/closeout"
                                                    element={<CloseOutReport />}
                                                />
                                            </CustomRoutes>

                                            <Resource name="user" {...users} />
                                            <Resource
                                                name="project"
                                                {...project}
                                            />
                                            <Resource name="site" {...sites} />
                                            <Resource name="task" {...tasks} />
                                            <CustomRoutes noLayout={true}>
                                                <Route
                                                    path="/video-call/:sessionName/:callType"
                                                    element={<VideoCall />}
                                                />
                                            </CustomRoutes>
                                            <CustomRoutes>
                                                <Route
                                                    path="/chat"
                                                    element={<ChatRoom />}
                                                />
                                                <Route
                                                    path="/chat/:conversationId"
                                                    element={<ChatRoom />}
                                                />
                                            </CustomRoutes>
                                            <Resource
                                                name="provider"
                                                {...providers}
                                            />
                                            <Resource
                                                name="operation"
                                                {...operations}
                                            />
                                            <Resource
                                                name="template_task"
                                                {...templateTask}
                                            />
                                            <Resource
                                                name="evaluation"
                                                {...projectEvaluation}
                                            />
                                            <Resource
                                                name="reference"
                                                {...references}
                                            />
                                            {/* Resources exclusive to Administrators */}
                                            <Resource
                                                name="template_operation"
                                                {...operationTemplate}
                                                edit={
                                                    permissions === 'Admin'
                                                        ? operationTemplate.edit
                                                        : undefined
                                                }
                                            />
                                        </>
                                    );
                                }}
                            </AdminUI>
                        </TwilioProvider>
                    </AdminContext>
                </BlockerProvider>
            </SocketProvider>
        </Sentry.ErrorBoundary>
    );
};
