import React, { useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
    Datagrid,
    FunctionField,
    ListContextProvider,
    RaRecord,
    TextField,
    useList,
    useListContext,
    useTranslate,
} from 'react-admin';

import { LinkWrapper, TimeZoneDateField } from '../../components';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CheckoutModal from './CheckoutModal';

const CheckInList: React.FC = () => {
    const { data } = useListContext();
    const listContext = useList({ data });

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [curRecord, setCurRecord] = useState<null | RaRecord>(null);

    const translate = useTranslate();

    return (
        <ListContextProvider value={listContext}>
            <Datagrid bulkActionButtons={false} size="medium">
                <LinkWrapper
                    label="resources.site.fields.title"
                    resource="site"
                    target="site.id"
                    sortable={false}
                >
                    <TextField
                        source="site.title"
                        sx={{
                            color: 'black',
                            textDecoration: 'underline',
                        }}
                    />
                </LinkWrapper>
                <LinkWrapper
                    label="resources.project.fields.title_project"
                    resource="project"
                    target="site.project_fk"
                    sortable={false}
                >
                    <TextField
                        source="project_title"
                        sx={{
                            color: 'black',
                            textDecoration: 'underline',
                        }}
                    />
                </LinkWrapper>
                <FunctionField
                    label="Start Time"
                    render={() => <TimeZoneDateField source="created_on" />}
                />
                <FunctionField
                    label="End Time"
                    render={(record: RaRecord) => {
                        if (record.status)
                            return <TimeZoneDateField source="updated_on" />;
                        return <>{translate('actions.in_progress')}</>;
                    }}
                />
                <FunctionField
                    label="Duration"
                    render={(record: RaRecord) => {
                        if (!record?.status) return <></>;
                        const start = DateTime.fromISO(record.created_on);
                        const end = DateTime.fromISO(record.updated_on);
                        const diff = end
                            .diff(start, ['hours', 'minutes'])
                            .toObject();
                        const hoursDifference = diff['hours'] ?? 0;
                        const minutesDifference = Math.floor(
                            diff['minutes'] ?? 0,
                        );
                        let hoursMsg = '';
                        if (hoursDifference > 0) {
                            hoursMsg = hoursMsg.concat(
                                translate('common.duration.hour', {
                                    smart_count: hoursDifference,
                                }),
                                ' and ',
                            );
                        }
                        return (
                            <>
                                {hoursMsg.concat(
                                    translate('common.duration.minute', {
                                        smart_count: minutesDifference,
                                    }),
                                )}
                            </>
                        );
                    }}
                />
                <FunctionField
                    label="Checked Out?"
                    render={(record: RaRecord) => {
                        if (record.status && record.status === 'in')
                            return <></>;

                        let buttonMsg = 'Check-Out';
                        let title = 'Add Check-Out Time';

                        if (record.status) {
                            title = 'Edit Check-Out Time';
                            if (record.initiator_user_fk === record.user.id)
                                buttonMsg = 'Checked Out by Technician';
                            else buttonMsg = 'Checked Out by Manager';
                        }
                        return (
                            <Tooltip title={title}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setCurRecord(record);
                                        setOpenConfirmation(true);
                                    }}
                                >
                                    {record.status ? (
                                        <EventBusyIcon />
                                    ) : (
                                        <EventAvailableIcon />
                                    )}
                                    <Typography
                                        sx={{
                                            textDecoration: record.status
                                                ? null
                                                : 'underline',
                                            marginLeft: '5px',
                                        }}
                                    >
                                        {buttonMsg}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        );
                    }}
                />
            </Datagrid>
            <CheckoutModal
                isOpen={openConfirmation}
                setIsOpen={setOpenConfirmation}
                record={curRecord}
            />
        </ListContextProvider>
    );
};

export default CheckInList;
