import React, { useState } from 'react';
import { Identifier, useRecordContext } from 'react-admin';
import { Task, TaskPhoto as TaskPhotoType } from '../../types';
import { Box, Typography, Paper } from '@mui/material';
import { apiUrl } from '../../dataProvider/index';
import { OpenInNew } from '@mui/icons-material';
import { ImageModal } from '../../components';

export const TaskPhoto = ({ operationTitle }: { operationTitle: string }) => {
    const record = useRecordContext<Task>();

    const taskPhotos = record.photos;

    const urlPhotoList: {
        index: Identifier;
        url: string;
        thumb_url: string;
    }[] = [];

    taskPhotos?.forEach((photo: TaskPhotoType) => {
        let url = photo.url;
        let thumb_url = photo.thumb_url;
        if (thumb_url === null) thumb_url = url;
        if (url.indexOf('http') === -1) {
            url = apiUrl + '/' + url;
            thumb_url = apiUrl + '/' + thumb_url;
        }
        urlPhotoList.push({ index: photo.id, url, thumb_url });
    });

    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);

    // No Photos
    if (urlPhotoList.length == 0)
        return (
            <Typography
                sx={{
                    width: '40%',
                    textAlign: 'left',
                    marginLeft: '50px',
                }}
            >
                {record.photo_required
                    ? 'No Photos Available'
                    : 'No Photos Required'}
            </Typography>
        );

    return (
        <>
            <PhotoDisplay
                urlPhotoList={urlPhotoList}
                setSelectedImage={setSelectedImage}
                setOpen={setOpen}
            />
            <ImageModal
                urlPhotoList={urlPhotoList}
                open={open}
                setOpen={setOpen}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                ModalHeader={
                    <ModalHeader
                        record={record}
                        operationTitle={operationTitle}
                    />
                }
                ModalFooter={<ModalFooter description={record.description} />}
            />
        </>
    );
};

// -----------------------
// Photo display component
// -----------------------

type PhotoDisplayProps = {
    urlPhotoList: { url: string; thumb_url: string }[];
    setSelectedImage: React.Dispatch<React.SetStateAction<number>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PhotoDisplay = ({
    urlPhotoList,
    setSelectedImage,
    setOpen,
}: PhotoDisplayProps) => {
    const handleOpenModal = (index: number) => {
        setSelectedImage(index);
        setOpen(true);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                marginLeft: 3,
                gap: 2,
            }}
        >
            {urlPhotoList.map(({ url, thumb_url }, index) => (
                <Paper
                    elevation={2}
                    sx={{
                        width: '150px',
                        height: '150px',
                        cursor: 'pointer',
                    }}
                    key={url}
                    onClick={() => handleOpenModal(index)}
                >
                    <img
                        src={thumb_url}
                        alt=""
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        crossOrigin="anonymous"
                    />
                </Paper>
            ))}
        </Box>
    );
};

// ----------------------
// Modal header for links
// ----------------------

type ModalHeaderProps = {
    record: Task;
    operationTitle: string;
};

const ModalHeader = ({ record, operationTitle }: ModalHeaderProps) => {
    const taskTitle = record.title;
    const currentUrl = window.location.href.substring(
        0,
        window.location.href.indexOf('#') + 1,
    );

    const information: {
        Title: {
            Text: string;
            Link?: string;
        };
        SubTitle?: {
            Text: string;
            Link?: string;
        };
    } = {
        Title: {
            Text: 'Operation: ' + operationTitle ?? '',
            Link: `${currentUrl}/operation/${record.operation_fk}`,
        },
        SubTitle: {
            Text: 'Task: ' + taskTitle ?? '',
            Link: `${currentUrl}/task/${record.id}`,
        },
    };

    return (
        <Box
            sx={{
                marginLeft: '10px',
                marginTop: '5px',
            }}
        >
            <Typography color="primary">
                {information?.Title?.Text + ' '}
                {information?.Title?.Link && (
                    <OpenInNew
                        sx={{
                            fontSize: '15px',
                            cursor: 'pointer',
                            marginBottom: '-2px',
                        }}
                        onClick={() => window.open(information?.Title.Link)}
                    />
                )}
            </Typography>
            <Typography color="primary">
                {information?.SubTitle?.Text + ' '}
                {information?.SubTitle?.Link && (
                    <OpenInNew
                        sx={{
                            fontSize: '15px',
                            cursor: 'pointer',
                            marginBottom: '-2px',
                        }}
                        onClick={() => window.open(information?.SubTitle?.Link)}
                    />
                )}
            </Typography>
        </Box>
    );
};

// ----------------------------
// Modal footer for description
// ----------------------------

type ModalFooterProps = {
    description: string;
};

const ModalFooter = ({ description }: ModalFooterProps) => (
    <Box
        sx={{
            marginLeft: '10px',
            marginRight: '10px',
            marginBottom: '5px',
        }}
    >
        <Typography
            color="primary"
            sx={{
                color: 'rgba(0,0,0,0.4)',
                padding: '5px',
                paddingTop: '0px',
                display: 'block',
                fontSize: 15,
                fontStyle: 'italic',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
            }}
        >
            {description}
        </Typography>
    </Box>
);
