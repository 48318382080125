import { useRecordContext, useTranslate } from 'react-admin';
import { Card, CardContent, CardProps, Typography } from '@mui/material';
import { getHoursAndMinutes } from '../../helpers';
import { useGetUserEvent } from '../../hooks';

type TimeCardProps = { resource: string; dateFilters?: any } & CardProps;

export const TimeCard: React.FunctionComponent<TimeCardProps> = ({
    resource,
    children,
    dateFilters,
    ...rest
}) => {
    const record = useRecordContext();
    const translate = useTranslate();

    const { data, isLoading } = dateFilters
        ? useGetUserEvent(true, {
              filter: `{${dateFilters}, "${resource}_fk": ${record.id}}`,
          })
        : useGetUserEvent(true, {
              filter: `{"${resource}_fk": ${record.id}}`,
          });

    if (isLoading) return <Typography>loading...</Typography>;
    if (data?.total === null || data?.total === undefined)
        return (
            <Typography>{translate('reporting.total.fetchFail')}</Typography>
        );

    return (
        <Card {...rest}>
            <CardContent>
                {children}
                <Typography variant="h6">
                    {getHoursAndMinutes(data.total)}
                </Typography>
                <Typography variant="caption" sx={{ color: 'grey' }}>
                    Total technician time spent
                </Typography>
            </CardContent>
        </Card>
    );
};
