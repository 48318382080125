import React, { useEffect, useState } from 'react';
import { Box, Typography, Modal, Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type ImageModalProps = {
    urlPhotoList: { url: string; thumb_url: string }[];
    open: boolean;
    setOpen: (open: boolean) => void;
    selectedImage: number;
    setSelectedImage: React.Dispatch<React.SetStateAction<number>>;
    ModalHeader: JSX.Element;
    ModalFooter: JSX.Element;
};

export const ImageModal = ({
    urlPhotoList,
    open,
    setOpen,
    selectedImage,
    setSelectedImage,
    ModalHeader,
    ModalFooter,
}: ImageModalProps) => {
    const [imageWidth, setImageWidth] = useState<number | undefined>(undefined);

    const handlePrev = () => {
        const newIndex =
            selectedImage - 1 >= 0
                ? selectedImage - 1
                : urlPhotoList.length - 1;
        setSelectedImage(newIndex);
    };

    const handleNext = () => {
        const newIndex =
            selectedImage + 1 < urlPhotoList.length ? selectedImage + 1 : 0;
        setSelectedImage(newIndex);
    };

    const handleImageLoad = (
        event: React.SyntheticEvent<HTMLImageElement, Event>,
    ) => {
        const { width } = event.currentTarget;
        setImageWidth(width);
    };

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft') {
                handlePrev();
            } else if (event.key === 'ArrowRight') {
                handleNext();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handlePrev, handleNext]);

    if (urlPhotoList.length === 0) return null;

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                ...modalStyles.modal,
                ...(imageWidth && { width: imageWidth }),
            }}
        >
            <Box sx={modalStyles.boxContainer}>
                {ModalHeader}
                <Box sx={modalStyles.relativeBox}>
                    <Button onClick={handlePrev} sx={modalStyles.buttonPrev}>
                        <ArrowBackIosNewIcon />
                    </Button>

                    <img
                        src={urlPhotoList[selectedImage].thumb_url}
                        alt=""
                        style={modalStyles.image}
                        onLoad={handleImageLoad}
                        crossOrigin="anonymous"
                    />

                    <Typography
                        variant="subtitle1"
                        sx={modalStyles.imageCaption}
                    >
                        Image {selectedImage + 1} of {urlPhotoList.length}
                    </Typography>

                    <Button onClick={handleNext} sx={modalStyles.buttonNext}>
                        <ArrowForwardIosIcon />
                    </Button>
                </Box>

                {ModalFooter}
            </Box>
        </Modal>
    );
};

const modalStyles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
        margin: '0 auto',
    },
    boxContainer: {
        outline: 'none',
        bgcolor: 'background.paper',
        borderRadius: 1,
        overflow: 'hidden',
    },
    relativeBox: {
        position: 'relative',
        textAlign: 'center',
    },
    buttonPrev: {
        position: 'absolute',
        left: 16,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
    },
    buttonNext: {
        position: 'absolute',
        right: 16,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
        height: '36px',
        width: '36px',
    },
    image: {
        maxWidth: '90vw',
        maxHeight: '80vh',
        display: 'block',
        margin: '10px auto',
    },
    imageCaption: {
        position: 'absolute',
        bottom: 10,
        left: '50%',
        transform: 'translateX(-50%)',
        color: 'white',
        padding: '0.5em',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderRadius: '10px',
        border: '1px solid white',
    },
};
