import { convertToUTCDateTime } from '../dataProvider';

export const projectLifeCycleCallbacks = {
    resource: 'project',
    beforeUpdate: async (params: any, dataProvider: any) => {
        const newParams = { ...params };
        const { data, previousData } = newParams;
        const {
            current_end,
            status,
            scheduled_start,
            scheduled_end,
            time_region,
            ...rest
        } = data;

        if (scheduled_start) {
            const newScheduledStart = convertToUTCDateTime(
                scheduled_start,
                time_region,
                params.previousData.time_region,
            );
            newParams.data.scheduled_start = newScheduledStart;
        }

        if (scheduled_end) {
            const newScheduledEnd = convertToUTCDateTime(
                scheduled_end,
                time_region,
                params.previousData.time_region,
            );
            newParams.data.scheduled_end = newScheduledEnd;
        }

        if (status === 'completed' && current_end === null) {
            const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            newParams.data.current_end = convertToUTCDateTime(
                new Date(),
                browserTimeZone,
                browserTimeZone,
            )
        }
        if (status !== 'completed' && current_end !== null) {
            newParams.data.current_end = null;
        }
        return newParams;
    },
};
