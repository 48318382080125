import React from 'react';
import {
    SimpleForm,
    AutocompleteInput,
    required,
    TextInput,
    useGetList,
} from 'react-admin';
import {
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    ClearButtons,
    ColorButtons,
} from 'ra-input-rich-text';
import { SublistCreate, DefaultCreateToolbar } from '../components';
import { Grid } from '@mui/material';
import { SiteChoices } from '../types';

type CustomRichTextInputProps = {
    source: string;
    label: string;
};

const CustomRichTextInput = (
    { source }: CustomRichTextInputProps,
    { label }: CustomRichTextInputProps,
) => {
    return (
        /* disable typecheck for RichTextInput defaultValue
        eslint-disable-next-line @typescript-eslint/ban-ts-comment
        */
        <RichTextInput
            source={source}
            fullWidth
            label={label}
            toolbar={
                <RichTextInputToolbar>
                    <LevelSelect />
                    <FormatButtons />
                    <ColorButtons />
                    <AlignmentButtons />
                    <ListButtons />
                    <ClearButtons />
                </RichTextInputToolbar>
            }
        />
    );
};

export const SiteEvaluationCreate: React.FC = () => {
    const { data: choices, isLoading } = useGetList<SiteChoices>('sitecompleted');
    if (!choices) return null;

    return (
        <>
            {
                <SublistCreate
                    resource="evaluation"
                    title={
                        <>
                            <span>New Site Evaluation</span>&ensp;
                            <span style={{ fontSize: '11px' }}>
                                (Create a New Site Evaluation Report here. From
                                the dropdown, select any completed Site that
                                does not have yet have an Evaluation.)
                            </span>
                        </>
                    }
                    component="div"
                >
                    <div>
                        <Grid container direction="column">
                            <Grid
                                item
                                sx={{
                                    padding: '1.3em',
                                    background: 'white',
                                    border: '2px solid lightgrey',
                                    borderRadius: '16px',
                                }}
                            >
                                <SimpleForm
                                    toolbar={
                                        <DefaultCreateToolbar
                                            resource="evaluation"
                                            redirect="edit"
                                        />
                                    }
                                    sx={{
                                        paddingX: 0,
                                        '.ProseMirror': {
                                            minHeight: '122px',
                                        },
                                        '[class^="ra-input"]': {
                                            alignItems: 'flex-start',
                                        }, // apply css to all classes of prefix
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={9}>
                                            <AutocompleteInput
                                                source="site_fk"
                                                choices={choices}
                                                isLoading={isLoading}
                                                validate={required()}
                                                label="resources.evaluation.fields.search"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <TextInput
                                                source="purchase_order"
                                                label="resources.evaluation.fields.purchase_order"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <TextInput
                                                source="field_name"
                                                label="resources.evaluation.fields.field_name"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                source="field_value"
                                                label="resources.evaluation.fields.field_value"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={10}>
                                            <CustomRichTextInput
                                                source="outstanding"
                                                label="resources.evaluation.fields.outstanding"
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <CustomRichTextInput
                                                source="baseline"
                                                label="resources.evaluation.fields.baseline"
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <CustomRichTextInput
                                                source="end_result"
                                                label="resources.evaluation.fields.end_result"
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <CustomRichTextInput
                                                source="assesment"
                                                label="resources.evaluation.fields.assesment"
                                            />
                                        </Grid>
                                    </Grid>
                                </SimpleForm>
                            </Grid>
                        </Grid>
                    </div>
                </SublistCreate>
            }
        </>
    );
};
