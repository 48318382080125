import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import { OperationEdit } from './OperationEdit';
import { NotFound } from 'react-admin';

export default {
    create: NotFound,
    icon: FeaturedPlayListIcon,
    list: NotFound,
    edit: OperationEdit,
};
