import React, { useEffect, useState } from 'react';
import { Layout as RA_Layout, LayoutProps } from 'react-admin';
import { CustomAppBar } from './AppBar';
import { Menu } from './Menu';
import AcceptNotificationDialog from '../components/AcceptNotificationDialog/AcceptNotificationDialog';

const Layout = (props: LayoutProps) => {
    return <RA_Layout {...props} appBar={CustomAppBar} menu={Menu} />;
};

export default Layout;
