import React from 'react';
import {
    BooleanField,
    Datagrid,
    List,
    SelectInput,
    TextField,
    TextInput,
    useTranslate,
} from 'react-admin';
import { Box } from '@mui/material';
import { DefaultPerPage, roles } from '../constants';
import { EnhancedPagination, EnhancedEmpty, QuickFilter } from '../components';
import { FilterButton, TopToolbar } from 'react-admin';

const UserListActions = () => (
    <TopToolbar>
        <FilterButton />
    </TopToolbar>
);

export const UserList: React.FC = () => {
    const translate = useTranslate();
    const UserListFilters = [
        <SelectInput
            key={`user-filters-00`}
            source="role"
            defaultValue={'Technician'}
            choices={roles}
            placeholder={translate('resources.user.placeholders.role')}
        />,
        <TextInput
            key={`user-filters-01`}
            source="first_name"
            placeholder={translate('resources.user.placeholders.first_name')}
        />,
        <TextInput
            key={`user-filters-02`}
            source="last_name"
            placeholder={translate('resources.user.placeholders.last_name')}
        />,
        <TextInput
            key={`user-filters-03`}
            source="email"
            placeholder={translate('resources.user.placeholders.email')}
        />,
        <TextInput
            key={`user-filters-04`}
            source="phone"
            placeholder={translate('resources.user.placeholders.phone')}
        />,
        <QuickFilter
            key={`user-filters-05`}
            source="is_active"
            defaultValue={true}
            label="resources.user.filters.is_active"
        />,
    ];

    // const filter = permissions !== 'Admin' ?? { id__ni: [] } : undefined;

    return (
        <Box display="flex">
            <List
                actions={<UserListActions />}
                sx={{
                    flexGrow: 1,
                }}
                filters={UserListFilters}
                filterDefaultValues={{ is_active: true, role: 'Technician' }} // sets the filter to value on initial load
                sort={{ field: 'created_on', order: 'DESC' }}
                perPage={DefaultPerPage}
                pagination={<EnhancedPagination />}
                empty={<EnhancedEmpty resource="user" />}
            >
                <Datagrid optimized rowClick="show" bulkActionButtons={false}>
                    <TextField source="role" />
                    <TextField source="first_name" />
                    <TextField source="last_name" />
                    <TextField source="email" />
                    <TextField source="phone" />
                    <BooleanField source="is_active" />
                </Datagrid>
            </List>
        </Box>
    );
};
