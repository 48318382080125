import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useRef,
} from 'react';
import {
    Client,
    Conversation,
    Message,
    Participant,
} from '@twilio/conversations';
import { useNotify, useGetIdentity, useAuthProvider } from 'react-admin';
import { Identifier } from 'react-admin';
import * as Sentry from '@sentry/browser';

export interface LocalConversation {
    uniqueName: string;
    selectedPeople?: string[];
    otherPeople?: string[];
    type: string;
    typeId?: Identifier;
    friendlyName?: string;
}

export interface ConversationData {
    userNames: string[];
    latestMessage: Message | null;
    senderName: string | null;
    unreadCount: number;
    friendly_map: Record<string, string>;
}

type TwilioContextType = {
    client: Client | null;
    conversations: Conversation[];
    connected: boolean;
    unreadCount: number;
    selectedConversation: Conversation | null;
    setConversation: (conversation: Conversation | null) => void;
    conversationData: Record<string, ConversationData>;
    localConversation: LocalConversation | null;
    setLocalConversation: (newConversation: LocalConversation) => void;
};

const TwilioContext = createContext<TwilioContextType | null>(null);

export const useTwilio = () => useContext(TwilioContext);

export const TwilioProvider: React.FC = ({ children }) => {
    const notify = useNotify();
    const { data } = useGetIdentity();
    const authProvider = useAuthProvider();

    const [client, setClient] = useState<Client | null>(null);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [connected, setConnected] = useState(false);
    const [selectedConversation, setConversation] =
        useState<Conversation | null>(null);
    const [conversationData, setConversationData] = useState<
        Record<string, ConversationData>
    >({});
    const [localConversation, setLocalConversation] =
        useState<LocalConversation | null>(null);
    const selectedConversationRef = useRef<Conversation | null>(null);
    const conversationsRef = useRef<Conversation[]>([]);
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        selectedConversationRef.current = selectedConversation;
        if (selectedConversation) getConversationData(selectedConversation);
        updateUnreadCounts();
    }, [selectedConversation]);

    useEffect(() => {
        conversationsRef.current = conversations;
        updateUnreadCounts();
    }, [conversations]);

    useEffect(() => {
        if (client) {
            const getNewToken = () => {
                authProvider.getTwilioToken((token: string) => {
                    client.updateToken(token).catch((error) => {
                        notify(`Error refreshing token: ${error}`, {
                            type: 'error',
                        });
                        Sentry.captureException(
                            new Error(
                                `Error refreshing token: ${JSON.stringify(
                                    error,
                                )}`,
                            ),
                        );
                    });
                });
            };

            const onTokenAboutToExpire = () => getNewToken();
            const onTokenExpired = () => getNewToken();

            client.on('tokenAboutToExpire', onTokenAboutToExpire);
            client.on('tokenExpired', onTokenExpired);

            return () => {
                client.off('tokenAboutToExpire', onTokenAboutToExpire);
                client.off('tokenExpired', onTokenExpired);
            };
        }
    }, [client]);

    useEffect(() => {
        if (data?.twilio_token) {
            const twilioClient = new Client(data.twilio_token);

            twilioClient.on('connectionStateChanged', async (state) => {
                if (state === 'connected') {
                    setConnected(true);
                } else setConnected(false);
            });

            twilioClient.on('conversationAdded', async (conversation) => {
                getConversationData(conversation);
                setConversations((prevConversations) => {
                    const newConversations = [...prevConversations];
                    const index = newConversations.findIndex(
                        (c) => c.sid === conversation.sid,
                    );

                    if (index !== -1) newConversations[index] = conversation;
                    else newConversations.push(conversation);

                    newConversations.sort((a, b) => {
                        const aDate = new Date(
                            a.lastMessage?.dateCreated || 0,
                        ).getTime();
                        const bDate = new Date(
                            b.lastMessage?.dateCreated || 0,
                        ).getTime();
                        return bDate - aDate;
                    });

                    return newConversations;
                });
            });

            twilioClient.on('conversationRemoved', async (conversation) => {
                setConversations((currentConversations) =>
                    currentConversations.filter(
                        (c) => c.sid !== conversation.sid,
                    ),
                );
                setConversationData((currentData) => {
                    const newData = { ...currentData };
                    delete newData[conversation.sid];
                    return newData;
                });
            });

            twilioClient.on('connectionError', (data) => {
                Sentry.addBreadcrumb({
                    category: 'twilio',
                    message: 'Twilio connection error encountered',
                    level: 'log',
                    data: data,
                });
            });

            twilioClient.on('messageAdded', async (message: Message) => {
                if (
                    message.conversation.sid ==
                    selectedConversationRef.current?.sid
                )
                    await selectedConversationRef.current.setAllMessagesRead();

                getConversationData(message.conversation);
                updateUnreadCounts();
                setConversations((conversations) =>
                    conversations.sort((a, b) => {
                        const aDate = new Date(
                            a.lastMessage?.dateCreated || 0,
                        ).getTime();
                        const bDate = new Date(
                            b.lastMessage?.dateCreated || 0,
                        ).getTime();
                        return bDate - aDate;
                    }),
                );
            });
            setClient(twilioClient);

            return () => {
                twilioClient.removeAllListeners();
                twilioClient.shutdown();
            };
        }
    }, [data]);

    const updateUnreadCounts = async () => {
        let totalUnread = 0;

        for (const conversation of conversationsRef.current) {
            try {
                let unreadCount = await conversation.getUnreadMessagesCount();

                if (unreadCount === null)
                    unreadCount = await conversation.getMessagesCount();

                totalUnread += unreadCount || 0;
            } catch (error) {
                Sentry.addBreadcrumb({
                    category: 'twilio',
                    message:
                        'Selected converstaion changed and error in fetching unread message count',
                    level: 'log',
                });
            }
        }

        setUnreadCount(totalUnread);
    };

    const getConversationData = async (conversation: Conversation) => {
        try {
            // Participant user names
            const participants = await conversation.getParticipants();
            const users = await Promise.all(
                participants.map((participant: Participant) =>
                    participant.getUser(),
                ),
            );
            const friendly_map: Record<string, string> = {};

            const userNames = users
                .filter((user) => user.identity != data?.email)
                .map((user) => {
                    if (user.identity)
                        friendly_map[user.identity] = user.friendlyName || '';
                    if (user.friendlyName) return user.friendlyName;
                    return user.identity;
                })
                .sort();

            // Latest message
            const messages = await conversation.getMessages(1);
            const latestMessage = messages.items[0];
            let senderName: string | null = '';
            if (latestMessage)
                senderName =
                    (latestMessage.attributes as { authorFriendlyName: string })
                        .authorFriendlyName ||
                    (await (await latestMessage.getParticipant()).getUser())
                        .friendlyName ||
                    latestMessage.author;

            // Unread Messages
            let unreadCount: number | null = 0;

            unreadCount = await conversation.getUnreadMessagesCount();
            if (unreadCount == null)
                unreadCount = await conversation.getMessagesCount();

            // Data Update
            setConversationData((prevData) => {
                return {
                    ...prevData,
                    [conversation.sid]: {
                        userNames,
                        latestMessage: latestMessage || null,
                        senderName,
                        unreadCount: unreadCount || 0,
                        friendly_map,
                    },
                };
            });
        } catch (error) {
            Sentry.addBreadcrumb({
                category: 'twilio',
                message: 'Error fetching conversation data',
                level: 'log',
            });
        }
    };

    return (
        <TwilioContext.Provider
            value={{
                client,
                conversations,
                connected,
                unreadCount,
                selectedConversation,
                setConversation,
                conversationData,
                localConversation,
                setLocalConversation,
            }}
        >
            {children}
        </TwilioContext.Provider>
    );
};
