import React from 'react';
import {
    Datagrid,
    List,
    SearchInput,
    TextField,
    TextInput,
    useTranslate,
} from 'react-admin';
import { matchPath } from 'react-router-dom';
import { EnhancedEmpty, EnhancedPagination } from '../components';

import { DefaultPerPage } from '../constants';

export const ProviderList: React.FC = () => {
    const translate = useTranslate();
    const ProviderFilters = [
        <SearchInput key="filter-00" source="q" alwaysOn />,
        <TextInput
            key="filter-01"
            source="name"
            label="resources.provider.fields.name"
            placeholder={translate('resources.provider.placeholders.name')}
        />,
        <TextInput
            key="filter-02"
            source="provider_code"
            label="resources.provider.fields.provider_code"
            placeholder={translate(
                'resources.provider.placeholders.provider_code',
            )}
        />,
    ];

    const match = matchPath('/user/:id', location.pathname);

    return (
        <>
            <List
                sx={{
                    flexGrow: 1,
                    transition: (theme: any) =>
                        theme.transitions.create(['all'], {
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    marginRight: match ? '400px' : 0,
                }}
                filters={ProviderFilters}
                sort={{ field: 'id', order: 'DESC' }}
                pagination={<EnhancedPagination />}
                perPage={DefaultPerPage}
                empty={<EnhancedEmpty resource="provider" />}
                title="resources.provider.name"
            >
                <Datagrid optimized>
                    <TextField source="name" />
                    <TextField source="provider_code" />
                </Datagrid>
            </List>
        </>
    );
};
