import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { useNotify, useTranslate } from 'react-admin';

export interface ClickableLocationIconProps {
    lat: number;
    lng: number;
    label: string;
}

export const ClickableLocationIcon: React.FunctionComponent<
    ClickableLocationIconProps
> = ({ lat, lng, label }) => {
    const notify = useNotify();
    const translate = useTranslate();
    const url =
        lat & lng ? `https://maps.google.com?q=${lat},${lng}` : undefined;

    const handleMapClick = () => {
        try {
            window.open(url);
        } catch {
            notify(translate('resources.user.location.getFailed'), {
                type: 'error',
            });
        }
    };

    return (
        <>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <Typography>{label}</Typography>
                </Grid>
                <Grid item>
                    <IconButton
                        color="inherit"
                        onClick={handleMapClick}
                        disabled={!url}
                    >
                        <MapOutlinedIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};
