import React, { useEffect, useState } from 'react';
import { AppBar, useNotify, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import OkosLogo from '../assets/OkosLogo.png';
import { Button, Icon } from '@mui/material';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { getMessaging, onMessage } from 'firebase/messaging';
import {
    firebaseApp,
    requestNotificationPermission,
} from '../chats/fcmPushNotifications';
import { useTwilio } from '../contexts/TwilioProvider';
import { Client, Conversation } from '@twilio/conversations';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AcceptNotificationDialog from '../components/AcceptNotificationDialog/AcceptNotificationDialog';

const FeedbackFormButton = () => {
    const translate = useTranslate();
    return (
        <Button
            onClick={() => {
                const googleFormUrl =
                    'https://docs.google.com/forms/d/e/1FAIpQLSdB_lgOPjvIbeZ0QXAb3CdOcaappjoiPQ6hlNLX-nqvodb05g/viewform?vc=0&c=0&w=1&flr=0';
                window.open(googleFormUrl, '_blank');
            }}
        >
            {translate('pos.feedback')}
        </Button>
    );
};

const EnableNotificationsButton = ({
    client,
    permission,
}: {
    client: Client;
    permission: NotificationPermission | string;
}) => {
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        if (['default', 'denied'].includes(permission)) {
            setShowDialog(true);
        }
    }, [permission]);

    const handleButtonClick = () => {
        setShowDialog(true);
        requestNotificationPermission(client);
    };

    if (['default', 'denied'].includes(permission)) {
        return (
            <>
                <Button
                    onClick={handleButtonClick}
                    endIcon={<NotificationsActiveIcon />}
                    sx={{
                        backgroundColor: 'orange',
                        '&:hover': {
                            backgroundColor: 'darkorange',
                        },
                        color: 'white',
                    }}
                >
                    Enable Notifications
                </Button>
                {showDialog && <AcceptNotificationDialog />}
            </>
        );
    } else {
        return null;
    }
};

export const CustomAppBar = (props: any) => {
    const navigate = useNavigate();

    // Push Notifications and Permissions
    const { conversations, selectedConversation, client } = useTwilio() as {
        conversations: Conversation[];
        selectedConversation: Conversation | null;
        client: Client;
    };
    const notify = useNotify();
    const [notificationPermission, setNotificationPermission] = useState('');
    useEffect(() => {
        if ('Notification' in window) {
            setNotificationPermission(window.Notification.permission);
        } else {
            setNotificationPermission('');
        }
    }, [window]);

    useEffect(() => {
        const handlePermissionChange = () => {
            setNotificationPermission(window.Notification.permission);
        };

        // Listen for changes to the notification permission
        if ('permissions' in navigator) {
            navigator.permissions
                .query({ name: 'notifications' })
                .then((permissionStatus) => {
                    permissionStatus.onchange = handlePermissionChange;
                });
        }

        // If the notification permission is already 'granted', request the FCM token
        if (notificationPermission === 'granted') {
            requestNotificationPermission(client);
        }

        // Clean up the event listener when the component unmounts
        return () => {
            if ('permissions' in navigator) {
                navigator.permissions
                    ?.query({ name: 'notifications' })
                    .then((permissionStatus) => {
                        permissionStatus.onchange = null;
                    });
            }
        };
    }, [client]);

    // Foreground push notification if selectedConversation is not focused

    if ('permissions' in navigator) {
        // Initialize Firebase Cloud Messaging and get a reference to the service
        const messaging = getMessaging(firebaseApp);

        onMessage(messaging, (payload) => {
            console.log('Foreground Message received. ', payload);
            // incoming twilio message
            if (
                payload.data &&
                payload.data.twi_message_type ===
                    'twilio.conversations.new_message' &&
                payload.data.conversation_sid !== selectedConversation?.sid
            ) {
                const options = {
                    body: payload.data.twi_body,
                    icon: './OkosTransparent.ico',
                };

                // sends MUI alert for foreground notification
                // TODO: refactor into custom component
                notify(
                    <Alert
                        icon={<MarkUnreadChatAltIcon fontSize="inherit" />}
                        severity="info"
                        variant="filled"
                        action={
                            <IconButton
                                color="inherit"
                                size="small"
                                onClick={async () => {
                                    const convo = conversations.find(
                                        (c: Conversation) =>
                                            c.sid ==
                                            payload.data?.conversation_sid,
                                    );
                                    await convo?.setAllMessagesRead();
                                    navigate(
                                        `/chat/${
                                            payload.data?.conversation_sid ?? ''
                                        }`,
                                    );
                                }}
                            >
                                <OpenInFullIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {options.body}
                    </Alert>,
                    { anchorOrigin: { vertical: 'top', horizontal: 'center' } },
                );
            } else {
                // incoming firebase notification
                if (payload.notification) {
                    // Customize notification here
                    const notificationTitle =
                        payload.notification?.title ?? 'New Message';
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: './OkosTransparent.ico',
                    };
                    new Notification(notificationTitle, notificationOptions);
                }
            }
        });
    }

    return (
        <AppBar {...props} color="secondary" elevation={1}>
            {props.open && (
                <Box
                    sx={{
                        width: 200,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        src={OkosLogo}
                        height={20}
                        onClick={() => {
                            navigate('/');
                        }}
                    />
                </Box>
            )}
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            {notificationPermission !== 'granted' && (
                <EnableNotificationsButton
                    client={client}
                    permission={notificationPermission}
                />
            )}
            <FeedbackFormButton />
        </AppBar>
    );
};
