import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Confirm, SimpleForm, useNotify, RaRecord } from 'react-admin';
import * as Sentry from '@sentry/browser';
import { useDataProvider, useGetIdentity } from 'react-admin';
import {
    LocalizationProvider,
    DateTimeField,
    MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

interface CheckoutModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    record: RaRecord | null;
}

const CheckoutModal: React.FC<CheckoutModalProps> = ({
    isOpen,
    setIsOpen,
    record,
}) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { identity } = useGetIdentity();
    const [checkoutTime, setCheckoutTime] = useState<DateTime | null>(null);

    useEffect(() => {
        if (record && record.time_region) {
            const zone = record.time_region;
            let defaultTime = DateTime.now().setZone(zone);
            if (record.status) {
                defaultTime = DateTime.fromISO(record.updated_on, {
                    zone,
                });
            } else if (record.created_on) {
                const created_on = DateTime.fromISO(record.created_on, {
                    zone,
                });
                const eightHoursLater = created_on.plus({ hours: 8 });
                const now = DateTime.now().setZone(zone);

                const diffHours = now.diff(created_on, 'hours').hours;

                if (diffHours < 8) defaultTime = now;
                else defaultTime = eightHoursLater;
            }
            setCheckoutTime(defaultTime);
        }
    }, [record, isOpen]);

    if (isOpen && !record) {
        notify('Cannot retrieve Check In Data', { type: 'error' });
        return null;
    }
    if (!record) return null;

    const handleTimeChange = (newTime: DateTime | null) => {
        if (!newTime) return;

        const zone = record.time_region || 'local';
        const recordCreationTime = DateTime.fromISO(record?.created_on, {
            zone,
        });
        const timeDiff = newTime.diff(recordCreationTime, 'seconds').toMillis();
        if (timeDiff < 0) {
            notify('Checkout Time Cannot Occur Before Check-In Time', {
                type: 'error',
            });
        }
        setCheckoutTime(newTime.setZone(zone, { keepLocalTime: true }));
    };

    const handleConfirm = async () => {
        if (!checkoutTime) return;

        const now = DateTime.now().setZone(checkoutTime.zone);

        const futureCheck = checkoutTime
            .diff(now, 'minutes')
            .toObject().minutes;

        if (!futureCheck || futureCheck > 0) {
            notify('Checkout Time Cannot Occur In The Future', {
                type: 'error',
            });
            return;
        }

        const checkIn = DateTime.fromISO(record?.created_on, {
            zone: record.time_region,
        });
        const beforeCheckIn = checkoutTime
            .diff(checkIn, 'minutes')
            .toObject().minutes;

        if (!beforeCheckIn || beforeCheckIn < 0) {
            notify('Checkout Time Cannot Occur Before Check-In Time', {
                type: 'error',
            });
            return;
        }

        try {
            await dataProvider.customUpdateOne('sitecheck/site', {
                id: record?.site.id,
                data: {
                    user_fk: record.user.id,
                    status: 'out',
                    end_time: checkoutTime
                        .toUTC()
                        .toFormat('yyyy-MM-dd HH:mm:ss.SSSZZZ'),
                    check_unique_id: record.check_unique_id,
                    id: record.id,
                    initiator_user_fk: identity?.id ?? record.user.id,
                },
            });
            setIsOpen(false);
            window.location.reload();
        } catch (error) {
            Sentry.captureException(error);
            notify('Error during checkout process', { type: 'error' });
        }
    };

    return (
        <Confirm
            isOpen={isOpen}
            title={record.status ? 'Edit Check-Out Time' : 'Add Check-Out Time'}
            onConfirm={handleConfirm}
            onClose={() => setIsOpen(false)}
            content={
                <SimpleForm toolbar={false}>
                    <Typography variant="h6" gutterBottom>
                        Are you sure you want to check out the technician?
                    </Typography>
                    <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={'en-ca'}
                    >
                        <Stack spacing={2}>
                            <DateTimeField
                                disabled
                                label={`Check-In Time in ${record.time_region}`}
                                fullWidth
                                slotProps={{ textField: { size: 'small' } }}
                                margin="normal"
                                format="yyyy-MM-dd t"
                                timezone={record?.time_region ?? 'system'}
                                value={DateTime.fromISO(record.created_on, {
                                    zone: record.time_region,
                                })}
                            />
                            <div>
                                <MobileDateTimePicker
                                    label={`Check-Out Time in ${record.time_region}`}
                                    value={checkoutTime}
                                    onChange={handleTimeChange}
                                    maxDateTime={DateTime.now().setZone(
                                        record?.time_region || 'local',
                                    )}
                                    minDateTime={DateTime.fromISO(
                                        record?.created_on,
                                        {
                                            zone: record.time_region || 'local',
                                        },
                                    )}
                                    minutesStep={1}
                                />
                                <CalendarTodayIcon />
                            </div>
                        </Stack>
                    </LocalizationProvider>
                </SimpleForm>
            }
        />
    );
};

export default CheckoutModal;
