import React, { ReactNode } from 'react';
import { Empty, EmptyProps, ListView, ListViewProps } from 'react-admin';
import { ListCreateButton } from '../ListCreateButton';
import { Grid, Typography } from '@mui/material';

export type EnhancedListViewProps = {
    resource:
        | 'site'
        | 'task'
        | 'template_task'
        | 'operation'
        | 'project'
        | 'evaluation'
        | 'template_operation';
    children?: ReactNode;
} & ListViewProps;
// TODO: the above prop ges into ListView which requires a title.

export const EnhancedListView: React.FC<EnhancedListViewProps> = ({
    resource,
    children,
    ...rest
}) => {
    return (
        <ListView
            {...rest}
            empty={<EnhancedEmpty resource={resource} />}
            title={'default.no_title'}
        >
            {children}
        </ListView>
    );
};

type EnhancedEmptyProps = {
    resource:
        | 'site'
        | 'task'
        | 'template_task'
        | 'operation'
        | 'project'
        | 'template_operation'
        | 'user'
        | 'evaluation'
        | 'provider';
} & EmptyProps;

export const EnhancedEmpty: React.FC<EnhancedEmptyProps> = ({
    resource,
    ...rest
}) => {
    return (
        <Grid container direction="column" alignItems="center">
            <Grid item>
                <Empty {...rest} hasCreate={false} />
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    Would you like to create a{' '}
                    {resource.replace(/[^a-zA-Z ]/g, ' ')}?{' '}
                    {/* Using regex to remove the underscore in template_task */}
                </Typography>
            </Grid>
            <Grid item>
                <ListCreateButton
                    resource={resource}
                    style={{ margin: '1em', textTransform: 'capitalize' }}
                />
            </Grid>
        </Grid>
    );
};
