import { FieldValues } from 'react-hook-form';
import { GetListParams } from 'react-admin';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sanitizeEmptyValues(data: FieldValues): FieldValues {
    const sanitizedData: FieldValues = {};
    for (const key in data) {
        if (
            !data[key] ||
            (typeof data[key] === 'string' && data[key].length === 0)
        )
            continue;
        sanitizedData[key] = data[key];
    }
    return sanitizedData;
}

export const getHoursAndMinutes = (minutes: number) =>
    `${Math.floor(minutes / 60)} hours, ${minutes % 60} mins`;

export type FilterOptions = {
    component: React.ReactElement;
    role: 'all' | 'Manager' | 'Admin';
};

/*
 * Returns filters based on roles
 */
export function RBACFilters(
    allFilters: FilterOptions[],
    permissions = 'Manager',
): React.ReactElement[] {
    return allFilters.reduce(
        (filters: React.ReactElement[], curr: FilterOptions) => {
            if (curr.role === 'all' || curr.role === permissions)
                filters.push(curr.component);
            return filters;
        },
        [],
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
/*
 * Returns filters based on roles
 */
export function sanitizeQueryParams(params: GetListParams) {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    return {
        sort: JSON.stringify([field, order]),
        page: JSON.stringify(page),
        perPage: JSON.stringify(perPage),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
    };
}
