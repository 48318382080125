import React, { RefObject, useMemo } from 'react';
import { Client, Message as TwilioMessage } from '@twilio/conversations';
import { ConversationData, useTwilio } from '../../contexts/TwilioProvider';
import TextMessage from './TextMessage';
import MediaMessage from './MediaMessage';

interface DisplayMessageProps {
    message: TwilioMessage;
    client: Client;
    msgListRef: RefObject<any>;
}

const DisplayMessage: React.FC<DisplayMessageProps> = ({
    message,
    client,
    msgListRef,
}) => {
    const { conversationData } = useTwilio() as {
        conversationData: { [key: string]: ConversationData };
    };

    const friendly_map = useMemo(() => {
        const currentFriendlyMap =
            conversationData[message.conversation.sid]?.friendly_map || {};
        return currentFriendlyMap;
    }, [conversationData, message.conversation.sid]);

    return (
        <div>
            {message?.type === 'media' ? (
                <MediaMessage
                    message={message}
                    client={client}
                    msgListRef={msgListRef}
                    friendly_map={friendly_map}
                />
            ) : (
                <TextMessage
                    message={message}
                    client={client}
                    friendly_map={friendly_map}
                />
            )}
        </div>
    );
};

export default DisplayMessage;
