import React from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { DefaultToolbar } from './DefaultToolbar';

interface DefaultCreateToolbar {
    resource: 'provider' | 'evaluation';
    redirect?: 'edit' | 'list';
}
/**
 * This component  to standardize the buttons and behaviours for CREATE components
 * including the messages and notifications. Redirect is defaulted to `list`.
 */

export const DefaultCreateToolbar: React.FC<DefaultCreateToolbar> = ({
    resource,
    redirect = 'list',
}) => {
    const notify = useNotify();
    const redirectFn = useRedirect();

    return (
        <DefaultToolbar
            mutationOptions={{
                onSuccess: (data) => {
                    notify(`resources.${resource}.notification.created`, {
                        undoable: true,
                    });
                    redirectFn(redirect, resource, data?.id);
                },
            }}
            resource={resource}
        />
    );
};
