import React, { ReactNode } from 'react';
import {
    FunctionField,
    Link,
    PublicFieldProps,
    RaRecord,
    useResourceContext,
} from 'react-admin';

interface LinkWrapper extends PublicFieldProps {
    children: ReactNode;
    resource?: 'project' | 'site';
    target?: 'id' | 'project_fk' | 'site_fk' | 'site.id' | 'site.project_fk';
}

interface CheckInLinks {
    [key: string]: string;
}

/*
 * Add the <a> tag wrapper to any existing children
 * @param {string} resource: uses the default resources from the list context. This maybe overridden in the prop
 * @param {string} target: identify the data field to use for the href
 * @param {string} source: Not required by the component, use this to indicate to react-admin the column header
 * @param {string} label: Not required by the component, use this to indicate to react-admin the column header in i18n
 * @return {React FC}
 *
 */
export const LinkWrapper: React.FC<LinkWrapper> = (props) => {
    const listResource = useResourceContext();
    const { children, target = 'id', resource = listResource } = props;

    if (!resource) return <>{children}</>;
    const checkInLinks: CheckInLinks = {
        'site.id': '`/${resource}/${record["site"]["id"]}`',
        'site.project_fk': '`/${resource}/${record["site"]["project_fk"]}`',
    };

    // generate the resource and record string to be evaluated in FUnctionField
    const identifier: string =
        checkInLinks[target] ??
        '`/${resource}/${record["'.concat(target).concat('"]}`');
    return (
        <FunctionField
            // do not remove the record, as this is needed for the strint evaluation
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={(record: RaRecord) => (
                <Link to={eval(identifier)}>{children}</Link>
            )}
        />
    );
};
